.newsletter-merge-fields-portfolio-web {
    table {
        color: var(--mo-color-text1);
        margin-bottom: 30px;
        width: 100%;
        thead {
            tr {
                th {
                    text-align: left;
                    white-space: nowrap;
                }
            }
        }
        .nmfpw-link {
            margin-right: 10px;
            white-space: nowrap;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
