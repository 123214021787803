.service-list-medium-block-web {
    border-bottom: 1px solid var(--mo-color-ln);
    display: flex;
    padding: 20px;
    position: relative;
    width: 100%;
    .slmbw-cover {
        border-radius: 50%;
        flex-shrink: 0;
        height: 125px;
        overflow: hidden;
        width: 125px;
        .slmbw-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
    .slmbw-info {
        flex: 1;
        padding: 0 15px;
        .mo-list-block-name-web {
            font-size: 19px;
        }
        .slmbw-profile-name {
            display: block;
            font-size: 15px;
        }
        .slmbw-country {
            align-items: center;
            display: flex;
            .main-hashtag {
                font-size: 13px;
            }
            .main-country-flag-web {
                .mcfw-flag {
                    height: 10px;
                    width: 10px;
                }
            }
        }
        .slmbw-tags {
            .main-chip-list-block {
                height: 21px;
                margin-bottom: 5px;
            }
        }
        .slmbw-price-wrap {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            .slmbw-price {
                font-size: 19px;
                margin-left: 20px;
                .slmbw-day {
                    color: var(--mo-color-text2);
                    font-size: 17px;
                }
            }
        }
    }
    .slmbw-image-wrap {
        display: flex;
        padding-right: 10px;
        .slmbw-image {
            border-radius: 6px;
            height: 125px;
            margin: 0 3px;
            object-fit: cover;
            width: 125px;
        }
    }
    .slmbw-actions {
        display: flex;
        flex-direction: column;
        width: 30px;
    }
}
