.admin-menu-item-view {
    align-items: center;
    display: flex;
    .amiv-expand {
        align-items: center;
        color: var(--mo-color-text2);
        cursor: pointer;
        display: flex;
        font-size: 10px;
        margin-left: 10px;
        transition: $mo-ease;
        &.hidden {
            visibility: hidden;
        }
        &.open {
            transform: rotate(180deg);
        }
    }
    .amiv-tab-item-children {
        align-items: center;
        border-bottom: 1px solid var(--mo-color-ln);
        cursor: pointer;
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 7px 10px 7px 0;
        &.active {
            background: var(--mo-color-ln);
            color: var(--mo-color-mo-color);
        }
        &.separator {
            color: var(--mo-color-text2);
            font-size: 13px;
            pointer-events: none;
            text-transform: uppercase;
        }
        &:hover {
            background: var(--mo-color-ln);
            color: var(--mo-color-mo-color);
        }
        .amiv-ion-reorder {
            font-size: 24px;
            &::part(icon) {
                font-size: 24px;
            }
        }
    }
}
