.admin-menu-item {
    .ami-expand {
        align-items: center;
        color: var(--mo-color-text2);
        cursor: pointer;
        display: flex;
        font-size: 10px;
        margin-left: 10px;
        transition: $mo-ease;
        &.hidden {
            visibility: hidden;
        }
        &.open {
            transform: rotate(180deg);
        }
    }
    .main-icon {
        font-size: 12px;
    }
    .ami-tab-item-wrap {
        align-items: center;
        display: flex;
        .ami-tab-item {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .ami-tab-item {
        align-items: center;
        border-bottom: 1px solid var(--mo-color-ln);
        cursor: pointer;
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 7px 10px 7px 0;
        &.active {
            background: var(--mo-color-ln);
            color: var(--mo-color-mo-color);
        }
        &:hover {
            background: var(--mo-color-ln);
            color: var(--mo-color-mo-color);
        }
        &.nolink {
            pointer-events: none;
        }
    }
    .ami-children-wrap {
        border-left: 1px solid var(--mo-color-ln);
        display: none;
        // margin-left: 3px;
        padding-left: 10px;
        &.open {
            display: block;
        }
    }
}
