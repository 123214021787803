.main-form-container-web {
    .mfcw-name-button {
        max-width: 150px;
    }
    .mfcw-form-content {
        .mfcw-nav-mode-wrap {
            align-items: center;
            display: flex;
            justify-content: center;
        }
        .mfcw-nav-preview-link-wrap {
            display: flex;
            flex-direction: column;
            padding-right: 15px;
            .mfcw-nav-preview-link {
                font-size: 14px;
                line-height: 1;
            }
        }
        .mfcw-nav-go-to-builder {
            margin-right: 15px;
        }
        .mfcw-form-panels-wrap {
            height: calc(100vh - #{$nav-children-height});
            display: flex;
            .mfcw-form-panels {
                background: var(--mo-color-bg1);
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                transition: $mo-ease;
                width: 370px;
                &.fullscreen {
                    margin-left: -370px;
                    &.mixed-media,
                    &.mixed-media-content {
                        margin-left: -340px;
                    }
                }
                &.inline {
                    width: 100%;
                    .mfcw-form-panels-children {
                        padding: 0;
                    }
                }
                &.mixed-media,
                &.mixed-media-content {
                    width: 340px;
                    .mfcw-form-panels-children {
                        padding: 0;
                    }
                }
                &.menu,
                &.submenu {
                    .mfcw-form-panels-children {
                        padding: 0;
                    }
                }
                &.special {
                    .mfcw-form-panels-children {
                        padding: 15px 15px 0;
                    }
                }
                .mfcw-form-panels-children {
                    padding: 15px;
                    flex: 1;
                    .mfcw-form-panels-header {
                        font-size: 24px;
                        padding-bottom: 20px;
                    }
                    .mfcw-member-form {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        .mfcw-member-form-fields {
                            flex: 1;
                        }
                        .mfcw-member-form-footer {
                            margin: 0 -15px;
                            background: var(--mo-color-bg1);
                            padding: 10px;
                            display: flex;
                            .mfcw-member-form-footer-button {
                                width: 100%;
                            }
                        }
                    }
                }
                .mfcw-footer-wrap {
                    border-top: 1px solid var(--mo-color-ln);
                    padding: 10px;
                    .mfcw-footer {
                        display: flex;
                        .mfcw-footer-button {
                            width: 50%;
                            &.mfcw-finish {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .mfcw-form-preview {
                flex: 1;
                overflow-x: auto;
                .detail-view-preview-web {
                    height: 100%;
                }
            }
            .mfcw-form-preview-mixed-media-content {
                flex: 1;
                .mixed-template-preview-wrap {
                    position: relative;
                    width: 100%;
                    .mixed-template-preview {
                        height: 100%;
                        position: relative;
                    }
                    &.is-mobile {
                        margin: 30px 30px 30px 0;
                        width: calc(375px + 60px + 30px);
                    }
                    &.is-tablet {
                        margin: 30px 30px 30px 0;
                        width: calc(768px + 60px + 30px);
                    }
                    &.is-web {
                        padding: 30px 0;
                        width: calc(100vw - 340px);
                    }
                }
                &.fullscreen {
                    .mixed-template-preview-wrap {
                        padding: 0;
                        width: 100vw;
                        .mixed-template-preview {
                            .slug-page-content-wrap {
                                .slug-page-content-div {
                                    height: $portfolio-height-vh;
                                    .slug-page-content {
                                        padding: 0;
                                        .slug-page-content-list {
                                            .hpc-selection-helper {
                                                .selection-helper-page-content-web-left,
                                                .selection-helper-page-content-web-right {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .mfcw-content-header {
                padding: 20px 0 0;
                --border-color: black;
            }
        }
    }
}
.main-form-container-modal-web {
    .wrapper {
        height: 95vh;
        width: 75vw;
    }
    .mfcmw-custom-edit-title {
        font-size: 30px;
        font-weight: bold;
        padding-bottom: 20px;
    }
    .mo-image-crop-input-web,
    .mo-image-crop-square-input-web {
        width: 340px;
    }
}
