.team-list-small-block-web {
    border: 1px solid transparent;
    display: inline-block;
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
    width: 220px;
    .tlsbw-wrap {
        .tlsbw-image-wrap {
            position: relative;
            .ratio-helper-web {
                &.link {
                    &:after {
                        background: rgba(var(--mo-color-bg2-rgb), 0.7);
                        border-radius: 5%;
                        content: "";
                        cursor: pointer;
                        height: 100%;
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        transition: $mo-ease;
                        width: 100%;
                        z-index: 1;
                    }
                }
                &.no-link {
                    &:after {
                        background: rgba(var(--mo-color-bg2-rgb), 0.7);
                        border-radius: 5%;
                        content: "";
                        height: 100%;
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        transition: $mo-ease;
                        width: 100%;
                        z-index: 1;
                    }
                }
            }
            .tlsbw-image-zarma {
                &.link {
                    &:after {
                        background: rgba(var(--mo-color-bg2-rgb), 0.7);
                        border-radius: 5%;
                        content: "";
                        cursor: pointer;
                        height: 100%;
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        transition: $mo-ease;
                        width: 100%;
                        z-index: 1;
                    }
                }
                &.no-link {
                    &:after {
                        background: rgba(var(--mo-color-bg2-rgb), 0.7);
                        border-radius: 5%;
                        content: "";
                        height: 100%;
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        transition: $mo-ease;
                        width: 100%;
                        z-index: 1;
                    }
                }
            }
            .tlsbw-action {
                align-items: center;
                display: flex;
                height: 74px;
                justify-content: space-around;
                opacity: 0;
                position: absolute;
                top: calc(50% - 37px);
                transition: $mo-ease;
                width: 100%;
                z-index: 2;
                .tlsbw-actions-link {
                    position: relative;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
                .main-icon-block-web {
                    z-index: 1;
                }
            }
            &.is-open,
            &:hover {
                .ratio-helper-web {
                    &:after {
                        opacity: 1;
                    }
                }
                .tlsbw-image-zarma {
                    &:after {
                        opacity: 1;
                    }
                }
                .tlsbw-action {
                    opacity: 1;
                }
            }
        }
        .tlsbw-cover-wrap {
            display: block;
            position: relative;
            z-index: 2;
            .tlsbw-image-wrap {
                align-items: center;
                border-radius: 5%;
                display: flex;
                justify-content: center;
                overflow: hidden;
                padding-bottom: 100%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .tlsbw-image {
                    border-radius: 14px;
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
                .tlsbw-image-zarma {
                    align-items: center;
                    background: var(--mo-color-bg1);
                    border-radius: 5%;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    left: 0;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    .tlsbw-image-zarma-name {
                        color: var(--mo-color-text1);
                        font-size: 40px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .tlsbw-info {
            padding: 10px 10px 0;
            text-align: center;
            width: 100%;
            .mo-list-block-name-web.small {
                -webkit-line-clamp: 3;
                white-space: initial;
            }
            .tlsbw-chips {
                margin-bottom: 10px;
                overflow: hidden;
                .main-hashtag {
                    font-size: 14px;
                    line-height: initial;
                }
            }
            .tlsbw-country {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                height: 21px;
                justify-content: center;
                overflow: hidden;
                .tlsbw-country-flag {
                    flex: 0 0 24px;
                    height: 21px;
                }
                .tlsbw-city-name {
                    height: 21px;
                    overflow: hidden;
                }
            }
        }
        .tlsbw-action {
            padding: 10px;
            text-align: center;
        }
    }
}
