.project-detail-block-web {
    .pcbw-image-wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        &.playable {
            .pcbw-image-div {
                align-items: center;
                display: flex;
                flex: 1;
                overflow: auto;
                width: 100%;
            }
        }
        .pcbw-image {
            cursor: pointer;
            margin: auto;
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
            object-position: center;
        }
        .pcb-link-url {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 10px 10px 0;
            .pcb-platform-wrap {
                align-items: center;
                display: flex;
                flex: 1;
                justify-content: flex-start;
                .pcb-platform {
                    margin-left: 5px;
                    height: 15px;
                }
            }
            .pcb-play-icon {
                font-size: 24px;
                height: auto;
            }
        }
    }
    .pcb-play-button-wrap {
        margin: auto;
        max-width: 70%;
    }
}
