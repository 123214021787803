.list-mixed-content-panel-web {
    display: flex;
    flex-direction: column;
    flex: 1;
    .lmcpw-content-list {
        flex: 1;
        padding: 15px;
        .lmcpw-expand {
            color: var(--mo-color-text2);
            cursor: pointer;
        }
        .lmcpw-content-list-block {
            border-bottom: 1px solid var(--mo-color-ln);
            display: flex;
            height: 150px;
            overflow: hidden;
            padding: 15px 0;
            .lmcpw-content {
                overflow: hidden;
                width: 230px;
                .lmcpw-scale-content {
                    pointer-events: none;
                    transform-origin: top left;
                    transform: scale(0.15);
                    width: 100vw;
                }
            }
            .lmcpw-actions-wrap {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .lmcpw-content-status {
                    font-size: 12px;
                    &.not-active {
                        color: red !important;
                    }
                }
                .lmcpw-actions {
                    align-items: center;
                    color: var(--mo-color-text1);
                    display: flex;
                    flex: 1;
                    justify-content: flex-end;
                    padding-left: 15px;
                }
            }
        }
    }
    .lmcpw-footer {
        background: var(--mo-color-bg1);
        border-top: 1px solid var(--mo-color-ln);
        padding: 15px;
    }
}
