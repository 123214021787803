.mo-player-web {
    display: none;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 10;
    &.bottom,
    &.full {
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(var(--mo-color-bg2-rgb), 0.7);
        bottom: 0;
        display: flex;
        height: 55px;
        .mo-player-web-block {
            background: black;
            height: 55px;
            overflow: hidden;
            position: relative;
            width: 55px;
            z-index: 1;
            &:after {
                bottom: 0;
                content: "";
                cursor: pointer;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
            .mo-player-web-cover {
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
            &.full {
                bottom: 55px;
                height: 300px;
                position: absolute;
                right: 0;
                width: 500px;
                &:after {
                    display: none;
                }
                &.is-mobile {
                    width: 100%;
                }
            }
        }
        .mo-player-web-platform-wrap {
            align-items: center;
            display: flex;
            color: var(--mo-color-text1);
            padding: 10px;
            justify-content: center;
            .mo-player-web-powered {
                flex-shrink: 0;
                font-size: 14px;
            }
            .mo-player-web-platform {
                margin-left: 5px;
                height: 15px;
            }
        }
        .mo-player-web-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5px 10px;
            flex: 1;
            &.is-mobile {
                display: none;
            }
            .mo-player-web-info-name {
                color: var(--mo-color-text1);
                font-size: 14px;
                font-weight: 600;
            }
        }
        .mo-player-web-range {
            display: block;
        }
        .mo-player-web-right {
            align-items: center;
            display: flex;
            padding-right: 30px;
            &.is-mobile {
                display: none;
            }
            .mo-player-web-range-duration {
                padding: 0 15px;
                display: flex;
                align-items: center;
                font-size: 13px;
                color: var(--mo-color-text2);
                justify-content: space-between;
            }
        }
        .mo-player-web-action {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            left: 50%;
            padding: 5px;
            position: absolute;
            transform: translate(-50%);
            .mo-player-web-action-icon {
                display: flex;
                font-size: 20px;
                margin-left: 20px;
            }
            .mo-player-web-action-full {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
                .mo-player-web-action-icon-full {
                    align-items: center;
                    display: flex;
                    margin: 0 10px;
                    &.mo-player-web-action-icon-full-main {
                        height: 40px;
                        .main-icon {
                            font-size: 40px;
                        }
                    }
                    &.is-mobile {
                        &.album {
                            display: none;
                        }
                    }
                }
            }
        }
        .mo-player-web-next {
            align-items: center;
            bottom: 5px;
            color: var(--mo-color-text2);
            display: flex;
            font-size: 14px;
            justify-content: space-between;
            left: 0;
            padding: 10px;
            position: absolute;
            width: 100%;
        }
        .mo-player-minimize {
            display: none;
            height: 100%;
            left: 7px;
            position: absolute;
            top: 0;
            &.is-mobile {
                display: flex;
            }
        }
        .mo-player-close {
            display: none;
            height: 100%;
            position: absolute;
            right: 7px;
            top: 0;
            &.is-mobile {
                display: flex;
            }
        }
    }
    &.detail {
        display: flex;
        flex-direction: column;
        height: calc(#{$nav-height-vh2} - 20px - 45px);
        left: 10px;
        top: calc(#{$nav-height} + #{$nav-children-height} + 10px + 45px);
        width: calc(100vw - 370px - 20px);
        .mo-player-web-block {
            background: black;
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
            .mo-player-web-cover {
                display: none;
            }
        }
        .mo-player-close,
        .mo-player-minimize,
        .mo-player-web-action,
        .mo-player-web-info,
        .mo-player-web-right {
            display: none;
        }
        &.in-modal {
            height: calc(100vh - #{$nav-children-height} - 20px - 30px);
            left: calc(10px + 80px);
            top: calc(#{$nav-children-height} + 10px + 15px);
            width: calc(100vw - 370px - 20px - 160px);
            z-index: 99999;
        }
    }
}
