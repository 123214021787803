.detail-list-container-new {
    &.table {
        height: calc(#{$nav-height-vh2} - 45px);
        table {
            border-spacing: 0;
            min-width: 100%;
            position: relative;
            tbody {
                tr:nth-child(even) {
                    background-color: var(--mo-color-bg2);
                    td {
                        background-color: var(--mo-color-bg2);
                    }
                }
                tr:nth-child(odd) {
                    background-color: var(--mo-color-bg1);
                    td {
                        background-color: var(--mo-color-bg1);
                    }
                }
            }
        }
    }
    .dlcn-infinite {
        &.gallery {
            display: block;
        }
    }
}
