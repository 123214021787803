.profile-popover-web {
    .ppw-label {
        align-items: center;
        display: flex;
        .ppw-label-icon {
            display: flex;
            margin-right: 10px;
        }
    }
}
