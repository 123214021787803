.table-column {
    position: relative;
    transition: background-color 0.3s ease;
    .tc-background-image-wrap {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        .tc-background-image-overlay {
            background-color: rgba(var(--mo-color-black-rgb), 0.82);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    .tc-link {
        color: inherit;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .tc-link-href {
        color: inherit;
    }
    .tc-link-div {
        display: inline-block;
        padding: 5px 7px;
        position: relative;
        &.tc-fk-link {
            display: flex;
            .main-icon-block-web {
                margin-right: 5px;
                padding: 0;
                .main-icon {
                    font-size: 11px;
                }
            }
        }
        .otlb-image-wrap {
            display: inline-flex;
            flex-wrap: wrap;
            padding-top: 10px;
            .otlb-image {
                cursor: pointer;
                height: 70px;
                margin: 5px;
                object-fit: cover;
                width: 70px;
            }
        }
    }
    &.field-error {
        color: $error;
    }
    &.field-boolean {
        width: 40px;
        .tc-link-div {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
    &.field-image {
        width: 30px;
        .tc-link-div {
            display: flex;
            justify-content: center;
        }
        .rzh-image-zarma-name {
            font-size: 13px !important;
        }
    }
    &.field-number {
        text-align: right;
    }
}
