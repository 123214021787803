.table-column-value {
    position: relative;
    &.tcv-boolean {
        align-items: center;
        display: flex;
        font-size: 12px;
        justify-content: center;
        &.mo-new-icon-check-circle-solid {
            color: springgreen;
        }
        &.mo-new-icon-times-solid {
            color: $error;
        }
    }
    &.tcv-datetime {
        white-space: nowrap;
    }
    &.tcv-icon {
        display: flex;
        .mo-new-icon-link-solid {
            padding: 0;
        }
    }
    &.tcv-number {
        text-align: right;
        white-space: nowrap;
    }
    &.tcv-rich {
        white-space: normal;
    }
}
.table-column-value-copy {
    color: var(--mo-color-text2);
    font-size: 13px;
    margin-right: 10px;
}
