.template-block-inception-mixed-media-content-web {
    .tbimmcw-info-wrap {
        color: var(--mo-color-text1);
        display: flex;
        &:hover {
            background: var(--mo-color-bg1);
        }
        &.active {
            outline: 2px solid var(--mo-color-mo-color);
            outline-offset: -2px;
        }
        .tbimmcw-expand {
            align-items: center;
            color: var(--mo-color-text2);
            cursor: pointer;
            display: flex;
            margin-right: 10px;
            transition: $mo-ease;
            &.hidden {
                visibility: hidden;
            }
            &.open {
                transform: rotate(90deg);
            }
        }
        .tbimmcw-info {
            border-bottom: 1px solid var(--mo-color-ln);
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            .tbimmcw-name-wrap {
                align-items: center;
                color: var(--mo-color-text1) !important;
                display: flex;
                flex-wrap: wrap;
                &:hover {
                    color: var(--mo-color-text1) !important;
                }
                .tbimmcw-icon {
                    color: var(--mo-color-text2);
                    display: flex;
                    font-size: 14px;
                    margin-right: 10px;
                }
                .tbimmcw-content-source {
                    color: var(--mo-color-text2);
                    font-size: 13px;
                    margin-left: 10px;
                }
                .tbimmcw-link {
                    color: var(--mo-color-text2);
                    font-size: 13px;
                    margin-left: 10px;
                }
            }
            .tbimmcw-auth {
                color: #8fb4c1;
                font-size: 13px;
                font-weight: bold;
            }
            .tbimmcw-text {
                color: var(--mo-color-text2);
                font-size: 12px;
                white-space: normal;
            }
            .tbimmcw-content-inactive {
                color: red;
                font-size: 12px;
            }
        }
        .tbimmcw-actions-wrap {
            align-items: center;
            border-bottom: 1px solid var(--mo-color-ln);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .tbimmcw-actions {
                align-items: center;
                color: var(--mo-color-text1);
                display: flex;
                flex: 1;
                justify-content: flex-end;
                padding-left: 15px;
            }
        }
    }
    .tbimmcw-content-wrap {
        border-left: 1px solid var(--mo-color-ln);
        display: none;
        padding-left: 15px;
        &.open {
            display: block;
        }
    }
}
