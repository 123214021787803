.main-hashtag {
    color: var(--mo-color-text2);
    cursor: pointer;
    display: inline-block;
    height: auto;
    margin-right: 10px;
    transition: $mo-ease;
    span {
        padding: 1px 8px;
    }
    &.lowercase {
        text-transform: lowercase;
    }
    &:hover {
        color: var(--mo-color-mo-color);
    }
    &.edit {
        cursor: initial;
        &:hover {
            color: var(--mo-color-text2);
        }
    }
}
