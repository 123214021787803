.product-detail-block {
    position: relative;
    .pdb-cover-image {
        width: 100%;
    }
    .pdb-buttons {
        display: flex;
        justify-content: space-around;
        padding: 10px;
    }
    .pdb-product-info {
        padding: 10px;
        .pdb-main-info {
            border-bottom: solid 1px var(--mo-color-ln);
            padding-bottom: 10px;
            margin-bottom: 10px;
            .pdb-name {
                font-weight: 600;
            }
            .pdb-tags {
                border-bottom: solid 1px var(--mo-color-ln);
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
            .pdb-label {
                margin-top: 5px;
            }
            .pdb-countries {
                display: flex;
                padding-bottom: 10px;
            }
            .pdb-phone {
                color: var(--mo-color-text2);
            }
        }
        .pdb-section {
            border-bottom: solid 1px var(--mo-color-ln);
            padding-bottom: 10px;
            margin-bottom: 10px;
            .pdb-section-title {
                border-bottom: solid 1px var(--mo-color-ln);
                font-weight: 600;
                margin-bottom: 10px;
                padding-bottom: 10px;
            }
            .pdb-description {
                color: var(--mo-color-text2);
                white-space: pre-line;
                a {
                    display: none;
                }
            }
            .edb-links {
                color: var(--mo-color-mo-color);
            }
        }
    }
}
