.main-form-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    .main-form-label-wrap {
        align-items: baseline;
        color: var(--mo-color-text1);
        display: flex;
        flex: 1;
        padding-right: 5px;
        .mflw-label {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            transition: $mo-ease;
            width: 100%;
            &:hover {
                color: var(--mo-color-mo-color);
            }
            .mflw-label-original-name {
                font-weight: normal;
                font-size: 12px;
            }
        }
    }
    .main-form-helper-wrap {
        align-items: center;
        display: flex;
        .main-form-label-optional {
            color: var(--mo-color-text1);
            flex-shrink: 0;
            font-size: 10px;
            margin-right: 10px;
        }
        .main-form-label-translation {
            color: var(--mo-color-text1);
            cursor: pointer;
            flex-shrink: 0;
            font-size: 14px;
            margin-right: 10px;
            transition: $mo-ease;
            &:hover {
                color: var(--mo-color-mo-color);
            }
        }
        .main-form-label-helper {
            align-items: center;
            color: var(--mo-color-text1);
            cursor: pointer;
            display: flex;
            transition: $mo-ease;
            &:hover {
                color: var(--mo-color-mo-color);
            }
            &.hidden {
                visibility: hidden;
            }
            &.mr {
                margin-right: 10px;
            }
        }
        .main-form-label-color-point {
            border-radius: 50%;
            border: 1px solid white;
            cursor: pointer;
            height: 15px;
            margin-right: 5px;
            width: 15px;
        }
        .main-form-label-value {
            cursor: pointer;
            font-size: 13px;
            font-weight: normal;
            margin-right: 10px;
        }
    }
}
.main-form-label-popover {
    &::part(content) {
        border: 1px solid var(--mo-color-text1);
        padding: 10px 15px 15px;
        white-space: pre-line;
    }
    .mflp-header {
        padding-bottom: 10px;
        text-align: center;
        .mflp-title {
            font-weight: 600;
            text-transform: uppercase;
        }
        .mflp-optional {
            font-style: italic;
        }
    }
}
