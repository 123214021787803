.profile-home-list-block-mobile {
    width: 100%;
    .phlbm-title {
        border-bottom: 1px solid var(--mo-color-ln);
        font-size: 30px;
        font-weight: 600;
        margin: 10px;
        padding: 10px 0;
        .phlbm-see-all {
            color: var(--mo-color-text2);
            font-size: 16px;
            margin-left: 30px;
            text-transform: uppercase;
        }
    }
    .phlbm-content {
        .event-list-medium-block {
            padding: 0 10px;
        }
        .project-list-medium-block {
            padding: 0 10px 30px;
        }
        .phlbm-show-more {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 20px 0;
            .phlbm-show-wrap {
                align-items: center;
                color: var(--mo-color-text2);
                display: flex;
                justify-content: center;
                text-transform: uppercase;
            }
        }
    }
}
