.square-block {
    display: inline-block;
    overflow: hidden;
    padding: 7px 7px 20px;
    position: relative;
    width: 300px;
    .sb-cover {
        position: relative;
        .sb-cover-wrap {
            display: block;
            position: relative;
            z-index: 2;
            .sb-image-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
                padding-bottom: 100%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .sb-image {
                    border-radius: 14px;
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
                &:after {
                    content: "";
                    background: rgba(var(--mo-color-bg2-rgb), 0.7);
                    cursor: pointer;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    transition: $mo-ease;
                    width: 100%;
                    z-index: 4;
                }
            }
        }
        .sb-extra-info {
            background: rgba(var(--mo-color-bg2-rgb), 0.7);
            bottom: 0;
            color: rgba(var(--mo-color-text1-rgb), 0.5);
            margin-bottom: 0;
            padding: 0 5px;
            position: absolute;
            right: 0;
            text-transform: lowercase;
            z-index: 3;
        }
        .sb-actions {
            align-items: center;
            display: flex;
            height: 74px;
            justify-content: space-around;
            opacity: 0;
            position: absolute;
            top: calc(50% - 37px);
            transition: $mo-ease;
            width: 100%;
            z-index: 2;
            .sb-actions-link {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
            .main-icon-block-web {
                z-index: 1;
                .main-icon {
                    font-size: 24px;
                }
            }
            .sb-play-btn {
                z-index: 1;
                .main-icon-block-web {
                    margin-bottom: 0;
                    .mo-new-icon-pause-circle-regular,
                    .mo-new-icon-play-circle-regular {
                        font-size: 50px;
                    }
                }
            }
            .main-icon-block-web {
                margin-bottom: 0;
                .main-icon {
                    font-size: 20px;
                }
            }
        }
        &.is-open,
        &:hover {
            .sb-cover-wrap {
                .sb-image-wrap {
                    &:after {
                        opacity: 1;
                    }
                }
            }
            .sb-actions {
                opacity: 1;
            }
        }
    }
    .sb-info {
        padding-bottom: 15px;
        padding-top: 9px;
        .mo-list-block-name-web {
            font-size: 14px;
        }
        .sb-tags {
            .main-chip-list-block {
                max-height: 20px;
                .main-hashtag {
                    font-size: 13px;
                }
            }
        }
        .list-block-footer {
            margin-top: 5px;
            .list-profile-link-web {
                font-size: 13px;
                height: 20px;
                overflow: hidden;
            }
        }
    }
}
