.mo-menu-forms {
    .mo-menu-forms-icon {
        color: var(--mo-color-mo-color);
        font-size: 24px;
        margin-right: 15px;
    }
    .mo-menu-forms-second-label {
        color: var(--mo-color-text2) !important;
    }
}
