.schedule-list-block-web {
    color: var(--mo-color-text2);
    padding-bottom: 10px;
    .slbw-extra-info {
        font-size: 14px;
        padding-left: 10px;
        .slbw-address {
            display: flex;
            align-items: center;
            .slbw-icon {
                display: flex;
                margin-right: 10px;
            }
        }
        .slbw-venue {
            display: flex;
            align-items: center;
            .slbw-venue-text {
                margin-right: 10px;
            }
            .credit-short-info-web {
                margin: 0;
            }
        }
    }
}
