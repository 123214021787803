.form-component {
    display: flex;
    flex-direction: column;
    margin-top: -15px;
    min-height: 100%;
    position: relative;
    .fc-tabs {
        align-items: center;
        background-color: var(--mo-color-bg1);
        border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: -15px;
        z-index: 10;
        .fc-buttons {
            align-items: center;
            display: flex;
            padding: 15px 0 15px;
            text-transform: capitalize;
            .button-web {
                margin: 0 10px;
                min-width: 0;
                padding: 0;
            }
        }
        .fc-active {
            font-weight: bold;
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }
    .fc-content {
        .fc-fieldset {
            background-color: var(--mo-color-bg2);
            padding: 20px 0;
        }
        .fc-panel-description-staff {
            font-size: 12px;
            padding: 10px;
        }
    }
}
