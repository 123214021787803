.select-input-mobile {
    margin-bottom: $form-mb;
    .tim-input {
        background: var(--mo-color-bg2);
        min-height: 40px;
        padding: 10px 13px;
        border-radius: $form-br;
        &.disabled {
            opacity: 0.4;
        }
    }
}
.eci-thumb {
    padding: 5px 10px 5px 0;
}
.select-list-helpertext {
    padding: 15px;
    white-space: pre-line;
}
.mo-select-input-web {
    margin-bottom: $form-mb;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    &.no-mb {
        margin-bottom: 0;
    }
    .mo-select-disabled {
        opacity: 0.4;
    }
    .mo-text-input-web-input {
        background: var(--mo-color-bg2);
        border-radius: $form-br;
        cursor: pointer;
        min-height: 40px;
        padding: 10px 13px;
    }
    .siw-medium-type-label {
        align-items: center;
        display: flex;
        .siw-icon {
            display: flex;
            justify-content: center;
            margin-right: 20px;
            width: 20px;
        }
    }
    .siw-layout-custom-label {
        align-items: center;
        display: flex;
        .siw-lcl-image-wrap {
            flex-shrink: 0;
            height: 30px;
            overflow: hidden;
            width: 30px;
            .siw-lcl-image {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
            &.zarma {
                align-items: center;
                background: var(--mo-color-bg1);
                display: flex;
                justify-content: center;
                .siw-image-zarma-name {
                    text-transform: uppercase;
                }
            }
        }
        .siw-lcl-text-wrap {
            margin-left: 15px;
            .siw-lcl-profile-text {
                font-size: 10px;
            }
        }
    }
}
.eci-thumb-web {
    padding: 5px 10px 5px 0;
}
.select-list-helpertext-web {
    padding: 15px;
    white-space: pre-line;
}
