.detail-list-container-web {
    .dlcw-content {
        padding: 10px;
        .dlcw-infinite {
            &.small {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                &.image {
                    display: block;
                }
            }
            &.big {
                max-width: 50%;
                margin: auto;
                &.services {
                    max-width: 100%;
                }
            }
            &.medium {
                max-width: 800px;
                margin: auto;
                &.image,
                &.images,
                &.services {
                    max-width: 100%;
                }
            }
        }
    }
}
