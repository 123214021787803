.image-gallery-helper-web {
    display: block;
    overflow: hidden;
    .ighw-image {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }
    &.cursor {
        cursor: pointer;
    }
}
