.search-tabs {
    background: black; // because of profile background blur
    padding-bottom: 7px;
    position: relative; // because of profile background blur
    transition: $mo-ease;
    z-index: 6; // because of profile background blur
    .st-tab-button {
        padding: 2px 0;
        .st-tab-label {
            color: var(--mo-color-text2);
            font-size: $MainFontSize;
            font-weight: 600;
        }
        &.segment-button-checked {
            .st-tab-label {
                color: var(--mo-color-text1);
            }
        }
    }
}
