.mo-phone-number-input-web {
    margin-bottom: $form-mb;
    .main-form-label {
        margin-bottom: 7px;
    }
    .PhoneInputInput {
        background: var(--mo-color-bg2);
        border: none;
        border-radius: $form-br;
        min-height: 36px;
        padding: 0px 10px;
    }
    .PhoneInputCountry {
        border: 0;
    }
}
