.profile-detail-menu-web {
    .hcw-profile-pic {
        margin-right: 30px !important;
    }
    &.no-pic {
        margin-left: 10px;
    }
    .pdmw-tab-button {
        @extend .mo-clamp-web1;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        margin-right: 15px;
        max-width: 150px;
        position: relative;
        text-transform: uppercase;
        &.active {
            color: var(--mo-color-mo-color);
            &:after {
                border-top: 3px solid var(--mo-color-mo-color);
                bottom: -18px;
                content: "";
                height: 0.5em;
                left: 0;
                position: absolute;
                right: 0;
                z-index: -1;
            }
        }
    }
    .pdmw-button {
        display: inline-block;
        font-size: 16px;
        margin-right: 15px;
        position: relative;
        text-transform: capitalize;
    }
}
.profile-detail-menu-left-web {
    .pdmlw-tab-item {
        border-bottom: 1px solid var(--mo-color-ln);
        display: block;
        font-weight: bold;
        margin-left: 16px;
        padding: 7px 10px 7px 0;
        text-transform: uppercase;
        width: 100%;
        &.active {
            color: var(--mo-color-mo-color);
        }
    }
}
