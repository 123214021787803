.forms-page {
    padding: 15px;
    .forms-page-content {
        background: var(--mo-color-bg1);
        position: relative;
        margin: 0 auto;
        max-width: 900px;
        padding: 50px 15px;
        .form-description {
            padding-bottom: 20px;
        }
        .forms-page-content2 {
            padding: 15px 0;
            &.submitted {
                opacity: 0.4;
                pointer-events: none;
            }
        }
        .forms-page-buttons {
            background: var(--mo-color-bg2);
            display: flex;
            padding: 15px;
            button:first-child {
                margin-right: 10px;
            }
            button:last-child {
                margin-left: 10px;
            }
            @media (max-width: 576px) {
                flex-direction: column;
                button:first-child {
                    margin-bottom: 10px;
                    margin-right: 0;
                }
                button:last-child {
                    margin-left: 0;
                }
            }
        }
    }
}
