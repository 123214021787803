.inline-form-container-web {
    display: flex;
    height: 100%;
    .ifcw-left {
        display: flex;
        flex-direction: column;
        width: 370px;
        .ifcw-form-children {
            flex: 1;
            padding: 15px;
        }
        .ifcw-form-button {
            background: var(--mo-color-bg1);
            border-top: 1px solid var(--mo-color-ln);
            display: flex;
            padding: 10px;
            .ifcw-footer-button {
                width: 50%;
            }
            .ifcw-footer-button-full {
                width: 100%;
            }
        }
    }
    .ifcw-right {
        background: var(--mo-color-bg2);
        flex: 1;
        position: relative;
        .ifcw-segment-wrap {
            background: var(--mo-color-bg2);
            position: sticky;
            top: 0;
            z-index: 3;
        }
        .ifcw-search-wrap {
            align-items: center;
            background: var(--mo-color-bg2);
            border-bottom: 1px solid var(--mo-color-ln);
            display: flex;
            padding: 0 20px 0 10px;
            position: sticky;
            top: 0;
            z-index: 2;
            .ifcw-searchbar {
                max-width: 400px;
                padding: 0 10px;
                .searchbar-search-icon {
                    display: none;
                    height: 0;
                    width: 0;
                }
                .searchbar-input {
                    background: var(--mo-color-bg2);
                    padding-inline-start: 13px;
                }
            }
            .ifcw-icon {
                margin-left: 10px;
            }
        }
    }
    .mfcw-form-content {
        .mfcw-form-panels {
            padding: 15px;
            &.menu {
                padding: 15px 0;
            }
        }
    }
    .ifcw-avatar {
        margin-right: 10px;
    }
    .ifcw-label {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin: 0px;
    }
    .ifcw-name {
        white-space: pre-line;
        &.image {
            padding-left: 10px;
        }
    }
    .ifcw-active-status {
        color: var(--mo-color-text2) !important;
        &.not-active {
            color: var(--mo-color-mo-color) !important;
        }
        &.image {
            padding-left: 10px;
        }
    }
    .ifcw-address {
        align-items: center;
        color: var(--mo-color-text2);
        display: flex;
        font-size: 14px;
        .ifcw-icon {
            display: flex;
            margin-right: 10px;
        }
    }
    .ifcw-venue {
        align-items: center;
        display: flex;
        font-size: 14px;
        .ifcw-venue-text {
            margin-right: 10px;
        }
    }
    .ifcw-thumb {
        padding: 10px;
    }
    .ifcw-message {
        text-align: center;
        padding: 10px;
    }
    .ifcw-team-wrap {
        display: flex;
        .ifcw-team-info {
            width: 300px;
        }
        .ifcw-team-credit-type {
            width: 150px;
        }
        .ifcw-team-roles {
            flex: 1;
        }
        .ifcw-team-text {
            color: var(--mo-color-text2);
            white-space: break-spaces;
        }
    }
}
