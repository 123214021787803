.infinite-scroll-helper-web-empty {
    height: 64px;
    width: 100%;
}
.infinite-scroll-helper-web-no-more-text {
    height: 64px;
    padding: 20px;
    text-align: center;
    &.tertiary {
        color: black;
    }
}
