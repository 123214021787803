.profile-short-info-web {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    &.right {
        flex-direction: row-reverse;
        .psiw-name-wrap {
            margin-left: 0px;
            margin-right: 10px;
            .psiw-name {
                text-align: right;
            }
        }
    }
    .psiw-image {
        height: 30px;
        width: 30px;
    }
    .psiw-name-wrap {
        flex: 1;
        margin-left: 10px;
        .psiw-name {
            display: inline-block;
        }
    }
}
