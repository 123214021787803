.detail-list-container-mobile {
    .dlcm-wrap {
        padding: 5px 0;
    }
    .dlcm-profile-pic {
        align-items: center;
        display: flex;
        padding: 10px;
        width: 70px;
    }
    .dlcm-header-wrap {
        align-items: center;
        display: flex;
        .dlcm-profile-name {
            align-items: center;
            display: flex;
            flex: 1;
            padding-right: 10px;
        }
        .dlcm-thumb-wrap {
            color: #898f99;
            display: flex;
            justify-content: space-between;
            .dlcm-size-icon {
                font-size: 20px;
                padding: 10px;
                &.active {
                    color: var(--mo-color-mo-color);
                }
            }
        }
    }
}
