.admin-module {
    display: flex;
    flex-direction: column;
    height: 100%;
    .am-header {
        background: var(--mo-color-bg1);
        border-bottom: 3px solid var(--mo-color-ln);
        border-left: 1px solid var(--mo-color-ln);
        z-index: 10;
        .am-header-title {
            font-size: 18px;
            font-weight: bold;
            padding: 15px 0 0 20px;
        }
        .am-header-bottom {
            display: flex;
            .am-tab-wrap {
                align-items: center;
                border-top: 3px solid var(--mo-color-ln);
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                padding: 10px 10px 10px 0;
                width: 100%;
                .am-status-link {
                    cursor: pointer;
                    margin: 0 10px;
                    position: relative;
                    &.active {
                        color: var(--mo-color-mo-color);
                        &:after {
                            border-top: 3px solid var(--mo-color-mo-color);
                            bottom: -1px;
                            content: "";
                            left: 0;
                            position: absolute;
                            right: 0;
                            z-index: -1;
                        }
                    }
                    &:hover {
                        color: var(--mo-color-mo-color);
                    }
                }
            }
        }
        .am-search-wrap {
            align-items: center;
            display: flex;
            padding: 0 20px;
            .am-searchbar {
                height: 50px;
                padding: 0 10px 0 0;
                width: 350px;
                .searchbar-search-icon {
                    display: none;
                    height: 0;
                    width: 0;
                }
                .searchbar-input {
                    background: var(--mo-color-bg2);
                    padding-inline-start: 13px;
                }
            }
            .am-icon {
                margin-left: 10px;
            }
            .am-action-wrap {
                display: flex;
                padding: 0 20px 0 0;
            }
        }
    }
    .am-infinite-table {
        flex: 1;
        table {
            border-collapse: separate;
            border-spacing: 0;
            // min-width: 100%;
            position: relative;
            tbody {
                tr:nth-child(even) {
                    background-color: var(--mo-color-bg2);
                    td {
                        background-color: var(--mo-color-bg2);
                    }
                    &:hover {
                        background-color: rgba(var(--mo-color-mo-color-rgb), 0.3);
                        .table-column,
                        .tr-action {
                            background-color: rgba(var(--mo-color-mo-color-rgb), 0.3);
                        }
                    }
                }
                tr:nth-child(odd) {
                    background-color: var(--mo-color-bg1);
                    td {
                        background-color: var(--mo-color-bg1);
                    }
                    &:hover {
                        background-color: rgba(var(--mo-color-mo-color-rgb), 0.3);
                        .table-column,
                        .tr-action {
                            background-color: rgba(var(--mo-color-mo-color-rgb), 0.3);
                        }
                    }
                }
            }
        }
    }
}
