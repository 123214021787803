.help-center-topic-item-web {
    display: flex;
    height: $nav-height-vh2;
    position: relative;
    width: 100%;
    .hctiw-left {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
        padding: 10px;
        width: calc(100vw - 370px);
        .hctiw-image-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            .hctiw-image {
                cursor: pointer;
                margin: auto;
                max-height: 100%;
                max-width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }
    .hctiw-right {
        border-left: 1px solid var(--mo-color-ln);
        flex-shrink: 0;
        padding: 0 12px;
        position: relative;
        width: 370px;
        .hctiw-description {
            border-bottom: solid 1px var(--mo-color-ln);
            margin-bottom: 10px;
            padding: 12px 0;
            a {
                text-decoration: underline;
            }
            img {
                display: inline-block;
            }
        }
    }
}
