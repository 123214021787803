.style-mixed-panel-new-web {
    display: flex;
    flex-direction: column;
    flex: 1;
    .spcppnw-device {
        align-items: center;
        background: var(--mo-color-bg1);
        border-top: 1px solid var(--mo-color-ln);
        display: flex;
        justify-content: space-around;
        .spcppnw-device-div {
            border-bottom: 1px solid var(--mo-color-ln);
            cursor: pointer;
            display: flex;
            font-size: 15px;
            justify-content: center;
            padding: 10px;
            width: 100%;
            .icon {
                display: flex;
                margin: 0 20px;
            }
            &.active {
                border-bottom: 2px solid var(--mo-color-mo-color);
            }
        }
    }
    .spcppnw-style-wrap {
        flex: 1;
        padding: 0 15px 15px;
        position: relative;
        .spcppnw-section-header-web {
            background: var(--mo-color-bg1);
            padding-top: 15px;
            position: sticky;
            top: 0;
            z-index: 10;
            .spcppnw-title-div {
                align-items: center;
                cursor: pointer;
                display: flex;
                justify-content: flex-start;
                padding: 5px 0 10px;
                &:hover {
                    color: var(--mo-color-mo-color);
                }
                .spcppnw-collapse {
                    color: var(--mo-color-text2);
                    cursor: pointer;
                    display: flex;
                    transition: $mo-ease;
                }
            }
        }
        .spcppnw-sep-wrap {
            align-items: center;
            border-bottom: 1px solid var(--mo-color-ln);
            color: var(--mo-color-text2);
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .spcppnw-sep-div {
                align-items: center;
                cursor: pointer;
                display: flex;
                flex: 1;
                .spcppnw-expand {
                    display: flex;
                    margin-right: 10px;
                    transition: $mo-ease;
                    &.open {
                        transform: rotate(90deg);
                    }
                }
                .spcppnw-sep-title {
                    text-transform: uppercase;
                }
                .spcppnw-collapse {
                    cursor: pointer;
                }
            }
        }
        .spcppnw-style-group-wrap {
            display: none;
            padding-bottom: 20px;
            &.open {
                display: block;
            }
        }
    }
    .spcppnw-footer {
        align-items: center;
        background: var(--mo-color-bg1);
        display: flex;
        padding: 15px;
        .spcppnw-text {
            flex: 1;
            font-size: 14px;
            padding-left: 15px;
        }
    }
}
