.main-profile-website-block-web {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    width: 100%;
    @media (max-width: 576px) {
        display: block;
        height: initial;
    }
    .mpwb-loading {
        color: var(--mo-color-mo-color);
        position: absolute;
        text-align: center;
        top: 20px;
        width: 100%;
    }
    .mpwb-iframe {
        background: var(--mo-color-text1);
        border: 0;
        flex: 1;
        position: relative;
        width: 100%;
        @media (max-width: 576px) {
            height: 50vh;
        }
    }
    .mpwb-button-wrap {
        margin: auto;
        max-width: 70vw;
        padding: 10px 10px 0;
    }
}
