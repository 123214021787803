.help-center-topic-about-web {
    background: var(--mo-color-bg2);
    display: flex;
    .hctaw-menu {
        background: var(--mo-color-bg1);
        flex-shrink: 0;
        height: $nav-height-vh2;
        width: 400px;
        .hctaw-title {
            padding: 10px;
            text-transform: uppercase;
        }
        .hctaw-category-item {
            &::part(detail-icon) {
                transition: $mo-ease;
            }
            &::part(native) {
                background: var(--mo-color-bg1);
            }
            &.open {
                color: var(--mo-color-mo-color);
                &::part(detail-icon) {
                    color: var(--mo-color-mo-color);
                    transform: rotate(90deg);
                }
            }
            .hctaw-category-label {
                white-space: normal;
                &.open {
                    color: var(--mo-color-mo-color);
                }
            }
        }
        .hctaw-children-item {
            &::part(detail-icon) {
                transition: $mo-ease;
            }
            &::part(native) {
                background: var(--mo-color-bg1);
            }
            &.open {
                color: var(--mo-color-mo-color);
                &::part(detail-icon) {
                    color: var(--mo-color-mo-color);
                    transform: rotate(90deg);
                }
            }
            .hctaw-children-label {
                padding-left: 30px;
                white-space: normal;
                &.open {
                    color: var(--mo-color-mo-color);
                }
            }
        }
        .hctaw-topic-item {
            &::part(native) {
                background: var(--mo-color-bg1);
            }
        }
        .hctaw-topic-label {
            padding-left: 60px;
            white-space: normal;
            &.active {
                color: var(--mo-color-mo-color);
            }
        }
    }
    .hctaw-detail {
        flex: 1;
        height: $nav-height-vh2;
        padding: 30px;
        @media (max-width: 576px) {
            height: auto;
            padding: 20px;
        }
        .hctaw-topic-wrap {
            .hctaw-topic-name {
                font-size: 35px;
                font-weight: bold;
                padding-bottom: 30px;
            }
            .hctaw-topic-description {
                font-size: 19px;
                a {
                    text-decoration: underline;
                }
                img {
                    display: inline-block;
                }
            }
            .hctaw-topic-item-wrap {
                display: flex;
                padding: 7px 0;
                .hctaw-topic-item-div {
                    .hctaw-description-wrap {
                        align-items: baseline;
                        display: flex;
                        .hctaw-topic-item-number {
                            flex-shrink: 0;
                            font-size: 19px;
                            padding-right: 10px;
                        }
                        .hctaw-topic-item-description {
                            font-size: 19px;
                            img {
                                display: inline-block;
                            }
                        }
                    }
                    .hctaw-topic-item-image {
                        cursor: pointer;
                        margin-left: 23px;
                        max-width: 500px;
                        padding: 15px 0;
                    }
                }
            }
        }
    }
}
