.project-list-small-block {
    display: inline-block;
    overflow: hidden;
    padding: 2px 5px;
    position: relative;
    width: 50%;
    .plsb-cover {
        .plsb-cover-wrap {
            display: block;
            position: relative;
            z-index: 2;
            .plsb-image-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
                padding-bottom: 100%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .plsb-image {
                    border-radius: 7px;
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
            }
        }
        .plsb-extra-info {
            background-color: rgba(var(--mo-color-bg2-rgb), 0.7);
            bottom: 0;
            color: var(--mo-color-text1);
            margin-bottom: 0;
            padding: 0 5px;
            position: absolute;
            right: 0;
            text-transform: lowercase;
            z-index: 3;
        }
        .plsb-play-btn {
            bottom: 5px;
            left: 0;
            position: absolute;
            z-index: 4;
            .mo-new-icon-play-circle-regular {
                font-size: 20px;
            }
        }
    }
}
