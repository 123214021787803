.location-input-mobile {
    margin-bottom: $form-mb;
    .lim-input {
        background: var(--mo-color-bg2);
        min-height: 40px;
        padding: 10px 13px;
        border-radius: $form-br;
        &.disabled {
            opacity: 0.4;
        }
    }
}
.location-input-web {
    margin-bottom: $form-mb;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    .liw-chips {
        display: inline-block;
        padding: 10px 0 0;
        .liw-image {
            border-radius: 50%;
            flex-shrink: 0;
            height: 20px;
            margin-right: 10px;
            object-fit: cover;
            width: 20px;
        }
        .liw-cross {
            align-items: center;
            display: flex;
            margin-left: 7px;
        }
    }
    .list-ios,
    .list-md {
        background: transparent;
        border-radius: $form-br;
    }
    .liw-label {
        text-overflow: initial;
        white-space: initial;
    }
}
