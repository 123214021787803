.custom-filter-input-portfolio-web-modal {
    .cfipwm-label {
        align-items: center;
        display: flex;
        justify-content: space-between;
        white-space: initial;
        .cfipwm-icon {
            color: var(--mo-color-mo-color);
            display: flex;
        }
    }
    .cfipwm-select-option-wrap {
        display: flex;
        flex: 1;
        .cfipwm-select-options {
            border-right: 1px solid var(--mo-color-ln);
            height: 100%;
            position: relative;
            width: 50%;
            .cfipwm-filters {
                background: var(--mo-color-bg2);
                position: sticky;
                top: 0;
                z-index: 1;
            }
            .cfipwm-select-list-wrap {
                background: var(--mo-color-bg2);
            }
        }
        .cfipwm-select-selected {
            height: 100%;
            width: 50%;
        }
    }
}
