.login-page-web {
    .lpw-content {
        background: var(--mo-color-bg1);
        display: flex;
        @media (max-width: 576px) {
            height: 100%;
            padding-top: $nav-height-about-mobile;
        }
        .lpw-left {
            padding: 20px;
            max-width: 500px;
            margin: auto;
            width: 50%;
            @media (max-width: 576px) {
                width: 100%;
            }
            .lpw-title {
                font-size: 56px;
                font-weight: bold;
                line-height: 1;
                margin-bottom: 30px;
                text-transform: uppercase;
            }
        }
        .lpw-right {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: $nav-height-vh;
            width: 50%;
            @media (max-width: 576px) {
                display: none;
            }
        }
    }
}
