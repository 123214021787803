.form-panel {
    display: flex;
    .fp-fieldsets {
        flex: 1;
        .fp-wrap-title {
            border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
            border-top: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
            margin: 20px 0;
            padding: 10px 0;
            .fp-title {
                font-weight: bold;
                padding-left: 0 !important;
                text-transform: uppercase;
            }
        }
    }
}
