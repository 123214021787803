.detail-component-row {
    border-bottom: 1px solid rgba(var(--mo-color-text1-rgb), 0.1);
    display: flex;
    padding: 10px;
    &.error {
        color: $error;
    }
    &.RichTextField {
        .dcr-value {
            white-space: initial;
            .default-margin {
                h1, h2, h3, h4, h5, h6, p {
                    margin-block-end: 1em;
                    margin-block-start: 1em;
                }
            }
        }
    }
    .dcr-title {
        color: var(--mo-color-text2);
        flex-shrink: 0;
        font-size: 14px;
        text-transform: capitalize;
        width: 150px;
        .dcr-title-superuser {
            display: block;
            font-size: 10px;
        }
    }
    .dcr-value {
        flex: 1;
        padding-left: 10px;
        white-space: pre-line;
        .mcfw-name {
            color: var(--mo-color-text1);
        }
        .dcr-value-wrap {
            display: flex;
            .dcr-copy {
                color: var(--mo-color-text2);
                font-size: 13px;
                padding-right: 10px;
                padding-top: 5px;
                &.hidden {
                    visibility: hidden;
                }
            }
            .dcr-link-href {
                color: var(--mo-color-text2);
                font-size: 13px;
                padding-left: 10px;
                padding-top: 5px;
            }
        }
        .dcr-translation {
            padding-top: 10px;
            .dcr-language {
                color: var(--mo-color-text2);
                font-size: 14px;
            }
        }
    }
    .dcr-staff {
        align-items: center;
        color: var(--mo-color-text2);
        display: flex;
        flex-shrink: 0;
        font-size: 14px;
        padding-left: 10px;
        text-transform: capitalize;
        .dcr-title-field {
            display: block;
            text-transform: lowercase;
        }
        .dcr-title-original {
            display: block;
        }
        .dcr-staff-icons {
            align-items: center;
            cursor: pointer;
            display: flex;
            margin-left: 10px;
            .main-icon {
                align-items: center;
                display: flex;
            }
        }
    }
}
