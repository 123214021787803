.mo-player {
    display: none;
    left: 0;
    position: absolute;
    // transition: 1s all cubic-bezier(.78,-0.15,.18,1.18);
    width: 100%;
    z-index: 10;
    &.bottom {
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(var(--mo-color-bg2-rgb), 0.7);
        bottom: 43px;
        display: flex;
        height: 55px;
        .mo-player-header {
            display: none;
        }
        .mo-player-block {
            background: black;
            height: 55px;
            position: relative;
            width: 55px;
            &:after {
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
            .mo-player-cover {
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
        .mo-player-platform-wrap {
            display: none;
        }
        .mo-player-info {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            padding: 5px 10px;
            .mo-player-info-name {
                font-weight: 600;
                font-size: 14px;
            }
            .mo-player-info-profile {
                color: var(--mo-color-text2);
                font-size: 13px;
            }
        }
        .mo-player-range {
            display: none;
        }
        .mo-player-action {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            padding: 5px 15px 5px 0;
            .mo-player-action-icon {
                display: flex;
                font-size: 20px;
                margin-left: 20px;
            }
            .mo-player-action-full {
                display: none;
            }
            .mo-player-platform-wrap-bottom {
                align-items: center;
                display: flex;
                padding-right: 10px;
                justify-content: flex-end;
                .mo-player-powered {
                    font-size: 12px;
                    white-space: nowrap;
                }
                .mo-player-platform {
                    margin-left: 5px;
                    height: 10px;
                }
            }
        }
        .mo-player-next {
            display: none;
        }
    }
    &.detail {
        display: flex;
        flex-direction: column;
        .mo-player-header {
            opacity: 0;
            z-index: -10;
        }
        .mo-player-block {
            background: black;
            height: calc(100vw / 16 * 9);
            overflow: hidden;
            position: relative;
            width: 100%;
            .mo-player-cover {
                display: none;
            }
        }
        .mo-player-platform-wrap {
            display: none;
        }
        .mo-player-info {
            display: none;
        }
        .mo-player-range {
            display: none;
        }
        .mo-player-action {
            display: none;
        }
        .mo-player-next {
            display: none;
        }
    }
    &.full {
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(var(--mo-color-bg2-rgb), 0.7);
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100vh;
        position: relative;
        .mo-player-header {
            background: black;
            display: flex;
            justify-content: space-around;
            padding: 10px;
        }
        .mo-player-platform-wrap {
            align-items: center;
            display: flex;
            padding: 10px 10px 20px;
            justify-content: center;
            .mo-player-platform {
                margin-left: 5px;
                height: 15px;
            }
        }
        .mo-player-block {
            background: black;
            height: calc(100vw / 16 * 9);
            position: relative;
            width: 100%;
            .mo-player-cover {
                display: none;
            }
        }
        .mo-player-info {
            padding: 10px;
            text-align: center;
            .mo-player-info-name {
                font-weight: 600;
                margin-bottom: 10px;
            }
            .mo-player-info-profile {
                color: var(--mo-color-text2);
                font-size: 14px;
            }
        }
        .mo-player-range {
            display: block;
            .mo-player-range-duration {
                margin-top: -10px;
                padding: 0 15px;
                display: flex;
                font-size: 13px;
                color: var(--mo-color-text2);
                justify-content: space-between;
            }
        }
        .mo-player-action {
            .mo-player-action-icon {
                display: none;
            }
            .mo-player-action-full {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
                .mo-player-action-icon-full {
                    align-items: center;
                    display: flex;
                    &.mo-player-action-icon-full-main {
                        font-size: 40px;
                        height: 40px;
                    }
                }
            }
        }
        .mo-player-next {
            align-items: center;
            bottom: 5px;
            color: var(--mo-color-text2);
            display: flex;
            font-size: 14px;
            justify-content: space-between;
            left: 0;
            padding: 10px;
            position: absolute;
            width: 100%;
        }
    }
    &.landscape {
        background: black;
        display: flex;
        flex-direction: column;
        height: 100vh;
        top: 0;
        .mo-player-header {
            display: none;
        }
        .mo-player-platform-wrap {
            display: none;
        }
        .mo-player-block {
            background: black;
            height: 100%;
            width: 100%;
            .mo-player-cover {
                display: none;
            }
        }
        .mo-player-info {
            display: none;
        }
        .mo-player-range {
            display: none;
        }
        .mo-player-action {
            display: none;
        }
        .mo-player-next {
            display: none;
        }
    }
}
