.team-list-medium-block {
    margin-bottom: 30px;
    padding: 0 10px;
    position: relative;
    width: 100%;
    .tlmb-wrap {
        display: flex;
        position: relative;
        .tlmb-profile-pic {
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;
            width: 80px;
            .tlmb-image-link {
                display: block;
                padding-bottom: 100%;
                position: relative;
                width: 100%;
                .tlmb-image {
                    height: 100%;
                    position: absolute;
                }
            }
        }
        .tlmb-info {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            padding: 0 10px 0 13px;
            .tlmb-chips {
                margin-bottom: 10px;
                max-height: 22px;
                overflow: hidden;
            }
            .tlmb-country {
                display: flex;
                flex-wrap: wrap;
                height: 18px;
                overflow: hidden;
            }
        }
        .tlmb-action {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
}
