.profile-detail-portfolio-web {
    .pdpw-content {
        padding: 20px;
        .pdpw-title {
            border-bottom: 1px solid var(--mo-color-ln);
            font-size: 33px;
            font-weight: 600;
            margin-bottom: 10px;
            padding: 0 7px;
        }
        .pdpw-infinite {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 50px;
            .portfolio-profile-user-list-block-web {
                @media (max-width: 3040px) {
                    width: calc(100% / 6);
                }
                @media (max-width: 2540px) {
                    width: calc(100% / 5);
                }
                @media (max-width: 2040px) {
                    width: calc(100% / 4);
                }
                @media (max-width: 1540px) {
                    width: calc(100% / 3);
                }
                @media (max-width: 1040px) {
                    width: calc(100% / 2);
                }
                @media (max-width: 540px) {
                    width: 100%;
                }
            }
        }
    }
}
