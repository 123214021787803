.project-list-big-block {
    padding-bottom: 50px;
    position: relative;
    width: 100%;
    .plbb-cover {
        border-radius: 7px;
        overflow: hidden;
        position: relative;
        .plbb-cover-wrap {
            display: block;
            position: relative;
            .plbb-image {
                width: 100%;
            }
            .plbb-extra-info {
                background-color: rgba(var(--mo-color-bg2-rgb), 0.7);
                bottom: 0;
                color: var(--mo-color-text1);
                margin-bottom: 0;
                padding: 0 5px;
                position: absolute;
                right: 0;
                text-transform: lowercase;
                z-index: 3;
            }
        }
        .plbb-play-btn {
            bottom: 5px;
            left: 0;
            position: absolute;
            z-index: 4;
            .mo-new-icon-play-circle-regular {
                font-size: 20px;
            }
        }
    }
    .plbb-bottom {
        padding: 5px 10px 10px;
        .plbb-bottom-header {
            align-items: center;
            display: flex;
            justify-content: space-between;
            .plbb-feed-date {
                color: var(--mo-color-text2);
            }
            .plbb-actions {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 5px;
            }
        }
        .plbb-tags {
            .main-chip-list-block {
                max-height: 87px;
            }
        }
        .plbb-credit-detail {
            .plbb-roles {
                height: 24px;
                overflow: hidden;
            }
        }
    }
}
