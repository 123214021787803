.admin-home {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: center;
    .ad-cm-title {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .ad-cm-text {
        padding: 20px 0 50px;
    }
    .ah-sphere {
        height: 200px;
    }
    .ah-quote-block {
        border: 1px solid var(--mo-color-ln);
        margin-top: 30px;
        max-width: 700px;
        padding: 30px;
        .ah-quote-text {
            color: var(--mo-color-text1);
            font-size: 19px;
            padding-bottom: 20px;
        }
        .ah-quote-author {
            color: var(--mo-color-text2);
        }
    }
}
