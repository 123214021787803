.select-list-input-list-disabled {
    opacity: 0.4;
}
.select-list-input-layout-custom-label {
    align-items: center;
    display: flex;
    .slilcl-lcl-image-wrap {
        flex-shrink: 0;
        height: 30px;
        overflow: hidden;
        width: 30px;
        .slilcl-lcl-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        &.zarma {
            align-items: center;
            background: var(--mo-color-bg1);
            display: flex;
            justify-content: center;
            .slilcl-image-zarma-name {
                text-transform: uppercase;
            }
        }
    }
    .slilcl-lcl-text-wrap {
        margin-left: 15px;
        .slilcl-lcl-profile-text {
            font-size: 10px;
        }
    }
}
.slilcl-option-select-description {
    font-size: 14px;
    margin-top: 5px;
}
