.phone-number-helper {
    background: transparent;
    border: none;
    padding: 0;
    .PhoneInputCountrySelectArrow {
        display: none;
    }
    .PhoneInputInput {
        background: transparent;
        border: none;
        padding: 0;
    }
    .PhoneInputCountry {
        border: 0;
    }
    .PhoneInputCountrySelect {
        display: none;
    }
    .PhoneInputCountryIcon {
        background: none;
        box-shadow: none;
        height: 13px;
    }
}
