.search-page {
    .sp-header {
        padding-top: 12px;
        transition: $mo-ease;
        .sp-header-toolbar {
            --border-color: black;
            .sp-search {
                padding-bottom: 12px;
                input {
                    background: var(--mo-color-bg1);
                }
            }
            .sp-filter {
                font-weight: 600;
            }
        }
    }
    .sp-content {
        .sp-infinite-div {
            padding: 10px 0;
            &.medium {
                padding: 10px 5px;
            }
            .sp-message-div {
                display: flex;
                justify-content: center;
            }
            .sp-message {
                padding: 20px;
                text-align: center;
            }
            @media (min-width: 768px) {
                width: 540px;
                margin: auto;
            }
        }
    }
}
