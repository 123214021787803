.form-container {
    .fc-name-button {
        max-width: 150px;
    }
    .fc-form-content {
        .fc-nav-mode-wrap {
            align-items: center;
            display: flex;
            justify-content: center;
        }
        .fc-nav-preview-link-wrap {
            display: flex;
            flex-direction: column;
            padding-right: 15px;
            .fc-nav-preview-link {
                font-size: 14px;
                line-height: 1;
            }
        }
        .fc-nav-go-to-builder {
            margin-right: 15px;
        }
        .fc-form-panels-wrap {
            height: calc(100vh - #{$nav-children-height});
            display: flex;
            .fc-form-panels {
                background: var(--mo-color-bg1);
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                transition: $mo-ease;
                width: 100%;
                &.fullscreen {
                    margin-left: -370px;
                    &.mixed-media,
                    &.mixed-media-content {
                        margin-left: -340px;
                    }
                }
                &.mixed-media,
                &.mixed-media-content {
                    width: 340px;
                    .fc-form-panels-children {
                        padding: 0;
                    }
                }
                &.menu,
                &.submenu {
                    .fc-form-panels-children {
                        padding: 0;
                    }
                }
                &.special {
                    .fc-form-panels-children {
                        padding: 15px 15px 0;
                    }
                }
                .fc-form-panels-children {
                    padding: 15px;
                    flex: 1;
                    .fc-form-panels-header {
                        font-size: 24px;
                        padding-bottom: 20px;
                    }
                    // TODO move
                    .mo-text-input-web,
                    .mo-text-input-webnew {
                        display: flex;
                        margin-bottom: 10px;
                    }
                    .main-form-label {
                        font-size: 14px;
                        margin-bottom: 0;
                        width: 200px;
                    }
                }
                .fc-footer-wrap {
                    border-top: 1px solid var(--mo-color-ln);
                    padding: 10px;
                    .fc-footer {
                        display: flex;
                        .fc-footer-button {
                            width: 50%;
                            &.fc-finish {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .fc-form-preview-mixed-media-content {
                flex: 1;
                .mixed-template-preview-wrap {
                    position: relative;
                    width: 100%;
                    .mixed-template-preview {
                        height: 100%;
                        position: relative;
                    }
                    &.is-mobile {
                        margin: 30px 30px 30px 0;
                        width: calc(375px + 60px + 30px);
                    }
                    &.is-tablet {
                        margin: 30px 30px 30px 0;
                        width: calc(768px + 60px + 30px);
                    }
                    &.is-web {
                        padding: 30px 0;
                        width: calc(100vw - 340px);
                    }
                }
                &.fullscreen {
                    .mixed-template-preview-wrap {
                        padding: 0;
                        width: 100vw;
                        .mixed-template-preview {
                            .slug-page-content-wrap {
                                .slug-page-content-div {
                                    height: $portfolio-height-vh;
                                    .slug-page-content {
                                        padding: 0;
                                        .slug-page-content-list {
                                            .hpc-selection-helper {
                                                .selection-helper-page-content-web-left,
                                                .selection-helper-page-content-web-right {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .fc-content-header {
                padding: 20px 0 0;
                --border-color: black;
            }
        }
    }
}
.form-container-modal {
    .wrapper {
        height: 95vh;
        width: 95vw;
    }
    // TODO move
    .mo-text-input-web,
    .mo-text-input-webnew {
        display: flex;
        margin-bottom: 10px;
    }
    .main-form-label {
        font-size: 14px;
        margin-bottom: 0;
    }
    .mo-image-crop-input-web,
    .mo-image-crop-square-input-web {
        width: 340px;
    }
}
