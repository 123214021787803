.main-country-flag-web {
    align-items: center;
    display: flex;
    margin-right: 5px;
    height: 16px;
    &.no-flag {
        margin-right: 0px;
        &.end {
            margin-left: 0px;
        }
        &.start {
            margin-right: 0px;
        }
    }
    &.edit,
    &.noclick {
        pointer-events: none;
    }
    .mcfw-country-wrap {
        align-items: center;
        cursor: pointer;
        display: flex;
        &:hover {
            .mcfw-name {
                color: var(--mo-color-mo-color);
            }
        }
        .mcfw-name {
            color: var(--mo-color-text2);
            transition: $mo-ease;
            &.end {
                margin-left: 5px;
            }
            &.start {
                margin-right: 5px;
            }
        }
        .mcfw-flag {
            width: 16px;
            height: 16px;
        }
    }
    .mcfw-city-wrap {
        .mcfw-city-pipe {
            padding: 0 5px;
        }
    }
}
