// Just The Web
body.dark {
    --mo-color-bg1: #202020;
    --mo-color-bg2: #181818;
    --mo-color-bg2-rgb: 24, 24, 24;
    --mo-color-ln: #383838;
    --mo-color-text1: #ffffff;
    --mo-color-text1-rgb: 255, 255, 255;
    --mo-color-text2: #a2a3a4;
    --mo-color-black: #000000;
    --mo-color-black-rgb: 0, 0, 0;
    --mo-color-mo-color: #8ea1ff;
    --mo-color-mo-color-rgb: 142, 161, 255;
}
body.light {
    --mo-color-bg1: #ffffff;
    --mo-color-bg2: #f2f2f2;
    --mo-color-bg2-rgb: 242, 242, 242;
    --mo-color-ln: #e4e4e4;
    --mo-color-text1: #000000;
    --mo-color-text1-rgb: 0, 0, 0;
    --mo-color-text2: #6e6f70;
    --mo-color-black: #ffffff;
    --mo-color-black-rgb: 255, 255, 255;
    --mo-color-mo-color: #8ea1ff;
    --mo-color-mo-color-rgb: 142, 161, 255;
}
body.agenda-du-quartier {
    --mo-color-mo-color: #14686c;
    --mo-color-mo-color-rgb: 20, 104, 108;
    --mo-color-mo-color2: #12356a;
    --mo-color-mo-color2-rgb: 18, 53, 106;
    --mo-color-mo-color3: #2d80a2;
    --mo-color-mo-color3-rgb: 45, 120, 162;
}
body.agsl {
    --mo-color-mo-color: #d45500;
    --mo-color-mo-color-rgb: 212, 85, 0;
}
$error: #dc3545;
$white1: #f4f4f4;
$sitegray1: #141418;
$sitegray3: #898f99;
$sitegray4: #2b2c31;
.mo-color {
    color: var(--mo-color-mo-color) !important;
}
/*
* Dark Colors
* -------------------------------------------
*/
body.dark {
    --ion-color-primary: #ffffff;
    --ion-color-primary-rgb: 81, 218, 248;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #d7d8da;
    --ion-color-primary-tint: #a2a4ab;
    --ion-color-secondary: #8ea1ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;
    --ion-color-tertiary: #181818;
    --ion-color-tertiary-rgb: 0, 0, 0;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #2d2d2d;
    --ion-color-tertiary-tint: #2d2d2d;
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
    --ion-color-warning: #ffffff;
    --ion-color-warning-rgb: 255, 255, 255;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d3d3d3;
    --ion-color-warning-tint: #ffd948;
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
    --ion-color-dark: #000000;
    --ion-color-dark-rgb: 0, 0, 0;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #141418;
    --ion-color-dark-tint: #898f99;
    --ion-color-medium: var(--mo-color-text1);
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    --ion-color-light: #1f1f24;
    --ion-color-light-rgb: 31, 31, 36;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #141418;
    --ion-color-light-tint: #898f99;
}
body.dark {
    --ion-background-color: var(--mo-color-bg2);
    --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-toolbar-background: transparent;
    --ion-item-background: #1c1c1c;
    --ion-item-background-activated: #313131;
}
body.light {
    --ion-background-color: var(--mo-color-bg2);
    // --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #000000;
    --ion-text-color-rgb: 0, 0, 0;
    // --ion-color-step-50: #0d0d0d;
    // --ion-color-step-100: #1a1a1a;
    // --ion-color-step-150: #262626;
    // --ion-color-step-200: #333333;
    // --ion-color-step-250: #404040;
    // --ion-color-step-300: #4d4d4d;
    // --ion-color-step-350: #595959;
    // --ion-color-step-400: #666666;
    // --ion-color-step-450: #737373;
    // --ion-color-step-500: #808080;
    // --ion-color-step-550: #8c8c8c;
    // --ion-color-step-600: #999999;
    // --ion-color-step-650: #a6a6a6;
    // --ion-color-step-700: #b3b3b3;
    // --ion-color-step-750: #bfbfbf;
    // --ion-color-step-800: #cccccc;
    // --ion-color-step-850: #d9d9d9;
    // --ion-color-step-900: #e6e6e6;
    // --ion-color-step-950: #f2f2f2;
    --ion-toolbar-background: transparent;
    // --ion-item-background: #1c1c1c;
    // --ion-item-background-activated: #313131;
}
