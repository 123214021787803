.table-row {
    td {
        border-bottom: 1px solid var(--mo-color-ln);
        border-left: 1px solid var(--mo-color-ln);
        border-right: 1px solid var(--mo-color-ln);
        white-space: pre-line;
        word-break: normal;
        &:first-child {
            border-left: 3px solid var(--mo-color-ln);
        }
        &:last-child {
            border-right: 3px solid var(--mo-color-ln);
        }
        &.tr-action {
            transition: background-color 0.3s ease;
            width: 80px;
            .tr-action-div {
                display: flex;
                justify-content: center;
                padding: 0 5px;
                .tr-action-checkbox {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    margin-right: 6px;
                    transform: scale(1.2);
                }
                .main-icon-block-web {
                    display: flex;
                    .mo-new-icon-pencil-alt-solid {
                        font-size: 14px;
                    }
                    .mo-new-icon-link-solid {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    &:last-child {
        td {
            border-bottom: 3px solid var(--mo-color-ln);
        }
    }
    &.draggable {
        cursor: grab;
        .tr-action {
            .tr-action-div {
                .main-icon-block-web {
                    cursor: grab;
                }
            }
        }
        .table-column {
            .tc-link {
                cursor: grab;
            }
        }
    }
    &.is-dragging {
        border: 3px dashed var(--mo-color-mo-color) !important;
        opacity: 0.5;
        td {
            border: none;
        }
    }
    &.is-hovered {
        border: 3px dashed red !important;
        opacity: 0.5;
        td {
            border: none;
        }
    }
}
