.description-content-block {
    background: black;
    padding: 12px;
    position: relative;
    .dcb-wrap {
        margin: auto;
        max-width: 650px;
        .dcb-main-info {
            align-items: center;
            border-bottom: solid 1px var(--mo-color-ln);
            display: flex;
            justify-content: space-between;
            padding-bottom: 17px;
            .dcb-date {
                color: var(--mo-color-text2);
            }
        }
        .dcb-description {
            border-bottom: solid 1px var(--mo-color-ln);
            color: var(--mo-color-text2);
            padding: 17px 0;
            white-space: pre-line;
        }
        .dcb-bravo1 {
            border-bottom: solid 1px var(--mo-color-ln);
            padding: 20px 0;
            .dcb-charlie1 {
                padding-bottom: 5px;
                text-transform: uppercase;
            }
            .dcb-charlie2 {
                color: var(--mo-color-text2);
                span {
                    display: block;
                }
                .dcb-country {
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    .main-hashtag {
                        padding-bottom: 5px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    .main-country-flag-web {
                        height: auto;
                    }
                }
            }
            .dcb-charlie3 {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
            }
            .dcb-charlie5 {
                .dcb-echo1 {
                    height: 20px;
                }
                .dcb-echo2 {
                    color: var(--mo-color-text2);
                    margin-top: 5px;
                }
            }
            .dcb-extra-added {
                color: var(--mo-color-text2);
                display: flex;
                span {
                    margin-right: 5px;
                }
            }
            .dcb-extra-count {
                align-items: center;
                color: var(--mo-color-text2);
                display: flex;
                .dcb-gold1 {
                    align-items: center;
                    display: flex;
                    margin-right: 5px;
                    .icon {
                        height: 20px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
.album-block {
    .ab-alfa1 {
        align-items: center;
        display: flex;
        margin: 10px 0;
        .ab-bravo1 {
            .ab-charlie1 {
                display: block;
                height: 100px;
                object-fit: contain;
                object-position: center;
                width: 100px;
            }
        }
        .ab-bravo2 {
            padding-left: 15px;
        }
    }
}
