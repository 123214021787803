.header-detail-block-web {
    .hdbw-name-block {
        border-bottom: solid 1px var(--mo-color-ln);
        padding: 12px 0;
        .hdbw-name {
            font-weight: 600;
        }
    }
    .hdbw-tags {
        border-bottom: solid 1px var(--mo-color-ln);
        padding: 12px 0;
    }
    .hdbw-profile-block {
        border-bottom: solid 1px var(--mo-color-ln);
        display: flex;
        flex-direction: column;
        padding: 12px 0;
        .credit-short-info-web,
        .profile-short-info-web {
            margin: 15px 0 0;
            &:first-child {
                margin: 0;
            }
        }
    }
    .hdbw-tag-block {
        border-bottom: solid 1px var(--mo-color-ln);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 12px 0;
        .hdbw-tag-wrap {
            display: flex;
            flex-wrap: wrap;
            .hdbw-tag-name {
                color: var(--mo-color-text2);
                margin-right: 5px;
                text-transform: uppercase;
            }
        }
    }
}
.detail-block-right-web {
    border-left: 1px solid var(--mo-color-ln);
    flex-shrink: 0;
    padding: 0 12px;
    position: relative;
    width: 370px;
}
