.mo-quadruple-input-web {
    margin-bottom: 15px;
    .qiw-input-wrap {
        display: flex;
        align-items: center;
        .qiw-input-action {
            align-items: center;
            cursor: pointer;
            display: block;
            display: flex;
            justify-content: center;
            .mo-new-icon-minus-solid,
            .mo-new-icon-plus-solid {
                display: flex;
                font-size: 20px;
            }
            .mo-new-icon-minus-solid {
                padding-right: 10px;
            }
            .mo-new-icon-plus-solid {
                padding-left: 10px;
            }
        }
        .qiw-inherit {
            align-items: center;
            display: flex;
            flex-shrink: 0;
            font-size: 13px;
            margin-left: 20px;
            .qiw-inherit-text {
                margin-left: 5px;
            }
        }
        .qiw-close-wrap {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            .qiw-close-icon {
                align-items: center;
                cursor: pointer;
                display: flex;
                font-size: 12px;
                transition: $mo-ease;
                &:hover {
                    color: var(--mo-color-mo-color);
                }
            }
        }
    }
    .qiw-show {
        cursor: pointer;
        display: block;
        margin: 10px 0;
        text-align: right;
        span {
            padding-right: 10px;
            &:hover {
                color: var(--mo-color-mo-color);
            }
        }
    }
    .qiw-children {
        .qiw-child {
            .qiw-position-wrap {
                padding-right: 10px;
                .qiw-position {
                    display: flex;
                    &.bottom {
                        transform: rotate(-90deg);
                    }
                    &.right {
                        transform: rotate(180deg);
                    }
                    &.top {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
    .qiw-range {
        ion-range {
            --bar-background: #d3d3d3;
            --bar-background-active: #8ea1ff;
            --knob-background: #8ea1ff;
            --knob-size: 12px;
            --height: 20px;
        }
    }
}
