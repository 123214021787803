.profile-detail-list-web {
    .pdlw-content {
        display: flex;
        height: $nav-height-vh;
        .pdlw-left {
            background: var(--mo-color-bg1);
            border-right: 1px solid var(--mo-color-ln);
            height: $nav-height-vh;
            padding: 0 15px 15px;
            position: relative;
            width: 240px;
            .pdlw-header-left {
                background: var(--mo-color-bg1);
                margin: 0 -15px;
                padding: 15px 15px 0;
                position: sticky;
                top: 0;
                z-index: 101;
                .pdlw-profile-wrap {
                    display: flex;
                    padding-bottom: 20px;
                    .pdlw-profile-pic {
                        cursor: pointer;
                        flex-shrink: 0;
                        height: 24px;
                        margin-right: 10px;
                        width: 24px;
                    }
                    .pdlw-profile-name {
                        align-items: center;
                        cursor: pointer;
                        display: flex;
                        flex: 1;
                        font-size: 13px;
                        padding-right: 10px;
                    }
                }
                .pdlw-thumb-wrap {
                    border-bottom: 1px solid var(--mo-color-ln);
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 10px;
                    .pdlw-size-icon {
                        .main-icon {
                            font-size: 15px;
                        }
                        &.active {
                            .main-icon {
                                color: var(--mo-color-mo-color);
                            }
                        }
                    }
                }
            }
        }
        .pdlw-right {
            flex: 1;
            height: $nav-height-vh;
            padding-bottom: 10px;
            position: relative;
            &.table {
                padding-bottom: 0;
            }
            .pdlw-header-right {
                background: var(--mo-color-bg1);
                border-bottom: 1px solid var(--mo-color-ln);
                display: flex;
                justify-content: space-between;
                position: sticky;
                top: 0;
                z-index: 10;
                .pdlw-thumb-wrap {
                    align-items: center;
                    display: flex;
                    flex: 1;
                    padding: 10px;
                    width: 100%;
                    .pdlw-status-link {
                        cursor: pointer;
                        margin: 0 10px;
                        position: relative;
                        &.active {
                            color: var(--mo-color-mo-color);
                            &:after {
                                border-top: 3px solid var(--mo-color-mo-color);
                                bottom: -4px;
                                content: "";
                                left: 0;
                                position: absolute;
                                right: 0;
                                z-index: -1;
                            }
                        }
                        &:hover {
                            color: var(--mo-color-mo-color);
                        }
                    }
                }
                .pdlw-search-wrap {
                    align-items: center;
                    display: flex;
                    padding: 0 20px;
                    .pdlw-searchbar {
                        height: 50px;
                        padding: 0 10px;
                        width: 350px;
                        .searchbar-search-icon {
                            display: none;
                            height: 0;
                            width: 0;
                        }
                        .searchbar-input {
                            background: var(--mo-color-bg2);
                            padding-inline-start: 13px;
                        }
                    }
                    .pdlw-icon {
                        margin-left: 10px;
                    }
                }
            }
            .pdlw-count-wrap {
                font-size: 20px;
                padding: 20px 20px 0;
                &.table {
                    padding: 20px;
                }
            }
            .pdlw-count-wrap-hub {
                align-items: center;
                display: flex;
                font-size: 20px;
                padding-left: 20px;
            }
            .pdlw-infinite-table {
                height: calc(#{$nav-height-vh} - 51px);
                table {
                    border-spacing: 0;
                    min-width: 100%;
                    position: relative;
                    tbody {
                        tr:nth-child(even) {
                            background-color: var(--mo-color-bg2);
                            td {
                                background-color: var(--mo-color-bg2);
                            }
                        }
                        tr:nth-child(odd) {
                            background-color: var(--mo-color-bg1);
                            td {
                                background-color: var(--mo-color-bg1);
                            }
                        }
                    }
                }
            }
            .pdlw-infinite {
                display: flex;
                flex-wrap: wrap;
                padding: 20px;
                .block-template-list-block-web,
                .event-list-small-block-web,
                .material-list-small-block-web,
                .offer-list-small-block-web,
                .page-template-list-block-web,
                .product-list-small-block-web,
                .project-list-small-block-web,
                .service-list-small-block-web,
                .square-block {
                    @media (max-width: 2680px) {
                        width: calc(100% / 8);
                    }
                    @media (max-width: 2380px) {
                        width: calc(100% / 7);
                    }
                    @media (max-width: 2080px) {
                        width: calc(100% / 6);
                    }
                    @media (max-width: 1780px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 1480px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1180px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 880px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 580px) {
                        width: 100%;
                    }
                }
                .portfolio-template-list-block-web {
                    width: 25%;
                }
                .portfolio-list-block-web,
                .press-list-small-block-web {
                    @media (max-width: 2780px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 2280px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1780px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 1280px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 780px) {
                        width: 100%;
                    }
                }
                .profile-list-small-block-web,
                .team-list-small-block-web {
                    @media (max-width: 2780px) {
                        width: calc(100% / 10);
                    }
                    @media (max-width: 2530px) {
                        width: calc(100% / 9);
                    }
                    @media (max-width: 2280px) {
                        width: calc(100% / 8);
                    }
                    @media (max-width: 2030px) {
                        width: calc(100% / 7);
                    }
                    @media (max-width: 1780px) {
                        width: calc(100% / 6);
                    }
                    @media (max-width: 1530px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 1280px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1030px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 780px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 530px) {
                        width: 100%;
                    }
                }
            }
            &.big {
                .press-list-big-block-web .plbb-cover .plbb-cover-wrap .plbb-image {
                    margin: auto;
                    max-width: 100%;
                    width: auto;
                }
                .project-list-big-block-web .plbb-cover .plbb-cover-wrap .plbb-image {
                    margin: auto;
                    max-width: 100%;
                    width: auto;
                }
            }
            &.map {
                padding-bottom: 0;
            }
            &.medium {
                padding: 0 5px 10px;
            }
            .pdlw-map-wrap {
                display: flex;
                height: $nav-height-vh;
                width: 100%;
                .pdlw-map {
                    flex: 1;
                    .leaflet-container {
                        background: #191a1a;
                        height: 100%;
                        width: 100%;
                        a {
                            color: initial;
                            text-decoration: initial;
                        }
                        .pdlw-map-searchbox {
                            border: 2px solid rgba(var(--mo-color-bg2-rgb), 0.2);
                            .search-control {
                                border-radius: 2px;
                                .search-control-icon-button {
                                    width: 30px;
                                }
                            }
                        }
                    }
                }
                .pdlw-map-items {
                    height: $nav-height-vh;
                    width: 300px;
                    .pdlw-map-item-detail {
                        padding: 0 5px;
                    }
                }
            }
            .pdlw-table {
                position: relative;
                .pdlw-table-header {
                    th {
                        padding: 10px;
                    }
                }
                tr:nth-child(even) {
                    background-color: var(--mo-color-bg1);
                }
            }
        }
    }
}
