.popover-web {
    background: var(--mo-color-bg1);
    border-radius: 3px;
    border: 1px solid var(--mo-color-mo-color);
    box-shadow: 0 0 4px 2px rgba(var(--mo-color-mo-color-rgb), 0.70);
    color: var(--mo-color-text1);
    overflow: hidden;
    .popover-web-item {
        .popover-web-item-button {
            background: var(--mo-color-bg1);
            border-bottom: 1px solid var(--mo-color-ln);
            padding: 10px;
            text-align: left;
            transition: $mo-ease;
            width: 100%;
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
            &.link {
                display: block;
                line-height: 1;
            }
            .popover-web-item-button-text {
                color: var(--mo-color-text1);
                font-size: 14px;
                transition: $mo-ease;
            }
            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
            &:focus,
            &:hover {
                outline: none;
                .popover-web-item-button-text {
                    color: var(--mo-color-mo-color);
                }
            }
            &:hover {
                background: var(--mo-color-bg1);
            }
        }
    }
}
