.event-list-big-block {
    padding-bottom: 50px;
    position: relative;
    width: 100%;
    .elbb-cover {
        border-radius: 7px;
        display: block;
        overflow: hidden;
        .elbb-image {
            width: 100%;
        }
    }
    .elbb-bottom {
        padding: 5px 10px 10px;
        .elbb-bottom-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .elbb-feed-date {
                color: var(--mo-color-text2);
            }
            .elbb-actions {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 5px;
            }
        }
        .elbb-date {
            color: var(--mo-color-text2);
            padding-bottom: 5px;
        }
        .elbb-address {
            padding-top: 10px;
            .elbb-icon {
                margin-right: 5px;
            }
        }
    }
}
