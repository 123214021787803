.form-component-field-set {
    display: flex;
    .fcfs-fieldsets {
        flex: 1;
        .fcfs-wrap-title {
            border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
            border-top: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
            padding: 10px;
            .fcfs-title {
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        .fcfs-fields {
            padding: 10px;
            .fcfs-input {
                display: flex;
                margin-bottom: $form-mb-new;
                &.disabled {
                    cursor: not-allowed;
                    opacity: 0.4;
                }
                .fcfs-label-wrap {
                    flex-shrink: 0;
                    padding: 5px 5px 0 0;
                    width: 150px;
                    .form-label {
                        flex-direction: column;
                        .form-label-helper {
                            margin: 10px 0;
                        }
                    }
                    .fcfs-staff {
                        color: var(--mo-color-text2);
                        font-size: 11px;
                    }
                }
                .fcfs-input-wrap {
                    flex: 1;
                }
            }
        }
    }
}
