.sub-content-type-90-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto;
    padding: 40px;
    .sct90f-block {
        background-color: var(--mo-color-bg1);
        border-bottom: 27px solid var(--mo-color-mo-color2);
        border-radius: 14px;
        box-shadow: 0 0.0625rem 0.1875rem hsla(239, 34%, 27%, 0.15);
        .sct90f-hr-wrap {
            background-color: rgba(var(--mo-color-mo-color-rgb), 0.17);
            border-radius: 14px 14px 0 0;
            overflow: hidden;
            .sct90f-hr {
                background-color: var(--mo-color-mo-color2);
                color: white;
                font-weight: bold;
                margin: 0;
                padding: 10px 10px 10px 20px;
                transition: width 0.3s ease;
            }
        }
        .sct90f-block2 {
            padding: 20px 50px 50px;
        }
    }
}
.sct90f-input-block {
    &.inline {
        &.open {
            background: var(--mo-color-bg1);
            border-radius: 14px;
            padding: 40px;
        }
        .sct90f-title-block {
            cursor: pointer;
        }
    }
    .sct90f-title-block {
        align-items: center;
        border-bottom: 1px solid var(--mo-color-ln);
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        padding-bottom: 20px;
        &.closed {
            background: var(--mo-color-bg1);
            border-radius: 14px;
            border: none;
            margin: 0;
            padding: 40px;
            .sct90f-title-icon {
                .sct90f-arrow-wrap {
                    transform: rotate(0deg);
                }
            }
        }
        .sct90f-title {
            color: var(--mo-color-mo-color2);
            font-size: 20px;
            font-weight: bold;
        }
        .sct90f-title-icon {
            align-items: center;
            display: flex;
            font-size: 27px;
            .sct90f-status {
                align-items: center;
                color: #28bd57;
                display: flex;
                margin-right: 20px;
                &.not-completed {
                    color: #c8c8c8;
                }
            }
            .sct90f-arrow-wrap {
                align-items: center;
                display: flex;
                transition: transform 0.3s ease;
                transform: rotate(180deg);
                .sct90f-arrow {
                    align-items: center;
                    display: flex;
                }
            }
        }
    }
    .sct90f-content {
        .sct90f-helper {
            padding: 20px 0 10px;
        }
        .sct90f-add-button-block {
            .button-web {
                padding: 0;
                font-weight: bold;
            }
        }
        .sct90f-form {
            padding: 20px 0;
        }
        .sct90f-input-wrap {
            align-items: center;
            display: flex;
        }
        .sct90f-text-before {
            color: #2d80a2;
            flex-shrink: 0;
            font-weight: bold;
            margin-right: 5px;
        }
        .sct90f-input-div {
            flex: 1;
        }
        .sct90f-text-after {
            color: #2d80a2;
            flex-shrink: 0;
            font-weight: bold;
            margin-left: 5px;
        }
        .sct90f-button-block {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            .sct90f-button-block-end {
                display: flex;
                justify-content: flex-end;
                .sct90f-button-spacing {
                    width: 20px;
                }
            }
        }
        .sct90f-error {
            color: #e74c3c;
            padding: 5px 0;
        }
        .sct90f-table-wrap {
            padding: 20px 0;
            .sct90f-table {
                border-collapse: collapse;
                word-break: keep-all;
                .sct90f-action-wrap {
                    display: flex;
                    .sct90f-action {
                        cursor: pointer;
                        padding: 0 5px;
                        .sct90f-action-icon {
                            align-items: center;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
                thead {
                    th {
                        border: 1px solid var(--mo-color-mo-color);
                        font-size: 12px;
                        padding: 7px;
                    }
                }
                tbody {
                    tr {
                        td {
                            border: 1px solid var(--mo-color-mo-color);
                            padding: 7px;
                        }
                    }
                }
            }
        }
    }
}
