.style-mixed-panel-web {
    display: flex;
    flex-direction: column;
    flex: 1;
    .smpw-device {
        align-items: center;
        background: var(--mo-color-bg1);
        border-top: 1px solid var(--mo-color-ln);
        display: flex;
        justify-content: space-around;
        .smpw-device-div {
            border-bottom: 1px solid var(--mo-color-ln);
            cursor: pointer;
            display: flex;
            font-size: 15px;
            justify-content: center;
            padding: 10px;
            width: 100%;
            .icon {
                display: flex;
                margin: 0 20px;
            }
            &.active {
                border-bottom: 2px solid var(--mo-color-mo-color);
            }
        }
    }
    .smpw-style-wrap {
        flex: 1;
        padding: 15px;
        .smpw-css-class {
            cursor: pointer;
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 10px;
            &:hover {
                color: var(--mo-color-mo-color);
            }
        }
        .smpw-css-class-wrap {
            padding-left: 5px;
        }
    }
    .smpw-footer {
        background: var(--mo-color-bg1);
        padding: 15px;
    }
}
