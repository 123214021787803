.form-label {
    display: flex;
    justify-content: space-between;
    .form-label-wrap {
        color: var(--mo-color-text2);
        font-size: 14px;
    }
    .form-label-helper-wrap {
        align-items: center;
        color: var(--mo-color-text2);
        display: flex;
        font-size: 12px;
        .form-label-helper {
            display: block;
            margin: 0 10px;
            &.pointer {
                &:hover {
                    color: var(--mo-color-mo-color);
                    transition: color 0.3s ease;
                }
            }
        }
        .form-label-helper-icon {
            align-items: center;
            display: flex;
            &:hover {
                color: var(--mo-color-mo-color);
                transition: color 0.3s ease;
            }
        }
    }
}
