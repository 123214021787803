.feed-home-list-block-web {
    padding: 0 40px;
    position: relative;
    .fhlbw-title-wrap {
        align-items: center;
        border-bottom: 1px solid var(--mo-color-ln);
        display: flex;
        justify-content: space-between;
        margin: 10px;
        padding: 10px 0;
        .fhlbw-title {
            font-size: 30px;
            font-weight: 600;
        }
        .fhlbw-see-all {
            color: var(--mo-color-text2);
            cursor: pointer;
            font-size: 16px;
            margin-left: 30px;
            text-transform: uppercase;
            &:hover {
                color: var(--mo-color-mo-color);
            }
        }
    }
    .fhlbw-content {
        display: flex;
        flex-wrap: nowrap;
        .block-template-list-block-web,
        .empty-list-small-block-web,
        .event-list-small-block-web,
        .material-list-small-block-web,
        .offer-list-small-block-web,
        .page-template-list-block-web,
        .portfolio-template-list-block-web,
        .portfolio-list-block-web,
        .press-list-small-block-web,
        .product-list-small-block-web,
        .profile-list-small-block-web,
        .project-list-small-block-web,
        .service-list-small-block-web,
        .square-block,
        .team-list-small-block-web {
            flex-shrink: 0;
        }
    }
    .fhlbw-navigation-left {
        align-items: center;
        background: var(--mo-color-bg2);
        display: flex;
        height: 100%;
        left: 20px;
        position: absolute;
        top: 0;
        z-index: 10;
        .fhlbw-icon-block {
            align-items: center;
            background: var(--mo-color-text1);
            border-radius: 50%;
            box-shadow: 0 2px 11px 0 rgba(var(--mo-color-bg2-rgb), 0.5);
            color: var(--mo-color-bg2);
            cursor: pointer;
            display: flex;
            height: 42px;
            justify-content: center;
            margin-right: -22px;
            transition: $mo-ease;
            width: 42px;
            .fhlbw-icon {
                display: flex;
                font-size: 20px;
            }
            &:hover {
                background: var(--mo-color-mo-color);
            }
        }
    }
    .fhlbw-navigation-right {
        align-items: center;
        background: var(--mo-color-bg2);
        display: flex;
        height: 100%;
        position: absolute;
        right: 20px;
        top: 0;
        z-index: 10;
        .fhlbw-icon-block {
            align-items: center;
            background: var(--mo-color-text1);
            border-radius: 50%;
            box-shadow: 0 2px 11px 0 rgba(var(--mo-color-bg2-rgb), 0.5);
            color: var(--mo-color-bg2);
            cursor: pointer;
            display: flex;
            height: 42px;
            justify-content: center;
            margin-left: -22px;
            transition: $mo-ease;
            width: 42px;
            .fhlbw-icon {
                display: flex;
                font-size: 20px;
            }
            &:hover {
                background: var(--mo-color-mo-color);
            }
        }
    }
    .fhlbw-empty-end {
        flex-shrink: 0;
        width: 40px;
    }
}
