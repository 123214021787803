.team-list-form-block {
    .tlfb-name-wrap {
        align-items: center;
        display: flex;
        .tlfb-avatar {
            margin-right: 10px;
        }
    }
    .tlfb-name-div {
        margin-left: 10px;
        .tlfb-name {
            white-space: pre-line;
            &.image {
                padding-left: 10px;
            }
        }
        .tlfb-active-status {
            color: var(--mo-color-text2) !important;
            &.not-active {
                color: var(--mo-color-mo-color) !important;
            }
            &.image {
                padding-left: 10px;
            }
        }
    }
}
