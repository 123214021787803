.detail-content-page-container-web {
    height: 100%;
    .wrapper {
        background: transparent;
        height: 100vh;
        opacity: 1;
        position: relative;
        width: 100vw;
    }
    .dcpcw-backdrop {
        background: rgba(var(--mo-color-bg2-rgb), 0.85);
        cursor: zoom-out;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .dcpcw-navigation {
        .dcpcw-previous {
            align-items: center;
            color: var(--mo-color-text1);
            cursor: pointer;
            display: flex;
            justify-content: center;
            left: 0;
            position: fixed;
            top: calc(50% - 22px);
            width: calc(80px + 5vh);
            z-index: 10;
            .dcpcw-icon {
                font-size: 30px;
            }
            .mo-new-icon-chevron-left-solid {
                &.hidden {
                    opacity: 0;
                }
            }
        }
        .dcpcw-next {
            align-items: center;
            color: var(--mo-color-text1);
            cursor: pointer;
            display: flex;
            font-size: 30px;
            justify-content: center;
            position: fixed;
            right: 0;
            top: calc(50% - 22px);
            width: calc(80px + 5vh);
            z-index: 10;
            .dcpcw-icon {
                font-size: 30px;
            }
            .mo-new-icon-chevron-right-solid {
                &.hidden {
                    opacity: 0;
                }
            }
        }
        .dcpcw-close {
            color: var(--mo-color-text1);
            cursor: pointer;
            position: fixed;
            right: calc(40px + 2.5vh - 11px);
            top: calc(5vh + 4px);
            z-index: 11;
            .dcpcw-close-icon {
                font-size: 22px;
            }
        }
    }
    .dcpcw-actions {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: 70px;
        position: absolute;
        right: 0;
        width: 80px;
        .main-detail-edit-block-web {
            display: flex;
            flex-direction: column-reverse;
            .main-icon-block-web {
                margin-bottom: 10px;
            }
            .inactive-button-web.detail {
                margin: 0;
            }
        }
    }
    .dcpcw-modal-wrap {
        height: calc(100% - 10vh);
        margin: 5vh calc(80px + 5vh);
        position: relative;
        width: calc(100% - 160px - 10vh);
        .dcpcw-header {
            align-items: center;
            background: var(--mo-color-bg1);
            border: 1px solid var(--mo-color-ln);
            display: flex;
            height: 45px;
            justify-content: space-between;
            left: 0;
            overflow: hidden;
            padding: 0 5px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 12;
            .dcpcw-header-left {
                align-items: center;
                background: var(--mo-color-bg1);
                display: flex;
                padding-right: 20px;
                z-index: 1;
                .hcw-album-block {
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    .hcw-album-image {
                        height: 30px;
                        padding: 0 10px 0 5px;
                        width: auto;
                    }
                    .hcw-album-name {
                        font-size: 14px;
                        &:hover {
                            color: var(--mo-color-mo-color);
                        }
                    }
                }
            }
            .dcpcw-title-wrap {
                align-items: center;
                display: flex;
                font-size: 17px;
                height: 100%;
                justify-content: center;
                left: 0px;
                padding: 0px 90px;
                position: absolute;
                top: 0px;
                width: 100%;
                .dcpcw-title {
                    font-size: 17px;
                    max-width: 500px;
                    font-weight: bold;
                }
            }
            .main-detail-edit-block-web {
                background: var(--mo-color-bg1);
                padding-left: 20px;
                z-index: 1;
            }
        }
        .dcpcw-modal-content {
            background: var(--mo-color-bg2);
            border: 1px solid var(--mo-color-ln);
            min-height: calc(100vh - 10vh);
            overflow: hidden;
            padding-top: 45px;
            .help-center-topic-item-web {
                height: calc(100vh - #{$nav-children-height} - 10vh);
                .hctiw-left {
                    height: calc(100vh - #{$nav-children-height} - 10vh);
                    width: calc(100vw - 160px - 10vh - 370px);
                }
                .hctiw-right {
                    height: calc(100vh - #{$nav-children-height} - 10vh);
                }
            }
            .main-detail-block-web {
                height: calc(100vh - #{$nav-children-height} - 10vh);
                .mdb-left {
                    height: calc(100vh - #{$nav-children-height} - 10vh);
                    width: calc(100vw - 160px - 10vh - 370px);
                    .mixed-content-block-web {
                        height: calc(100vh - #{$nav-children-height} - 10vh);
                        .slug-page-content {
                            height: calc(100vh - #{$nav-children-height} - 10vh);
                            min-height: calc(100vh - #{$nav-children-height} - 10vh);
                        }
                    }
                }
                .mdb-right {
                    height: calc(100vh - #{$nav-children-height} - 10vh);
                }
            }
        }
    }
}
