.profile-list-small-block-mobile {
    border: 1px solid transparent;
    display: inline-block;
    margin-bottom: 30px;
    position: relative;
    width: 50%;
    .pflsbm-wrap {
        position: relative;
        .pflsbm-profile-pic {
            display: block;
            padding: 5px;
            position: relative;
            .pflsbm-image-link {
                padding-bottom: 100%;
                position: relative;
                width: 100%;
                .pflsbm-image {
                    position: absolute;
                    top: 0;
                }
            }
        }
        .pflsbm-info {
            padding: 0 10px 0;
            text-align: center;
            width: 100%;
            .pflsbm-chips {
                max-height: 22px;
                margin-bottom: 10px;
                overflow: hidden;
            }
            .pflsbm-country {
                flex-wrap: wrap;
                height: 18px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                .pflsbm-country-flag {
                    flex: 0 0 24px;
                    height: 18px;
                }
                .pflsbm-city-name {
                    height: 18px;
                    overflow: hidden;
                }
            }
        }
        .pflsbm-action {
            padding: 10px;
            text-align: center;
        }
    }
}
