.material-list-medium-block-web {
    display: flex;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    .mlmb-cover {
        width: 200px;
        .mlmb-image {
            width: 100%;
        }
    }
    .mlmb-info {
        flex: 1;
        padding: 0px 5px 0 10px;
        .mlmb-tags {
            .main-chip-list-block {
                height: 21px;
                margin-bottom: 5px;
            }
        }
        .mlmb-price-wrap {
            padding-top: 5px;
        }
    }
    .mlmb-actions {
        display: flex;
        flex-direction: column;
        width: 30px;
    }
}
