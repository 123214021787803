.mixed-content-preview {
    .slug-page-content-wrap {
        color: black;
        .slug-page-content-div {
            height: calc(#{$portfolio-height-vh} - 60px);
            .slug-page-content {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                min-height: auto;
                margin: 0 30px 0 60px;
                .slug-page-content-list {
                    .hpc-selection-helper {
                        flex: 1;
                        position: relative;
                        .selection-helper-page-content-web-left {
                            &:hover {
                                [id^="content-section-"] {
                                    &:after {
                                        content: "";
                                        height: 100%;
                                        left: 0;
                                        outline-offset: -5px;
                                        outline: var(--mo-color-mo-color) 5px solid;
                                        position: absolute;
                                        top: 0;
                                        width: 100%;
                                        z-index: 99;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
