.auth-global-block-web {
    padding: 15px;
    text-align: center;
    .mo-text-input-web-input {
        text-align: initial;
    }
    .auth-text {
        .auth-link {
            color: var(--mo-color-mo-color);
        }
    }
    .section-border {
        width: 100%;
    }
    .success-registration {
        .success-message {
            p {
                white-space: pre-line;
            }
            border-bottom: 1px solid var(--mo-color-mo-color);
        }
    }
    .auth-separation {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
        .auth-separation-line {
            width: 40%;
            hr {
                display: block;
                height: 1px;
                border: 0;
                border-top: 1px solid var(--mo-color-ln);
                margin: 1em 0;
                padding: 0;
            }
        }
    }
    .asp-submit-button {
        margin: auto;
    }
    .asp-button {
        .mo-primary-button {
            margin-top: 0px;
        }
    }
    .asp-password-reset {
        display: block;
        font-size: 12px;
        margin-top: 20px;
    }
    .asp-terms {
        display: flex;
        margin-top: 20px;
        .asp-checkbox {
            padding: 0 10px 0 0;
        }
        a {
            color: var(--mo-color-mo-color);
        }
    }
    .auth-activation-text {
        font-size: 20px;
        padding: 20px;
    }
    .success-message2 {
        padding-bottom: 30px;
    }
}
