:root {
    /* Set the font family of the entire app */
    --ion-font-family: "FuturaPT";
    ion-alert {
        --background: #1f1f24;
    }
    ion-checkbox {
        --checkbox-background: #181818;
        --checkbox-background-checked: var(--mo-color-mo-color);
        --border-color-checked: var(--mo-color-mo-color);
    }
    ion-input {
        --padding-end: 13px;
        --padding-start: 13px;
    }
    ion-radio {
        --color-checked: var(--mo-color-mo-color);
    }
    ion-range {
        --bar-background-active: var(--mo-color-mo-color);
        --knob-background: var(--mo-color-mo-color);
        --knob-size: 14px;
    }
    ion-segment-button {
        --indicator-color: #1f1f24;
        --border-color: black;
    }
    ion-select {
        --padding-end: 13px;
        --padding-start: 13px;
        width: auto;
    }
    ion-textarea {
        --padding-end: 13px;
        --padding-start: 13px;
    }
    ion-toast {
        --color: var(--mo-color-mo-color);
    }
    ion-item {
        --background-hover-opacity: 1;
        --background-hover: var(--mo-color-ln);
        --background: var(--mo-color-bg1);
        --color-hover: var(--mo-color-mo-color);
    }
}
html {
    /* Hide scrollbar IE 11 */
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
    /* Hide scrollbar Firefox */
    scrollbar-width: thin !important;
}
body {
    background-color: var(--mo-color-bg2);
    color: var(--mo-color-text1);
    font-family: $font1;
    font-size: $MainFontSize;
    font-weight: normal;
    margin: 0;
    word-break: break-word;
    ion-app {
        letter-spacing: 0.3px;
        line-height: 1.5;
        user-select: auto !important;
    }
    .mo-hide-ion-content-scrollbar {
        // // Keep order
        // --offset-bottom: auto !important;
        // --overflow: hidden;
        // overflow: auto;
        // -ms-overflow-style: -ms-autohiding-scrollbar !important; /* Hide scrollbar IE 11 */
        &.is-windows {
            scrollbar-color: black rgba(var(--mo-color-text1-rgb), 0.1);
            /* Hide scrollbar Firefox */
            scrollbar-width: thin !important;
            &::-webkit-scrollbar {
                background: black;
                /* Hide scrollbar Safari and Chrome */
                width: 9px;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba(var(--mo-color-text1-rgb), 0.1);
            }
        }
        &.pcw {
            --background: var(--mo-color-text1);
            --color: black;
            height: calc(#{$portfolio-height-vh} - 60px);
        }
    }
    .alert-checkbox-group {
        border-color: var(--mo-color-bg1) !important;
        [aria-checked="true"] {
            &.alert-tappable {
                background: var(--mo-color-bg1);
            }
            .alert-checkbox-icon {
                background: var(--mo-color-mo-color) !important;
                border-color: var(--mo-color-mo-color) !important;
            }
            .alert-checkbox-inner {
                border-color: transparent;
            }
        }
    }
    .alert-radio-group {
        border-color: var(--mo-color-bg1) !important;
        [aria-checked="true"] {
            &.alert-tappable {
                background: var(--mo-color-bg1);
            }
            .alert-radio-inner {
                border-color: var(--mo-color-mo-color) !important;
            }
        }
    }
    .alert-button-group {
        .alert-button {
            border-color: var(--mo-color-bg1) !important;
            &.ion-activated {
                color: var(--mo-color-mo-color);
            }
        }
    }
    a {
        color: inherit;
        text-decoration: none;
        transition: $mo-ease;
        &:hover {
            color: var(--mo-color-mo-color);
        }
    }
    img {
        display: block;
    }
    td {
        img {
            max-width: none;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
    }
    .button-footer-web {
        background: var(--mo-color-bg1);
        border-top: 1px solid var(--mo-color-ln);
        display: flex;
        padding: 10px;
        .button-web,
        .button-wrap-web {
            width: 100%;
        }
    }
    .cursor-zoom-in {
        cursor: zoom-in;
    }
    .doka--root .doka--button-action-confirm {
        background-color: var(--mo-color-mo-color);
    }
    .doka--root {
        --doka-color--alpha: var(--mo-color-mo-color);
        --doka-color--alpha-dim: #1a80ec;
    }
    .list-block-footer {
        margin-top: 10px;
    }
    .mo-error-color {
        color: $error;
    }
    .mo-list-block-name-web {
        font-weight: 600;
        transition: $mo-ease;
        &.small {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &:hover {
            color: var(--mo-color-mo-color);
        }
    }
    .mo-list-feed-name-web {
        color: var(--mo-color-text2);
        font-size: 12px;
        padding-bottom: 5px;
    }
    .mo-clamp-web1 {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .mo-clamp-web5 {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .mo-cursor {
        cursor: pointer;
    }
    .mo-hidden-horizontal-scrollbar {
        /* IE 10+ */
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        /* Firefox */
        scrollbar-width: none;
        &::-webkit-scrollbar {
            /* WebKit */
            height: 0;
            width: 0;
        }
    }
    .mo-hidden-vertical-scrollbar {
        /* Hide scrollbar IE 11 */
        -ms-overflow-style: -ms-autohiding-scrollbar !important;
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        &.is-windows {
            scrollbar-color: black rgba(var(--mo-color-text1-rgb), 0.1);
            /* Hide scrollbar Firefox */
            scrollbar-width: thin !important;
            &::-webkit-scrollbar {
                background: black;
                /* Hide scrollbar Safari and Chrome */
                width: 9px;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba(var(--mo-color-text1-rgb), 0.1);
            }
        }
    }
    .mo-item-list-label {
        margin: 15px 8px 15px 0;
    }
    .navbar-pt {
        padding-top: $nav-height;
    }
    .pre-line {
        white-space: pre-line;
    }
    .pointer {
        cursor: pointer;
    }
    .rtl {
        direction: rtl;
    }
    .ril-toolbar.ril__toolbar {
        background-color: transparent;
        .ril__toolbarItemChild {
            outline: none;
        }
    }
    .tac {
        text-align: center;
    }
    .tar {
        text-align: right;
    }
}
