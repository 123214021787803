.list-page-content-portfolio-panel-web {
    display: flex;
    flex-direction: column;
    flex: 1;
    .lpcppw-content-list {
        flex: 1;
        padding: 15px;
        .lpcppw-expand {
            color: var(--mo-color-text2);
            cursor: pointer;
        }
    }
    .lpcppw-footer {
        border-top: 1px solid var(--mo-color-ln);
        padding: 10px;
    }
}
