.team-list-medium-block-web {
    margin-bottom: 30px;
    padding: 0 10px;
    position: relative;
    width: 100%;
    .tlmbw-wrap {
        display: flex;
        position: relative;
        .tlmbw-profile-pic {
            width: 80px;
            .tlmbw-image-link {
                display: block;
                padding-bottom: 100%;
                position: relative;
                width: 100%;
                .tlmbw-image {
                    height: 100%;
                    position: absolute;
                }
            }
            .tlmbw-image-zarma-name {
                align-items: center;
                background: var(--mo-color-bg1);
                border-radius: 5%;
                color: var(--mo-color-text1);
                display: flex;
                font-weight: bold;
                height: 80px;
                justify-content: center;
                text-transform: uppercase;
            }
        }
        .tlmbw-info {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            padding: 0 10px 0 13px;
            .tlmbw-chips {
                margin-bottom: 10px;
                max-height: 22px;
                overflow: hidden;
            }
            .tlmbw-country {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                height: 18px;
                overflow: hidden;
            }
        }
        .tlmbw-action {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
}
