.project-player-button-web {
    align-items: center;
    background: rgba(var(--mo-color-bg2-rgb), 0.4);
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    .main-icon-block-web {
        .main-icon {
            font-size: 100px;
        }
    }
}
