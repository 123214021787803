.admin-menu {
    position: relative;
    .am-profile-img {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        position: sticky;
        .profile-short-info-web {
            margin-bottom: 0;
        }
    }
}
