.feed-block-detail-web {
    .fbdw-content {
        display: flex;
        position: relative;
        .fbdw-left {
            border-right: 1px solid var(--mo-color-ln);
            flex-shrink: 0;
            height: $nav-height-vh2;
            padding: 10px;
            width: 240px;
        }
        .fbdw-right {
            flex: 1;
            .fbdw-right-wrap {
                display: flex;
                flex-wrap: wrap;
                height: $nav-height-vh2;
                padding: 20px;
                .block-template-list-block-web,
                .event-list-small-block-web,
                .material-list-small-block-web,
                .offer-list-small-block-web,
                .page-template-list-block-web,
                .product-list-small-block-web,
                .project-list-small-block-web,
                .service-list-small-block-web,
                .square-block {
                    @media (max-width: 2680px) {
                        width: calc(100% / 8);
                    }
                    @media (max-width: 2380px) {
                        width: calc(100% / 7);
                    }
                    @media (max-width: 2080px) {
                        width: calc(100% / 6);
                    }
                    @media (max-width: 1780px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 1480px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1180px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 880px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 580px) {
                        width: 100%;
                    }
                }
                .portfolio-template-list-block-web {
                    width: 25%;
                }
                .portfolio-list-block-web,
                .press-list-small-block-web {
                    @media (max-width: 2780px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 2280px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1780px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 1280px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 780px) {
                        width: 100%;
                    }
                }
                .profile-list-small-block-web,
                .team-list-small-block-web {
                    @media (max-width: 2780px) {
                        width: calc(100% / 10);
                    }
                    @media (max-width: 2530px) {
                        width: calc(100% / 9);
                    }
                    @media (max-width: 2280px) {
                        width: calc(100% / 8);
                    }
                    @media (max-width: 2030px) {
                        width: calc(100% / 7);
                    }
                    @media (max-width: 1780px) {
                        width: calc(100% / 6);
                    }
                    @media (max-width: 1530px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 1280px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1030px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 780px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 530px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
