.profile-short-info {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    .psi-alfa1 {
        height: 30px;
        width: 30px;
        .psi-bravo1 {
            height: 100%;
            width: 100%;
        }
    }
    .psi-alfa2 {
        margin-left: 15px;
        .psi-bravo1 {
            margin-bottom: 2px;
        }
        .psi-bravo2 {
            .psi-charlie1 {
                margin-right: 5px;
            }
        }
    }
}
