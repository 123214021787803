.product-detail-block-web {
    .pdbw-label {
        margin-top: 5px;
    }
    .pdbw-price-wrap {
        border-bottom: 1px solid var(--mo-color-ln);
        padding: 5px 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
    .pdbw-price {
        color: var(--mo-color-text1);
    }
    .pdbw-dimension,
    .pdbw-weight {
        font-size: 12px;
    }
    .pdbw-section-price {
        align-items: center;
        border-bottom: solid 1px var(--mo-color-ln);
        display: flex;
        .pdbw-variants {
            padding-right: 10px;
            width: 150px;
            .mo-select-input-web {
                margin-bottom: 0;
            }
        }
        .pdbw-qty-wrap {
            padding-right: 10px;
            width: 75px;
            .mo-text-input-web {
                margin-bottom: 0;
            }
        }
        .pdbw-button-wrap {
            flex: 1;
            .button-web {
                padding: 12px;
                width: 100%;
            }
        }
    }
    .pdbw-section-button {
        margin: 20px auto;
        max-width: 70%;
    }
}
