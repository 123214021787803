.inline-form-modal-web {
    .ifmw-wrapper {
        height: 95vh;
        .ifmw-header {
            align-items: center;
            background: var(--mo-color-bg1);
            border-bottom: 1px solid var(--mo-color-ln);
            display: flex;
            font-size: 16px;
            height: $nav-children-height;
            justify-content: center;
            padding: 0;
        }
        .ifmw-content {
            display: flex;
            padding: 0;
            .ifmw-left {
                background: var(--mo-color-bg1);
                display: flex;
                flex-direction: column;
                width: 370px;
                .ifmw-form-children {
                    flex: 1;
                    padding: 15px;
                }
                .ifmw-form-button {
                    background: var(--mo-color-bg1);
                    border-top: 1px solid var(--mo-color-ln);
                    display: flex;
                    padding: 10px;
                }
            }
            .ifmw-right {
                background: var(--mo-color-bg2);
                flex: 1;
                position: relative;
                .ifmw-table-container {
                    height: 100%;
                    table {
                        border-spacing: 0;
                        min-width: 100%;
                        position: relative;
                        tbody {
                            tr:nth-child(even) {
                                background-color: var(--mo-color-bg2);
                                td {
                                    background-color: var(--mo-color-bg2);
                                }
                            }
                            tr:nth-child(odd) {
                                background-color: var(--mo-color-bg1);
                                td {
                                    background-color: var(--mo-color-bg1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
