.profile-detail {
    .pd-image-block {
        .pd-image-block-serie {
            padding: 10px 2px 0;
        }
        .pd-section-button {
            margin: 20px auto 0;
            max-width: 70%;
        }
    }
    .pd-section-button {
        margin: 20px auto;
        max-width: 70%;
    }
    .pd-list {
        padding: 5px 0;
        @media (min-width: 768px) {
            width: 540px;
            margin: auto;
        }
    }
}
.mo-profile-menu-badge {
    position: fixed;
}
.profile-detail-website-iframe {
    background: black;
    .website-modal-iframe {
        border: none;
        height: 100%;
        width: 100%;
    }
}
