.header-children-web {
    height: calc(#{$nav-height} + #{$nav-children-height});
    padding-top: $nav-height;
    .hcw-toolbar {
        background: var(--mo-color-bg1);
        border-bottom: 1px solid var(--mo-color-ln);
        height: $nav-children-height;
    }
    .hcw-header-children-left {
        display: flex;
        position: relative;
        &.right {
            justify-content: flex-end;
        }
        .hcw-profile-pic {
            cursor: pointer;
            height: 30px;
            margin: 0 10px;
            width: 30px;
        }
        .hcw-profile-name {
            cursor: pointer;
            align-items: center;
            display: flex;
            flex: 1;
            padding-right: 10px;
            .hcw-name {
                &:hover {
                    color: var(--mo-color-mo-color);
                }
            }
        }
        .profile-short-info-web {
            margin-bottom: 0;
            padding-left: 10px;
            width: 30%;
            .psiw-image {
                flex-shrink: 0;
            }
            .psiw-name-wrap {
                .psiw-name {
                    @extend .mo-clamp-web1;
                }
            }
            &.right {
                padding-left: 0;
                padding-right: 10px;
            }
        }
    }
    .hcw-title {
        padding: 0 30%;
    }
    .hcw-thumb-wrap {
        display: flex;
        justify-content: space-between;
        .hcw-size-icon {
            &.active {
                .main-icon {
                    color: var(--mo-color-mo-color);
                }
            }
        }
    }
}
.hcw-nav-preview-link-wrap {
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    .hcw-nav-preview-link {
        font-size: 14px;
        line-height: 1;
    }
}
