.menu-forms-web {
    .mfw-icon {
        color: var(--mo-color-mo-color);
        font-size: 24px;
        margin-right: 15px;
        width: 30px;
    }
    .mfw-first-label {
        &:hover {
            color: var(--mo-color-mo-color);
        }
    }
    .mfw-second-label {
        color: var(--mo-color-text2) !important;
    }
}
