.detail-component-preview {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .dc-iframe-tabs {
        align-items: center;
        display: flex;
        padding: 5px;
        .main-icon-block-web .main-icon {
            font-size: 12px;
            padding: 0;
        }
        .dc-iframe-active {
            font-weight: bold;
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }
    .dc-iframe {
        border: 2px solid var(--mo-color-ln);
        flex: 1;
        width: 100%;
    }
}
