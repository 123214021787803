.button-mobile {
    &.bold {
        font-weight: 600;
    }
}
.button-wrap-web {
    align-items: center;
    display: flex;
}
.button-web {
    align-items: center;
    border-radius: 3px;
    border: 1px solid;
    display: inline-flex;
    font-size: 16px;
    justify-content: center;
    line-height: 1;
    min-width: 70px;
    text-align: center;
    transition: $mo-ease;
    &:focus {
        outline: none;
    }
    &.color {
        background: var(--mo-color-mo-color);
        border-color: var(--mo-color-mo-color);
        color: var(--mo-color-black);
        &:hover {
            background: var(--mo-color-black);
            color: var(--mo-color-mo-color) !important;
        }
        &.clear {
            background: transparent;
            border: none;
            color: var(--mo-color-mo-color) !important;
            padding: 7px 12px;
            &:hover {
                opacity: 0.7;
            }
        }
        &.outline {
            background: transparent;
            color: var(--mo-color-mo-color) !important;
            &:hover {
                background: var(--mo-color-mo-color);
                color: var(--mo-color-black) !important;
            }
        }
    }
    &.danger {
        background: $error;
        border-color: $error;
        color: $error !important;
        &:hover {
            background: $error;
            color: var(--mo-color-mo-color) !important;
        }
        &.clear {
            background: transparent;
            border: none;
            padding: 7px 12px;
            &:hover {
                opacity: 0.7;
            }
        }
        &.outline {
            background: transparent;
            color: $error !important;
            &:hover {
                background: $error;
                color: var(--mo-color-text1) !important;
            }
        }
    }
    &.grey {
        background: var(--mo-color-ln);
        border-color: var(--mo-color-ln);
        color: var(--mo-color-black) !important;
        &:hover {
            background: var(--mo-color-black);
            color: var(--mo-color-mo-color) !important;
        }
        &.clear {
            background: transparent;
            border: none;
            padding: 7px 12px;
            &:hover {
                opacity: 0.7;
            }
        }
        &.outline {
            background: transparent;
            color: var(--mo-color-text2) !important;
            &:hover {
                background: var(--mo-color-mo-color);
                color: var(--mo-color-black) !important;
            }
        }
    }
    &.white {
        background: var(--mo-color-text1);
        border-color: var(--mo-color-text1);
        color: var(--mo-color-black) !important;
        &:hover {
            background: var(--mo-color-black);
            color: var(--mo-color-mo-color) !important;
        }
        &.clear {
            background: transparent;
            border: none;
            padding: 7px 12px;
            &:hover {
                opacity: 0.7;
            }
        }
        &.outline {
            background: transparent;
            color: var(--mo-color-text1) !important;
            &:hover {
                background: var(--mo-color-mo-color);
                color: var(--mo-color-black) !important;
            }
        }
    }
    &.regular {
        min-height: 42px;
        padding: 5px 25px;
        &.clear {
            min-height: auto;
        }
    }
    &.small {
        padding: 7px 12px;
    }
    &.x-small {
        font-size: 12px;
        padding: 5px;
    }
    &.full {
        width: 100%;
    }
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
