.image-crop-input {
    .ici-input-div {
        border: 1px solid var(--mo-color-ln);
        width: 350px;
        .ici-image-edit-wrap {
            background: var(--mo-color-bg2);
            margin-bottom: 5px;
            .ici-image {
                height: 202px;
                margin: auto;
                object-fit: contain;
            }
        }
        .ici-image-add-wrap {
            align-items: center;
            background: var(--mo-color-bg2);
            cursor: pointer;
            display: flex;
            justify-content: center;
            .ici-input {
                align-items: center;
                display: flex;
                flex-direction: column;
                height: 200px;
                justify-content: center;
                padding: 20px;
                text-align: center;
                .mo-dropzone-helper-text {
                    position: relative;
                    z-index: 2;
                }
                .m-icon {
                    font-size: 23px;
                    height: 24px;
                    position: relative;
                    z-index: 2;
                }
                &.error {
                    color: red;
                }
            }
        }
        .ici-buttons-wrap {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            width: 100%;
            .ici-dropzone-input {
                background: none;
            }
            .ici-buttons-div {
                padding: 5px;
                width: 50%;
                .ici-button {
                    width: 100%;
                }
            }
            &.clear {
                .ici-buttons-div {
                    width: calc(100% / 3);
                }
            }
        }
    }
    .ici-alt-wrap {
        padding-top: 5px;
        .main-form-label-wrap {
            font-size: 13px;
        }
    }
    .dropzone {
        align-items: center;
        background: var(--mo-color-bg2);
        border: none;
        border-radius: $form-br;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        .dz-message {
            margin: 0;
        }
        .dz-preview .dz-image {
            border-radius: 0px;
        }
    }
}
