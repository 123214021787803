.style-page-content-portfolio-panel-web {
    display: flex;
    flex-direction: column;
    flex: 1;
    .spcppw-device {
        align-items: center;
        border-top: 1px solid var(--mo-color-text1);
        display: flex;
        justify-content: space-around;
        .spcppw-device-div {
            border-bottom: 1px solid var(--mo-color-text1);
            cursor: pointer;
            display: flex;
            font-size: 15px;
            justify-content: center;
            padding: 10px;
            width: 100%;
            .icon {
                display: flex;
                margin: 0 20px;
            }
            &.active {
                border-bottom: 2px solid var(--mo-color-mo-color);
            }
        }
    }
    .spcppw-style-wrap {
        flex: 1;
        padding: 15px;
        position: relative;
        .spcppw-title-wrap {
            margin-bottom: 15px;
            padding: 0 0 10px;
            .spcppw-title-div {
                align-items: center;
                border-bottom: 1px solid var(--mo-color-text1);
                display: flex;
                justify-content: space-between;
                .spcppw-title {
                    flex: 1;
                    font-weight: 600;
                }
                .spcppw-collapse {
                    cursor: pointer;
                    display: flex;
                    font-size: 16px;
                    margin: 0 20px 0 10px;
                    transition: $mo-ease;
                    &:hover {
                        color: var(--mo-color-mo-color);
                    }
                }
            }
        }
        .spcppw-css-class-wrap {
            padding-left: 5px;
        }
    }
}
