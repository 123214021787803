.inline-form-container-mobile {
    .mfcm-form-content {
        .mfcm-form-panels {
            padding: 15px;
            &.menu {
                padding: 15px 0;
            }
        }
    }
    .ifcm-avatar {
        margin-right: 10px;
    }
    .ifcm-name {
        &.image {
            padding-left: 10px;
        }
    }
    .ifcm-active-status {
        color: var(--mo-color-text2) !important;
        &.not-active {
            color: var(--mo-color-mo-color) !important;
        }
        &.image {
            padding-left: 10px;
        }
    }
    .ifcm-thumb {
        padding: 10px;
    }
    .ifcm-message {
        text-align: center;
        padding: 10px;
    }
}
.inline-form-container-modal-content {
    padding: 15px !important;
}
