.profile-home-list-block-web {
    width: 100%;
    .phlbw-title {
        align-items: center;
        border-bottom: 1px solid var(--mo-color-ln);
        display: flex;
        font-size: 30px;
        font-weight: 600;
        justify-content: space-between;
        margin: 10px;
        padding: 10px 0;
        .phlbw-see-all {
            color: var(--mo-color-text2);
            cursor: pointer;
            font-size: 16px;
            margin-left: 30px;
            text-transform: uppercase;
            &:hover {
                color: var(--mo-color-mo-color);
            }
        }
    }
    .phlbw-content-wrap {
        // &.event {
        // 	display: flex;
        // 	.phlbw-content {
        // 		width: 50%;
        // 	}
        // 	.phlbw-map-content {
        // 		height: calc(#{$nav-height-vh2} - 66px);
        // 		width: 50%;
        // 	}
        // }
        .phlbw-content {
            .phlbw-content-flex {
                display: flex;
                flex-wrap: wrap;
                &.no-flex {
                    display: block;
                }
                .block-template-list-block-web,
                .empty-list-small-block-web,
                .event-list-small-block-web,
                .material-list-small-block-web,
                .offer-list-small-block-web,
                .page-template-list-block-web,
                .product-list-small-block-web,
                .project-list-small-block-web,
                .service-list-small-block-web,
                .square-block {
                    @media (max-width: 2530px) {
                        width: calc(100% / 7);
                    }
                    @media (max-width: 2230px) {
                        width: calc(100% / 6);
                    }
                    @media (max-width: 1930px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 1630px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1330px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 1030px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 730px) {
                        width: 100%;
                    }
                }
                .press-list-small-block-web {
                    @media (max-width: 2930px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 2430px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1930px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 1430px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 930px) {
                        width: 100%;
                    }
                }
                .profile-list-small-block-web,
                .team-list-small-block-web {
                    @media (max-width: 2930px) {
                        width: calc(100% / 10);
                    }
                    @media (max-width: 2670px) {
                        width: calc(100% / 9);
                    }
                    @media (max-width: 2430px) {
                        width: calc(100% / 8);
                    }
                    @media (max-width: 2170px) {
                        width: calc(100% / 7);
                    }
                    @media (max-width: 1930px) {
                        width: calc(100% / 6);
                    }
                    @media (max-width: 1670px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 1430px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1170px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 930px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 670px) {
                        width: 100%;
                    }
                }
            }
            &.initial {
                .project-list-child-block-web {
                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8),
                    &:nth-child(9),
                    &:nth-child(10) {
                        display: none;
                    }
                }
                .event-list-map-block-web,
                .event-list-medium-block-web,
                .material-list-medium-block-web,
                .offer-list-medium-block-web,
                .product-list-medium-block-web,
                .profile-list-medium-block-web,
                .project-list-medium-block-web,
                .service-list-medium-block-web,
                .team-list-medium-block-web {
                    &:nth-child(5),
                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8),
                    &:nth-child(9),
                    &:nth-child(10) {
                        display: none;
                    }
                }
                .block-template-list-block-web,
                .empty-list-small-block-web,
                .event-list-small-block-web,
                .material-list-small-block-web,
                .offer-list-small-block-web,
                .page-template-list-block-web,
                .product-list-small-block-web,
                .project-list-small-block-web,
                .service-list-small-block-web,
                .square-block {
                    @media (max-width: 1630px) {
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                    @media (max-width: 1330px) {
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                    @media (max-width: 1030px) {
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                    @media (max-width: 730px) {
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                }
                .press-list-small-block-web {
                    @media (max-width: 2430px) {
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                    @media (max-width: 1930px) {
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                    @media (max-width: 1430px) {
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                    @media (max-width: 930px) {
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                }
                .profile-list-small-block-web,
                .team-list-small-block-web {
                    @media (max-width: 1430px) {
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                    @media (max-width: 1170px) {
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                    @media (max-width: 930px) {
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                    @media (max-width: 670px) {
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            display: none;
                        }
                    }
                }
            }
            .phlbw-show-more {
                align-items: center;
                display: flex;
                justify-content: center;
                padding-bottom: 20px;
            }
        }
    }
}
