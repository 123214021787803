.detail-field-icon {
    align-items: center;
    display: flex;
    &.mo-new-icon-check-circle-solid {
        color: springgreen;
    }
    &.mo-new-icon-times-solid {
        color: $error;
    }
}
.detail-field-image {
    cursor: zoom-in;
    max-height: 200px;
    max-width: 200px;
    &.svg {
        width: 200px;
    }
}
