.detail-view-preview-web {
    &.big,
    &.medium {
        padding: 10px;
    }
    &.small {
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    .adherent-detail-block-web {
        height: calc(100vh - #{$nav-children-height});
        .adbw-left {
            height: calc(100vh - #{$nav-children-height});
        }
        .adbw-right {
            height: calc(100vh - #{$nav-children-height});
        }
    }
    .detail-block-right-web {
        height: $nav-height-vh;
    }
}
