.offer-list-medium-block {
    display: flex;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    .olmbm-cover {
        width: 100px;
        .olmbm-cover-wrap {
            position: relative;
            z-index: 2;
            .olmbm-image-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
                padding-bottom: 100%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .olmbm-image {
                    border-radius: 7px;
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
            }
        }
    }
    .olmbm-info {
        flex: 1;
        padding: 0px 5px 0 10px;
        .olmbm-tags {
            .main-chip-list-block {
                height: 21px;
                margin-bottom: 5px;
            }
        }
        .olmbm-date {
            padding-top: 5px;
            .olmbm-text {
                margin-right: 5px;
            }
        }
        .olmbm-price-wrap {
            padding-top: 10px;
        }
        .olmbm-countries {
            padding-top: 10px;
            .main-chip-list-block {
                display: flex;
            }
        }
    }
    .olmbm-actions {
        display: flex;
        flex-direction: column;
        width: 30px;
    }
}
