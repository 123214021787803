.team-list-big-block {
    margin-bottom: 50px;
    padding: 0 20px;
    width: 100%;
    position: relative;
    .tlbb-wrap {
        position: relative;
        .tlbb-profile-pic {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 5px;
            position: relative;
            .tlbb-button {
                display: flex;
                opacity: 0;
                position: absolute;
                top: 30px;
                z-index: 3;
            }
            .tlbb-image-link {
                display: block;
                padding-bottom: 100%;
                position: relative;
                width: 100%;
                .tlbb-image {
                    height: 100%;
                    position: absolute;
                }
            }
        }
        .tlbb-info {
            padding: 0 10px 0;
            width: 100%;
            .tlbb-chips {
                margin-bottom: 10px;
                max-height: 22px;
                overflow: hidden;
            }
            .tlbb-country {
                flex-wrap: wrap;
                height: 18px;
                overflow: hidden;
                display: flex;
            }
        }
        .tlbb-action {
            text-align: right;
            padding: 10px;
        }
        .tlbb-description {
            padding: 10px 0;
        }
    }
}
