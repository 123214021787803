.iframe-content-block {
    display: block;
    margin: auto;
    max-width: 632px;
    text-align: center;
    padding: 30px;
    .icb-name {
        font-weight: 600;
        margin-bottom: 15px;
    }
    .icb-description {
        margin-bottom: 30px;
    }
    .icb-date {
        margin-bottom: 10px;
    }
    .icb-img {
        max-width: 100%;
        margin-bottom: 15px;
    }
}
