.agenda-du-quartier-settings {
    padding: 40px;
    .adqs-from-wrap {
        padding-bottom: 15px;
    }
    .adqs-message-wrap-title {
        font-size: 20px;
        padding-bottom: 20px;
        font-weight: bold;
        &.second {
            padding-top: 20px;
        }
    }
}
