.empty-list-small-block-web {
    display: inline-block;
    overflow: hidden;
    padding: 7px 7px 20px;
    position: relative;
    width: 300px;
    .emlsbw-cover {
        position: relative;
        .emlsbw-cover-wrap {
            display: block;
            position: relative;
            z-index: 2;
            .emlsbw-image-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
                padding-bottom: 100%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .emlsbw-image {
                    border-radius: 14px;
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
            }
        }
    }
    .emlsbw-info {
        padding-bottom: 15px;
        .emlsbw-actions {
            margin: 10px 0 0;
            height: 89px;
        }
    }
}
