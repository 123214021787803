.selection-helper-page-content-web-left {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: -60px;
    position: absolute;
    top: 0;
    width: 60px;
    z-index: 1;
    .shpcwl-line {
        background: var(--mo-color-ln);
        height: 100%;
        left: 29px;
        position: absolute;
        top: 0;
        width: 2px;
    }
    .shpcwl-line-actions {
        position: sticky;
        top: 0;
        z-index: 1;
        .shpcwl-line-icon-link {
            align-items: center;
            background: var(--mo-color-ln);
            border-radius: 50%;
            border: 1px solid var(--mo-color-ln);
            color: var(--mo-color-text1);
            display: flex;
            height: 30px;
            justify-content: center;
            margin-bottom: 5px;
            transition: $mo-ease;
            width: 30px;
            .shpcwl-line-icon {
                align-items: center;
                display: flex;
                justify-content: center;
            }
            &:hover {
                background: var(--mo-color-mo-color);
                color: black;
            }
            &:focus,
            &:hover {
                outline: none;
            }
        }
    }
    &.active {
        .shpcwl-line {
            background: var(--mo-color-mo-color);
        }
        .shpcwl-line-actions {
            .shpcwl-line-icon-link {
                background: var(--mo-color-mo-color);
                border: 1px solid var(--mo-color-mo-color);
                color: black;
                &:hover {
                    background: black;
                    color: var(--mo-color-text1);
                }
            }
        }
    }
}
.selection-helper-page-content-web-right {
    display: flex;
    height: 100%;
    right: -30px;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: 1;
}
.selection-helper-page-content-web-popover {
    background: var(--mo-color-bg1);
    border-radius: 3px;
    border: 1px solid var(--mo-color-ln);
    box-shadow: 0 0 4px 0 rgba(var(--mo-color-bg2-rgb), 0.18);
    color: var(--mo-color-text1);
    overflow: hidden;
    .shpcwp-item {
        &.multi-button {
            align-items: center;
            border-bottom: 1px solid var(--mo-color-ln);
            display: flex;
            .shpcwp-icon-wrap {
                padding-left: 10px;
            }
            .shpcwp-multi-button-wrap {
                display: flex;
                flex-direction: column;
                .shpcwp-item-button {
                    padding-left: 0;
                }
                .shpcwp-item-button:last-child {
                    border-bottom: none;
                }
            }
        }
        &.bt2 {
            border-top: 2px solid var(--mo-color-ln);
        }
        &.bt3 {
            border-top: 3px solid var(--mo-color-ln);
        }
        .shpcwp-item-button {
            background: var(--mo-color-bg1);
            border-bottom: 1px solid var(--mo-color-ln);
            padding: 7px 10px;
            text-align: left;
            transition: $mo-ease;
            width: 100%;
            .shpcwp-item-button-text {
                color: var(--mo-color-text1);
                font-size: 14px;
            }
            &:focus,
            &:hover {
                outline: none;
            }
            &:hover {
                background: var(--mo-color-ln);
            }
        }
        i {
            color: var(--mo-color-text1);
            display: inline-block;
            margin-right: 10px;
            text-align: center;
            width: 15px;
        }
    }
    .shpcwp-item-layout-input {
        border-bottom: 1px solid var(--mo-color-ln);
        display: flex;
        &.column {
            flex-direction: column;
            .shpcwp-item-button-wrap {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-left: 27px;
            }
        }
        &.bt2 {
            border-top: 2px solid var(--mo-color-ln);
        }
        .shpcwp-item-button {
            align-items: center;
            background: var(--mo-color-bg1);
            display: flex;
            flex: 1;
            padding: 7px 10px;
            text-align: left;
            transition: $mo-ease;
            i {
                color: var(--mo-color-text1);
                margin-right: 10px;
                text-align: center;
                width: 15px;
            }
            .shpcwp-item-button-text {
                color: var(--mo-color-text1);
                display: block;
                font-size: 14px;
                white-space: pre-line;
            }
            &:focus,
            &:hover {
                outline: none;
            }
            &:hover {
                background: var(--mo-color-ln);
            }
            &:disabled {
                color: black;
                pointer-events: none;
            }
        }
        .shpcwp-item-button-wrap {
            .shpcwp-item-button-wrap2 {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.dynamic {
                display: flex;
            }
        }
        .shpcwp-item-button1,
        .shpcwp-item-button2 {
            background: var(--mo-color-bg1);
            padding: 7px 10px;
            text-align: left;
            transition: $mo-ease;
            i {
                color: var(--mo-color-text1);
            }
            .shpcwp-item-button-text {
                font-size: 14px;
            }
            &:focus,
            &:hover {
                outline: none;
            }
            &:hover {
                background: var(--mo-color-ln);
            }
            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}
