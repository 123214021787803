.event-image-detail-web-block {
    position: relative;
    .eidb-cover-image {
        width: 100%;
    }
    .eidb-event-info {
        display: block;
        padding: 30px 0;
        .eidb-name {
            padding: 0 10px 20px;
        }
    }
}
