.profile-list-small-block-web {
    border: 1px solid transparent;
    display: inline-block;
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
    width: 220px;
    .pflsbw-wrap {
        .pflsbw-image-wrap {
            position: relative;
            .ratio-zarming-helper {
                &:after {
                    background: rgba(var(--mo-color-bg2-rgb), 0.7);
                    border-radius: 5%;
                    content: "";
                    cursor: pointer;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    transition: $mo-ease;
                    width: 100%;
                    z-index: 1;
                }
            }
            .pflsbw-action {
                align-items: center;
                display: flex;
                height: 74px;
                justify-content: space-around;
                opacity: 0;
                position: absolute;
                top: calc(50% - 37px);
                transition: $mo-ease;
                width: 100%;
                z-index: 2;
                .pflsbw-actions-link {
                    position: relative;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
                .main-icon-block-web {
                    z-index: 1;
                }
            }
            &.is-open,
            &:hover {
                .ratio-zarming-helper {
                    &:after {
                        opacity: 1;
                    }
                }
                .pflsbw-action {
                    opacity: 1;
                }
            }
        }
        .pflsbw-info {
            padding: 10px 10px 0;
            text-align: center;
            width: 100%;
            .pflsbw-chips {
                max-height: 22px;
                margin-bottom: 10px;
                overflow: hidden;
            }
            .pflsbw-country {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                height: 21px;
                justify-content: center;
                overflow: hidden;
                .pflsbw-country-flag {
                    flex: 0 0 24px;
                    height: 18px;
                }
                .pflsbw-city-name {
                    height: 18px;
                    overflow: hidden;
                }
            }
        }
    }
}
