.text-area-input {
    background: var(--mo-color-bg2);
    border-radius: $form-br;
    border: 1px solid var(--mo-color-ln);
    min-height: 39px;
    padding: 9px 13px;
    resize: none;
    width: 100%;
    &:focus {
        box-shadow: 0 0 0 2px var(--mo-color-black);
        outline: 1px solid var(--mo-color-black);
    }
}
.tiw-loader {
    align-items: center;
    display: flex;
    .tiw-loader-text {
        font-size: 13px;
        margin-right: 15px;
        color: var(--mo-color-text2);
    }
    .tiw-loader-spinner {
        height: 10px;
        width: 10px;
    }
}
.list-ios,
.list-md {
    background: transparent;
    border-radius: $form-br;
}
.tiw-label {
    text-overflow: initial;
    white-space: initial;
}
