.date-input-web {
    margin-bottom: $form-mb;
    &.error {
        margin-bottom: 0;
    }
    &.no-mb {
        margin-bottom: 0;
    }
    .diw-input {
        background: var(--mo-color-bg2);
        border-radius: $form-br;
        border: none;
        font-family: $font1;
        padding: 9px 13px;
        width: 100%;
        &:focus {
            outline: none;
        }
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker-popper {
        z-index: 3;
    }
}
