.image-crop-input-mobile {
    margin-bottom: $form-mb;
    .main-form-label {
        margin-bottom: 7px;
    }
    .icim-dropzone-input {
        align-items: center;
        background: var(--mo-color-bg2);
        display: flex;
        justify-content: center;
        .icim-input {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 50vh;
            justify-content: center;
            padding: 20px;
            text-align: center;
            .icim-dropzone-helper-text {
                position: relative;
                z-index: 2;
            }
            .m-icon {
                font-size: 23px;
                height: 24px;
                position: relative;
                z-index: 2;
            }
        }
    }
    .dropzone {
        align-items: center;
        background: var(--mo-color-bg2);
        border: none;
        border-radius: $form-br;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        .dz-message {
            margin: 0;
        }
        .dz-preview .dz-image {
            border-radius: 0px;
        }
    }
    .icim-image-wrap {
        padding-bottom: 20px;
        .icim-image {
            margin: auto;
            object-fit: cover;
        }
    }
    .icim-buttons {
        display: flex;
        width: 100%;
        .icim-dropzone-input {
            background: none;
        }
        .icim-buttons-div {
            width: 50%;
            .icim-button {
                width: 100%;
            }
        }
        &.clear {
            .icim-buttons-div {
                width: calc(100% / 3);
            }
        }
    }
}
.mo-image-crop-input-web {
    margin-bottom: $form-mb;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
        .edc-dropzone-input {
            pointer-events: none;
        }
    }
    .main-form-label {
        margin-bottom: 7px;
    }
    .eici-dropzone-input {
        align-items: center;
        background: var(--mo-color-bg2);
        cursor: pointer;
        display: flex;
        justify-content: center;
        .eici-input {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 200px;
            justify-content: center;
            padding: 20px;
            text-align: center;
            .mo-dropzone-helper-text {
                position: relative;
                z-index: 2;
            }
            .m-icon {
                font-size: 23px;
                height: 24px;
                position: relative;
                z-index: 2;
            }
            &.error {
                color: red;
            }
        }
    }
    .dropzone {
        align-items: center;
        background: var(--mo-color-bg2);
        border: none;
        border-radius: $form-br;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        .dz-message {
            margin: 0;
        }
        .dz-preview .dz-image {
            border-radius: 0px;
        }
    }
    .eici-image-wrap {
        background: var(--mo-color-bg2);
        margin-bottom: 5px;
        .eici-image {
            margin: auto;
            object-fit: cover;
        }
    }
    .eici-buttons {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        .eici-dropzone-input {
            background: none;
        }
        .eici-buttons-div {
            padding: 5px;
            width: 50%;
            .eici-button {
                width: 100%;
            }
        }
        &.clear {
            .eici-buttons-div {
                width: calc(100% / 3);
            }
        }
    }
    .eici-alt-wrap {
        padding-top: 5px;
        .main-form-label-wrap {
            font-size: 13px;
        }
    }
}
