.mixed-content-block {
    .mcb-cover {
        width: 100%;
    }
    .mcb-content-wrap {
        padding-bottom: 30px;
        .mcb-image {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
