.sales-order-detail-block-web {
    display: flex;
    height: $nav-height-vh2;
    position: relative;
    .sodb-left {
        background-color: var(--mo-color-bg2);
        flex: 1;
        height: $nav-height-vh2;
        padding: 10px;
        position: relative;
        .sodb-title-wrap {
            border-bottom: 5px solid var(--mo-color-ln);
            border-top: 5px solid var(--mo-color-ln);
            .sodb-title-name {
                padding: 10px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        .sodb-text-wrap {
            border-bottom: 1px solid var(--mo-color-ln);
            display: flex;
            .sodb-text-name {
                padding: 10px;
                width: 200px;
                &.small {
                    color: var(--mo-color-text2);
                    font-size: 12px;
                }
            }
            .sodb-text-value {
                flex: 1;
                padding: 10px;
                white-space: pre-line;
                &.flex-align-center {
                    align-items: center;
                    display: flex;
                }
                &.small {
                    color: var(--mo-color-text2);
                    font-size: 12px;
                }
                .main-country-flag-web .mcfw-country-wrap .mcfw-name {
                    color: var(--mo-color-text1);
                }
            }
        }
        .mo-new-icon-check-circle-solid {
            color: springgreen;
        }
        .mo-new-icon-times-solid {
            color: red;
        }
        .sodb-products {
            padding: 0 10px 10px 10px;
            .sodb-product-wrap {
                align-items: center;
                border-bottom: 1px solid var(--mo-color-ln);
                display: flex;
                padding: 10px 0;
                .sodb-product-image {
                    padding-right: 10px;
                    width: 50px;
                }
                .sodb-product-name {
                    flex: 1;
                    .sodb-product-variant {
                        font-size: 15px;
                    }
                }
                .sodb-amount {
                    text-align: right;
                    width: 150px;
                    .sodb-amount-bold {
                        font-weight: bold;
                    }
                    &.flex1 {
                        flex: 1;
                    }
                }
                &.noline {
                    border-bottom: none;
                    padding: 10px 0 0;
                }
            }
        }
    }
    .sodb-right {
        border-left: 1px solid var(--mo-color-ln);
        flex-shrink: 0;
        padding: 0 12px;
        position: relative;
        width: 370px;
        .sodb-sales-status-wrap {
            align-items: center;
            display: flex;
            .sodb-icon {
                align-items: center;
                display: flex;
                justify-content: center;
                &.mo-new-icon-check-circle-solid {
                    color: springgreen;
                }
                &.mo-new-icon-times-solid {
                    color: $error;
                }
            }
            .sodb-sales-status {
                padding-left: 10px;
            }
        }
    }
    &.edit {
        height: $nav-height-vh;
        .sodb-left {
            height: $nav-height-vh;
        }
        .sodb-right {
            height: $nav-height-vh;
        }
    }
}
