.edit-block-site {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    &.hidden {
        display: none;
    }
    .ebs-link {
        cursor: pointer;
        height: 100%;
        width: 100%;
        &:after {
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 50;
        }
    }
    .ebs-actions {
        align-items: flex-start;
        display: flex;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 51;
        .ebs-button {
            background: var(--mo-color-mo-color);
            color: black;
            margin: 7px 7px 0 0;
            padding: 5px;
            &:focus,
            &:hover {
                outline: none;
            }
        }
    }
    &.is-open,
    &:hover {
        .ebs-link {
            &:after {
                outline-offset: -5px;
                outline: var(--mo-color-mo-color) 5px solid;
            }
        }
        .ebs-actions {
            opacity: 1;
        }
    }
}
