.adherent-detail-block-web {
    display: flex;
    height: $nav-height-vh2;
    position: relative;
    .adbw-left {
        background-color: var(--mo-color-bg2);
        flex: 1;
        height: $nav-height-vh2;
        padding: 10px;
        position: relative;
        .adbw-detail-tabs {
            display: flex;
            height: 31px;
            position: absolute;
            top: 0;
            width: calc(100% - 20px);
            .adbw-detail-tabs-item {
                cursor: pointer;
                align-items: center;
                background-color: var(--mo-color-bg1);
                display: flex;
                padding: 5px 10px;
                justify-content: center;
                width: 100%;
                transition: $mo-ease;
                .adbw-detail-tabs-item-name {
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 14px;
                }
                &.active,
                &:hover {
                    .adbw-detail-tabs-item-name {
                        color: var(--mo-color-mo-color);
                        font-weight: bold;
                    }
                }
            }
        }
        .adbw-left-content {
            padding-top: 41px;
        }
        .adbw-title-wrap {
            border-bottom: 5px solid var(--mo-color-ln);
            border-top: 5px solid var(--mo-color-ln);
            .adbw-title-name {
                padding: 10px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        .adbw-text-wrap {
            border-bottom: 1px solid var(--mo-color-ln);
            display: flex;
            .adbw-text-name {
                padding: 10px;
                width: 200px;
                &.small {
                    color: var(--mo-color-text2);
                    font-size: 12px;
                }
            }
            .adbw-text-value {
                flex: 1;
                padding: 10px;
                white-space: pre-line;
                &.flex-align-center {
                    align-items: center;
                    display: flex;
                }
                &.small {
                    color: var(--mo-color-text2);
                    font-size: 12px;
                }
                .main-country-flag-web .mcfw-country-wrap .mcfw-name {
                    color: var(--mo-color-text1);
                }
            }
        }
        .mo-new-icon-check-circle-solid {
            color: springgreen;
        }
        .mo-new-icon-times-solid {
            color: red;
        }
    }
    .adbw-right {
        border-left: 1px solid var(--mo-color-ln);
        flex-shrink: 0;
        padding: 0 12px;
        position: relative;
        width: 370px;
    }
    &.edit {
        height: $nav-height-vh;
        .adbw-left {
            height: $nav-height-vh;
        }
        .adbw-right {
            height: $nav-height-vh;
        }
    }
}
