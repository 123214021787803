.template-block-normal-mixed-media-content-web {
    border-bottom: 1px solid var(--mo-color-ln);
    color: var(--mo-color-text1);
    display: flex;
    &:hover {
        background: var(--mo-color-bg1);
    }
    &.active {
        outline: 2px solid var(--mo-color-mo-color);
        outline-offset: -2px;
    }
    &.detail {
        cursor: pointer;
        padding-top: 10px;
        .tbnmmcw-info {
            flex-direction: column;
            justify-content: center;
            padding-left: 0px;
        }
        &:hover {
            background: transparent;
        }
    }
    .tbnmmcw-info {
        display: flex;
        padding-left: 15px;
        flex: 1;
        .tbnmmcw-info-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .tbnmmcw-name-wrap {
            align-items: center;
            color: var(--mo-color-text1) !important;
            display: flex;
            flex-wrap: wrap;
            &:hover {
                color: var(--mo-color-text1) !important;
            }
            .tbnmmcw-icon {
                color: var(--mo-color-text2);
                display: flex;
                font-size: 14px;
                margin-right: 10px;
            }
            .tbnmmcw-content-source {
                color: var(--mo-color-text2);
                font-size: 13px;
                margin-left: 10px;
            }
            .tbnmmcw-link {
                color: var(--mo-color-text2);
                font-size: 12px;
                margin-left: 10px;
            }
        }
        .tbnmmcw-auth {
            color: #8fb4c1;
            font-size: 13px;
            font-weight: bold;
        }
        .tbnmmcw-image-wrap {
            flex: 1;
            display: flex;
            margin-left: 10px;
            justify-content: flex-end;
        }
        .tbnmmcw-text {
            color: var(--mo-color-text2);
            font-size: 12px;
            white-space: normal;
        }
        .tbnmmcw-content-inactive {
            color: red;
            font-size: 12px;
        }
    }
    .tbnmmcw-image-wrap {
        margin-left: 10px;
    }
    .tbnmmcw-actions-wrap {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .tbnmmcw-actions {
            align-items: center;
            color: var(--mo-color-text1);
            display: flex;
            flex: 1;
            justify-content: flex-end;
            padding-left: 15px;
        }
    }
}
