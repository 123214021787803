.icon-block-mobile {
    --ion-color-base: transparent !important;
    display: inline-block;
    height: auto;
    transition: $mo-ease;
    .ibm-icon {
        color: var(--mo-color-text1);
        display: inline-block;
        font-size: 17px;
        &.mo-new-icon-star-solid,
        &.mo-new-icon-clock-solid {
            color: var(--mo-color-mo-color);
        }
    }
}
.main-icon-block-web {
    background: transparent !important;
    border-radius: 5px;
    display: inline-block;
    height: auto;
    padding: 0 6px;
    transition: $mo-ease;
    &:focus {
        outline: none;
    }
    &:hover {
        background: var(--mo-color-bg1) !important;
        .main-icon {
            color: var(--mo-color-mo-color) !important;
        }
    }
    &:disabled {
        opacity: 0.7;
        pointer-events: none;
    }
    .main-icon {
        align-items: center;
        color: var(--mo-color-text1);
        display: flex;
        font-size: 17px;
        justify-content: center;
        padding: 6px 0px;
        &.color {
            color: var(--mo-color-mo-color);
        }
    }
}
