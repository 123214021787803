.list-page-portfolio-panel-web {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    .lpppw-page-list {
        flex: 1;
        .lpppw-pages-wrap {
            border-bottom: 3px solid var(--mo-color-ln);
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
        .lpppw-divider {
            --background: var(--mo-color-bg1);
            --padding-bottom: 10px;
            --padding-top: 10px;
            border-bottom: 1px solid var(--mo-color-ln);
            text-transform: uppercase;
            letter-spacing: 3px;
        }
        .lpppw-page-name {
            white-space: initial;
        }
        .lpppw-page-home {
            color: var(--mo-color-mo-color);
            font-size: 12px;
        }
        .lpppw-page-status {
            color: red !important;
            font-size: 12px;
        }
        .lpppw-page-private {
            color: #8fb4c1 !important;
            font-size: 12px;
            font-weight: bold;
        }
    }
    .lpppw-footer {
        border-top: 1px solid var(--mo-color-ln);
        display: flex;
        padding: 10px;
        .lpppw-button-margin {
            margin: 0 5px;
        }
    }
}
