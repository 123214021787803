.mo-color-input-web {
    .ciw-wrap-disabled {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-left: 5px;
        .ciw-inherit-disabled-wrap {
            background: rgb(255, 255, 255);
            border-radius: 1px;
            box-shadow: rgba(var(--mo-color-bg2-rgb), 0.1) 0px 0px 0px 1px;
            padding: 5px;
            .ciw-inherit-disabled {
                border-radius: 2px;
                height: 14px;
                width: 36px;
            }
        }
        .ciw-inherit {
            align-items: center;
            display: flex;
            flex-shrink: 0;
            font-size: 13px;
            margin-left: 20px;
            .ciw-inherit-text {
                margin-left: 5px;
            }
        }
    }
    .ciw-div {
        .ciw-wrap {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 0 0 5px 5px;
            position: relative;
            .ciw-picker {
                color: black; // keep, for 3rd party component
                left: 60px;
                position: absolute;
                top: -42px;
                z-index: 99;
                .ciw-colorbox {
                    bottom: 0px;
                    left: 0px;
                    position: fixed;
                    right: 0px;
                    top: 0px;
                }
            }
            .ciw-inherit {
                align-items: center;
                display: flex;
                flex-shrink: 0;
                font-size: 13px;
                margin-left: 20px;
                .ciw-inherit-text {
                    margin-left: 5px;
                }
            }
            .gpw {
                color: black; // keep, for 3rd party component
                padding: 0 !important;
                position: relative;
                .trigger {
                    background: rgb(255, 255, 255);
                    border-radius: 2px;
                    box-shadow: rgba(var(--mo-color-bg2-rgb), 0.1) 0 0 0 1px;
                    cursor: pointer;
                    display: block !important;
                    padding: 5px;
                    .inner {
                        border-radius: 2px;
                        height: 14px;
                        width: 36px;
                    }
                }
                .popover {
                    background: var(--mo-color-text1);
                    border-radius: 4px;
                    box-shadow:
                        rgba(var(--mo-color-bg2-rgb), 0.15) 0 0 0 1px,
                        rgba(var(--mo-color-bg2-rgb), 0.15) 0 8px 16px;
                    left: 60px;
                    margin-top: 6px;
                    padding: 12px;
                    position: absolute;
                    top: -42px;
                    z-index: 99 !important;
                    .angle-holder {
                        align-items: center;
                        border-top: 1px solid rgb(238, 238, 238);
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-around;
                        margin: 0 -10px;
                        padding: 10px 0 0 10px;
                        position: relative;
                    }
                    .angle-inputs {
                        align-items: center;
                        background: #f2f2f2;
                        border-radius: 4px;
                        display: flex;
                        flex: 1;
                        justify-content: space-around;
                        margin: 0 20px;
                        input {
                            background: inherit;
                            border: none;
                            color: #0c0c09;
                            text-align: center;
                            width: 48px;
                        }
                        span {
                            cursor: pointer;
                            padding: 5px;
                            user-select: none;
                        }
                    }
                }
                .overlay {
                    bottom: 0;
                    left: 0;
                    position: fixed;
                    right: 0;
                    top: 0;
                }
            }
        }
        .ciw-hex {
            font-size: 14px;
            padding-left: 5px;
        }
    }
}
