.project-content-block {
    position: relative;
    width: 100%;
    &.medium-type-1,
    &.medium-type-3,
    &.medium-type-4 {
        background: black;
    }
    .rd-image-wrap {
        background: black;
        padding-bottom: 10px;
        width: 100%;
        .rd-image {
            object-fit: contain;
            object-position: center;
            transition: $mo-ease;
            width: 100%;
            &.detail {
                visibility: hidden;
            }
        }
        .pcb-link-url {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 10px 10px 0;
            .pcb-platform-wrap {
                align-items: center;
                display: flex;
                flex: 1;
                justify-content: flex-start;
                .pcb-platform {
                    margin-left: 5px;
                    height: 15px;
                }
            }
            .pcb-play-icon {
                font-size: 24px;
                height: auto;
            }
        }
    }
    .pcb-artwork {
        display: flex;
        justify-content: center;
        max-width: 100%;
        padding: 0;
        text-align: center;
        &.detail {
            visibility: hidden;
        }
        .pcb-bravo1 {
            position: relative;
            text-align: center;
            width: 100%;
            width: auto;
            z-index: 2;
            .pcb-charlie1 {
                display: block;
                object-fit: contain;
                object-position: center;
                width: 100%;
            }
            .pcb-charlie2 {
                align-items: center;
                background-color: rgba(var(--mo-color-bg2-rgb), 0.5);
                bottom: 0;
                display: flex;
                justify-content: center;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition: $mo-ease;
                .pcb-delta1 {
                    height: 34px;
                }
                .icon {
                    color: var(--mo-color-mo-color);
                    font-size: 34px;
                    transition: $mo-ease;
                }
            }
        }
    }
    .pcb-play-button-wrap {
        margin: auto;
        max-width: 70%;
        padding: 10px;
    }
    .pcb-alfa2 {
        display: flex;
        justify-content: center;
        margin: 0;
        min-height: 100%;
        padding: 0 20px 10px;
        position: relative;
        width: 100%;
        z-index: 2;
        .pcb-cover-credit {
            color: black;
            .pcb-delta1 {
                margin-right: 10px;
                font-style: italic;
            }
        }
    }
    .pcb-related-images {
        background: var(--mo-color-text1);
        min-height: 200px;
        padding: 30px;
        &.off {
            min-height: 0;
            padding: 0;
        }
        .pcb-text {
            display: block;
            padding: 33px 10px 50px;
        }
    }
}
