.paob-step1 {
    .paob-registrations {
        padding: 10px 20px;
        table {
            width: 100%;
            td, th, tr {
                border: 1px solid var(--mo-color-ln);
            }
            .paob-sub-header {
                width: 210px;
                .paob-sub-row-header {
                    padding: 5px;
                }
                .paob-sub-header-name {
                    font-size: 14px;
                    text-transform: uppercase;
                }
                .paob-sub-header-description {
                    font-size: 14px;
                    color: var(--mo-color-text2);
                }
            }
            .paob-sub-row {
                .paob-sub-row-div {
                    padding: 5px;
                    .paob-sub-row-description {
                        color: var(--mo-color-text2);
                        font-size: 14px;
                    }
                }
                .paob-sub-row-div-options {
                    padding: 5px;
                    width: 200px;
                    &.center {
                        align-items: center;
                        display: flex;
                        justify-content: center;
                    }
                    .paob-sub-row-name {
                        text-align: center;
                    }
                }
            }
        }
        .paob-sub-wrap {
            display: flex;
            padding: 20px 0;
            .paob-sub-name {
                flex: 1;
                padding-left: 30px;
            }
            .paob-sub-price {
                width: 100px;
            }
        }
    }
}
