.text-input {
    background: var(--mo-color-bg2);
    border-radius: $form-br;
    border: 1px solid var(--mo-color-ln);
    min-height: 39px;
    padding: 0 13px;
    width: 100%;
    &:focus {
        box-shadow: 0 0 0 2px var(--mo-color-black);
        outline: 1px solid var(--mo-color-black);
    }
}
.input-wrap {
    margin-bottom: 20px;
    .input-label-wrap {
        margin-bottom: 10px;
    }
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}
