.email-form {
    .ef-container {
        padding: 20px;
    }
    .ef-emails {
        padding-bottom: 10px;
    }
    .ef-subject {
        border-bottom: 1px solid var(-mo-color-ln);
        padding-bottom: 20px;
    }
    .ef-dropzone {
        padding-top: 20px;
        .dropzone {
            align-items: center;
            background: var(--mo-color-bg2);
            border: 1px solid var(--mo-color-ln);
            border-radius: $form-br;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 5px;
            .dz-message {
                margin: 0;
            }
            .dz-preview .dz-image {
                border-radius: 0px;
            }
        }
    }
    .ec-file-item {
        align-items: center;
        display: flex;
    }
}
