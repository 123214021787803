.mo-dialog-class {
    background: rgba(var(--mo-color-bg2-rgb), 0.1);
    .mo-dialog-wrapper {
        background: var(--mo-color-bg1);
        border-radius: 0;
        color: var(--mo-color-text1);
        outline: 1px solid var(--mo-color-ln);
        &.fix-height {
            height: 600px;
        }
        &.fix-height-full {
            height: 90vh;
        }
        &.min-height {
            min-height: 600px;
        }
        &.fix-width {
            width: 600px;
        }
        &.fix-width-full {
            width: 90vw;
        }
        .mo-dialog-header {
            background: var(--mo-color-bg2);
            border-bottom: 1px solid var(--mo-color-ln);
            font-size: 16px;
            padding: 10px;
            position: relative;
            text-align: center;
            .mo-dialog-header-info-wrap {
                align-items: center;
                bottom: 0;
                display: flex;
                justify-content: centers;
                position: absolute;
                left: 0;
                top: 0;
                padding-left: 10px;
                .mo-dialog-header-info {
                    color: var(--mo-color-text2);
                    font-size: 13px;
                }
                &.reverse {
                    left: auto;
                    right: 0;
                    padding-left: 0;
                    padding-right: 10px;
                }
            }
            .mo-dialog-header-close-wrap {
                align-items: center;
                bottom: 0;
                display: flex;
                justify-content: centers;
                position: absolute;
                right: 0;
                top: 0;
                padding-right: 10px;
                .mo-dialog-header-close {
                    .main-icon {
                        font-size: 25px;
                    }
                }
                &.reverse {
                    left: 0;
                    right: auto;
                    padding-right: 0;
                    padding-left: 10px;
                }
            }
        }
        .mo-dialog-content {
            background: var(--mo-color-bg1);
            padding: 15px !important;
            position: relative;
            &.no-padding {
                padding: 0px !important;
            }
            .mo-dialog-searchbar-wrap {
                background: var(--mo-color-bg2);
                border-bottom: 1px solid var(--mo-color-ln);
                padding: 10px;
                position: sticky;
                top: 0;
                z-index: 1;
                .mo-dialog-select {
                    padding-bottom: 10px;
                }
            }
            .mo-column-title {
                font-weight: bold;
                padding-bottom: 10px;
                text-align: center;
            }
            .mo-dialog-title-wrap {
                align-items: center;
                background: var(--mo-color-bg2);
                border-bottom: 1px solid var(--mo-color-ln);
                display: flex;
                height: 90px;
                justify-content: center;
                padding: 10px;
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }
        .mo-dialog-footer {
            align-items: center;
            background: var(--mo-color-bg2);
            border-top: 1px solid var(--mo-color-ln);
            display: flex;
            padding: 10px;
            .button-web,
            .button-wrap-web {
                width: 100%;
            }
            &.reverse {
                flex-direction: row-reverse;
                &>:not(style)~:not(style) {
                    margin-left: 0;
                    margin-right: 8px;
                }
            }
        }
    }
    &.inline-form {
        .mo-dialog-wrapper {
            height: 95vh;
            .mo-dialog-content {
                display: flex;
                .mo-dialog-content-left {
                    border-right: 1px solid var(--mo-color-ln);
                    display: flex;
                    flex-direction: column;
                    width: 370px;
                    .mo-dialog-content-form-children {
                        flex: 1;
                        padding: 15px;
                    }
                }
                .mo-dialog-content-right {
                    flex: 1;
                    position: relative;
                    .mo-dialog-content-table-container {
                        table {
                            border-spacing: 0;
                            min-width: 100%;
                            position: relative;
                            thead {
                                tr {
                                    th {
                                        &:first-child {
                                            border-left: none;
                                        }
                                        &:last-child {
                                            border-right: none;
                                        }
                                    }
                                }
                            }
                            tbody {
                                tr:nth-child(even) {
                                    background-color: var(--mo-color-bg2);
                                    td {
                                        background-color: var(--mo-color-bg2);
                                        &:first-child {
                                            border-left: none;
                                        }
                                        &:last-child {
                                            border-right: none;
                                        }
                                    }
                                }
                                tr:nth-child(odd) {
                                    background-color: var(--mo-color-bg1);
                                    td {
                                        background-color: var(--mo-color-bg1);
                                        &:first-child {
                                            border-left: none;
                                        }
                                        &:last-child {
                                            border-right: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.half {
            .mo-dialog-content {
                .mo-dialog-content-left {
                    width: 50%;
                }
            }
        }
        &.quarter {
            .mo-dialog-content {
                .mo-dialog-content-quarter {
                    border-right: 1px solid var(--mo-color-ln);
                    width: 100%;
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
    }
}
