.portfolio-detail-block-web {
    .pdbw-header {
        align-items: center;
        background: black;
        display: flex;
        height: 45px;
        justify-content: center;
        // .pdbw-device {
        // 	align-items: center;
        // 	display: flex;
        // 	font-size: 27px;
        // 	justify-content: center;
        // 	margin-left: 20px;
        // 	.icon {
        // 		color: var(--mo-color-text1);
        // 		cursor: pointer;
        // 		display: flex;
        // 		margin: 0 20px;
        // 		&.active {
        // 			color: var(--mo-color-mo-color);
        // 		}
        // 	}
        // }
        .pdbw-select {
            align-items: center;
            display: flex;
            left: 10px;
            position: absolute;
            width: 300px;
            .mo-select-input-web {
                margin-bottom: 0;
                width: 100%;
                .main-form-label {
                    display: none;
                }
            }
        }
        .pdbw-import {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 0 20px;
            ion-toggle {
                --track-background-checked: #8ea1ff;
                --track-background: #f4f4f4;
                --handle-background-checked: var(--mo-color-text1);
            }
            .pdbw-import-text {
                margin-left: 20px;
            }
        }
    }
    .pdbw-content {
        height: $nav-height-vh2;
        .pdbw-loading {
            color: var(--mo-color-mo-color);
            position: absolute;
            text-align: center;
            width: 100%;
        }
        iframe {
            border: none;
            height: 100%;
            position: relative;
            width: 100%;
        }
    }
}
