.help-center-topic-detail-about-mobile {
    padding: 50px 20px;
    .hctam-topic-wrap {
        .hctam-topic-name {
            font-size: 44px;
            padding-bottom: 50px;
        }
        .hctam-topic-description {
            font-size: 19px;
            img {
                display: inline-block;
            }
        }
        .hctam-topic-video {
            padding-top: 50px;
            .hctam-video-text {
                font-size: 44px;
                padding-bottom: 10px;
            }
            .hctam-topic-video-block {
                margin: auto;
                max-width: 640px;
                height: 360px;
            }
        }
    }
}
