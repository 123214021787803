.header-project-detail {
    background: black;
    position: relative;
    .header-project-detail-left {
        .header-project-detail-title {
            border-bottom: solid 1px var(--mo-color-ln);
            display: block;
            font-weight: 600;
            margin-bottom: 10px;
            padding: 10px 12px;
        }
        .pcb-tag-section {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 10px 12px;
            .pcb-tag-sub-section {
                display: flex;
                flex-wrap: wrap;
            }
            .pcb-tag-name {
                color: var(--mo-color-text2);
                margin-right: 5px;
                text-transform: uppercase;
            }
        }
    }
    .header-project-detail-right {
        display: flex;
        border-bottom: solid 1px var(--mo-color-ln);
        flex-direction: column;
        padding: 10px 12px;
        .pcb-label {
            margin-top: 5px;
        }
    }
}
