.image-crop-square-input {
    border: 1px solid var(--mo-color-ln);
    width: 350px;
    .icsi-image-edit-wrap {
        background: var(--mo-color-bg2);
        margin-bottom: 5px;
        .icsi-image {
            margin: auto;
            object-fit: cover;
        }
    }
    .icsi-buttons-wrap {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        .icsi-buttons-div {
            padding: 5px;
            width: 50%;
            .icsi-button {
                width: 100%;
            }
        }
    }
}
