.datetime-input {
    background: var(--mo-color-bg2);
    border-radius: $form-br;
    border: 1px solid var(--mo-color-ln);
    font-family: $font1;
    padding: 9px 13px;
    width: 100%;
    &:focus {
        box-shadow: 0 0 0 2px var(--mo-color-black);
        outline: 1px solid var(--mo-color-black);
    }
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker-popper {
    z-index: 3 !important;
}
