.main-detail-block2 {
    background-color: var(--mo-color-bg2);
    padding: 0 20px 20px;
    position: relative;
    height: $nav-height-vh2;
    .mdb-tabs {
        background-color: var(--mo-color-bg2);
        border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 10;
        .mdb-buttons {
            align-items: center;
            display: flex;
            padding: 15px 0 10px;
        }
        .mdb-active {
            font-weight: bold;
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }
    .mdb-content {
        height: calc(100vh - #{$nav-height} - #{$nav-list-height} - 61px);
    }
}
.main-list-header2 {
    background: var(--mo-color-bg2);
    position: sticky;
    top: 0;
    z-index: 2;
    th {
        padding: 10px 13px;
        white-space: nowrap;
        &.wrap {
            word-break: keep-all;
            white-space: normal;
        }
    }
}
.main-detail-info-block2 {
    background-color: var(--mo-color-bg2);
    padding: 20px 0;
    .mdib-wrap {
        border-bottom: 1px solid rgba(var(--mo-color-text1-rgb), 0.1);
        display: flex;
        padding: 10px;
        .mdib-title {
            flex-shrink: 0;
            width: 300px;
        }
        .mdib-title-helper {
            color: var(--mo-color-text2);
            flex-shrink: 0;
            font-size: 14px;
            width: 300px;
        }
        .mdib-value {
            padding-left: 10px;
            white-space: pre-line;
            .mdib-icon {
                align-items: center;
                display: flex;
                justify-content: center;
                &.mo-new-icon-check-circle-solid {
                    color: springgreen;
                }
                &.mo-new-icon-times-solid {
                    color: $error;
                }
            }
            .mdib-image {
                height: 200px;
            }
        }
        .mdib-value-wrap {
            align-items: center;
            display: flex;
            padding-left: 10px;
            .mdib-icon {
                align-items: center;
                display: flex;
                justify-content: center;
                &.mo-new-icon-check-circle-solid {
                    color: springgreen;
                }
                &.mo-new-icon-times-solid {
                    color: $error;
                }
            }
        }
        .mdib-translation-wrap {
            .mdib-translation-block {
                padding-top: 15px;
                .mdib-language {
                    color: var(--mo-color-text2);
                    font-size: 12px;
                    padding-left: 10px;
                }
            }
            &.flex-wrap {
                display: flex;
                flex-wrap: wrap;
                .mdib-language {
                    padding-right: 10px;
                }
                .mdib-value {
                    padding-right: 10px;
                }
            }
        }
    }
    .mdib-wrap-title {
        border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
        border-top: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
        padding: 10px;
        .mdib-title {
            font-weight: bold;
            padding-left: 0 !important;
            text-transform: uppercase;
        }
        .mdib-description {
            font-size: 14px;
            padding-top: 10px;
        }
    }
    &.section-title {
        .mdib-title {
            padding-left: 20px;
        }
        .mdib-title-helper {
            padding-left: 20px;
        }
    }
}
.main-list-block2 {
    &.italic {
        font-style: italic;
    }
    td {
        padding: 10px 13px;
        white-space: nowrap;
        &.pre-line {
            white-space: pre-line;
        }
    }
    .mlb-icon {
        align-items: center;
        display: flex;
        justify-content: center;
        &.mo-new-icon-check-circle-solid {
            color: springgreen;
        }
        &.mo-new-icon-times-solid {
            color: $error;
        }
    }
    .mlb-image {
        object-fit: contain;
        width: 100px;
    }
    .mlb-rich {
        white-space: initial;
    }
}
