.credit-short-info-web {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    .csiw-name-wrap {
        flex: 1;
        margin-left: 10px;
        &.no-image {
            margin-left: 0;
        }
        .csiw-name {
            display: inline-block;
        }
    }
}
