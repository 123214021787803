.profile-detail-menu-left-tab-web {
    .pdmltw-expand {
        align-items: center;
        color: var(--mo-color-text2);
        cursor: pointer;
        display: flex;
        margin-right: 10px;
        transition: $mo-ease;
        &.hidden {
            visibility: hidden;
        }
        &.open {
            transform: rotate(90deg);
        }
    }
    .pdmltw-tab-item-wrap {
        display: flex;
        .pdmltw-tab-item {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .pdmltw-tab-item,
    .pdmltw-tab-item-children {
        align-items: center;
        border-bottom: 1px solid var(--mo-color-ln);
        cursor: pointer;
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 7px 10px 7px 0;
        &.active {
            background: var(--mo-color-ln);
            color: var(--mo-color-mo-color);
        }
        &.separator {
            color: var(--mo-color-text2);
            font-size: 13px;
            pointer-events: none;
            text-transform: uppercase;
        }
        &:hover {
            background: var(--mo-color-ln);
            color: var(--mo-color-mo-color);
        }
        .pdmltw-ion-reorder {
            font-size: 24px;
            &::part(icon) {
                font-size: 24px;
            }
        }
    }
    .pdmltw-searchbar {
        padding: 0;
    }
    .pdmltw-children-wrap {
        border-left: 1px solid var(--mo-color-ln);
        display: none;
        margin-left: 3px;
        padding-left: 27px;
        &.open {
            display: block;
        }
    }
    .pdmltw-children-children-wrap {
        border-left: 1px solid var(--mo-color-ln);
        display: none;
        margin-left: -14px;
        padding-left: 27px;
        &.open {
            display: block;
        }
    }
    .pdmltw-tab-item-children-wrap {
        align-items: center;
        display: flex;
        &.parent {
            margin-left: -18px;
        }
    }
}
