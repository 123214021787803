.agenda-du-quartier-payment-success {
    padding: 40px;
    .adgps-content {
        background: var(--mo-color-bg1);
        border-radius: 14px;
        padding: 40px;
        .adgps-title {
            border-bottom: 1px solid var(--mo-color-ln);
            color: var(--mo-color-mo-color2);
            font-size: 27px;
            font-weight: bold;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
        .adgps-ref {
            color: #2d80a2;
            font-weight: bold;
            padding-bottom: 10px;
        }
        .adgps-subtitle {
            font-weight: bold;
        }
        .adgps-table {
            th, td {
                border-bottom: 1px solid var(--mo-color-ln);
                padding: 0 5px;
                text-align: center;
            }
        }
        .adgps-button-wrap {
            padding: 20px 0;
        }
        .adgps-hr {
            border: 0;
            border-top: 1px solid var(--mo-color-ln);
            margin: 20px 0;
        }
    }
}
