.profile-section-block {
    align-items: center;
    border-bottom: solid 1px var(--mo-color-ln);
    color: var(--mo-color-text2);
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    .profile-section-block-small-text {
        color: var(--mo-color-text1);
        font-size: 14px;
        padding-right: 10px;
    }
    .main-hashtag {
        font-size: 14px;
        &.active,
        &:hover {
            color: var(--mo-color-mo-color);
            text-decoration: underline;
        }
    }
}
