.offer-list-medium-block-web {
    display: flex;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    .olmbw-cover {
        width: 200px;
        .olmbw-image {
            width: 100%;
        }
    }
    .olmbw-info {
        flex: 1;
        padding: 0px 5px 0 10px;
        .olmbw-tags {
            .main-chip-list-block {
                height: 21px;
                margin-bottom: 5px;
            }
        }
        .olmbw-date {
            padding-top: 5px;
            .olmbw-text {
                margin-right: 5px;
            }
        }
        .olmbw-price-wrap {
            padding-top: 10px;
        }
        .olmbw-countries {
            padding-top: 10px;
            .main-chip-list-block {
                display: flex;
            }
        }
    }
    .olmbw-actions {
        display: flex;
        flex-direction: column;
        width: 30px;
    }
}
