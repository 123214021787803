.block-template-list-block-web {
    display: inline-block;
    overflow: hidden;
    padding: 7px 7px 20px;
    position: relative;
    width: 300px;
    .btlbw-cover {
        position: relative;
        .btlbw-cover-wrap {
            display: block;
            position: relative;
            z-index: 2;
            .btlbw-image-wrap {
                align-items: center;
                border: 1px solid var(--mo-color-ln);
                display: flex;
                justify-content: center;
                padding-bottom: 100%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .btlbw-image {
                    height: 100%; // To keep aspect ratio
                    object-fit: contain;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
                &:after {
                    content: "";
                    background: rgba(var(--mo-color-bg2-rgb), 0.7);
                    cursor: pointer;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    transition: $mo-ease;
                    width: 100%;
                    z-index: 4;
                }
                &:hover {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
        .btlbw-actions {
            align-items: center;
            display: flex;
            justify-content: space-around;
            opacity: 0;
            position: absolute;
            top: calc(50% - 18px);
            transition: $mo-ease;
            width: 100%;
            z-index: 2;
            .main-icon-block-web {
                margin-bottom: 0;
                .main-icon {
                    font-size: 20px;
                }
            }
        }
        &.is-open,
        &:hover {
            .btlbw-cover-wrap {
                .btlbw-image-wrap {
                    &:after {
                        opacity: 1;
                    }
                }
            }
            .btlbw-actions {
                opacity: 1;
            }
        }
    }
    .btlbw-info {
        padding-bottom: 15px;
        padding-top: 9px;
        .mo-list-block-name-web {
            font-size: 14px;
        }
        .btlbw-tags {
            .main-chip-list-block {
                height: 20px;
                .main-hashtag {
                    font-size: 13px;
                }
            }
        }
        .btlbw-price-wrap {
            padding-top: 5px;
            .btlbw-price {
                font-size: 13px;
            }
        }
        .list-block-footer {
            margin-top: 5px;
            .list-profile-link-web {
                font-size: 13px;
                height: 20px;
                overflow: hidden;
            }
        }
    }
}
