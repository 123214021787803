.help-center-topic-about-mobile {
    .hctam-list {
        width: 100%;
        .hctam-category-item {
            &::part(detail-icon) {
                transition: $mo-ease;
            }
            &.open {
                color: var(--mo-color-mo-color);
                &::part(detail-icon) {
                    color: var(--mo-color-mo-color);
                    transform: rotate(90deg);
                }
            }
            .hctam-category-label {
                white-space: normal;
                &.open {
                    color: var(--mo-color-mo-color);
                }
            }
        }
        .hctam-children-item {
            &::part(detail-icon) {
                transition: $mo-ease;
            }
            &.open {
                color: var(--mo-color-mo-color);
                &::part(detail-icon) {
                    color: var(--mo-color-mo-color);
                    transform: rotate(90deg);
                }
            }
            .hctam-children-label {
                padding-left: 30px;
                white-space: normal;
                &.open {
                    color: var(--mo-color-mo-color);
                }
            }
        }
        .hctam-topic-label {
            padding-left: 60px;
            white-space: normal;
        }
    }
}
