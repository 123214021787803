.count-view-block-web {
    border-bottom: solid 1px var(--mo-color-ln);
    color: var(--mo-color-text2);
    padding: 12px 0;
    @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    .cvbw-text {
        color: var(--mo-color-text1);
    }
    .cvbw-created-wrap {
        padding: 2px 0;
        .cvbw-added {
            display: inline-block;
            margin-right: 3px;
        }
    }
    .cvbw-small-text {
        color: var(--mo-color-text1);
        font-size: 14px;
        padding: 10px 0 5px;
    }
}
