.location-detail-block-web {
    .main-country-flag-web {
        height: auto;
        margin: 0;
    }
}
.location-detail-block-full-web {
    .ldbfw-title {
        color: var(--mo-color-text1);
    }
    .ldbfw-wrap {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .ldbfw-value-wrap {
            text-align: right;
        }
    }
}
.location-detail-block-reduced-web {
    .ldbrw-wrap {
        align-items: center;
        display: flex;
        .ldbrw-text {
            padding-left: 10px;
        }
        .main-country-flag-web {
            height: auto;
            margin: 0;
        }
    }
}
