.phone-number-input {
    .PhoneInputInput {
        background: var(--mo-color-bg2);
        border: 1px solid var(--mo-color-ln);
        border-radius: $form-br;
        min-height: 36px;
        padding: 0px 10px;
    }
    .PhoneInputCountry {
        border: 0;
    }
}
