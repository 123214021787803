.mixed-content-block-web {
    height: $nav-height-vh2;
    position: relative;
    width: 100%;
    .mcbw-loader {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .slug-page-content {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        height: $nav-height-vh2;
        min-height: $nav-height-vh2;
    }
    &.tabs {
        height: calc(#{$nav-height-vh2} - 45px);
        .slug-page-content {
            height: calc(#{$nav-height-vh2} - 45px);
            min-height: calc(#{$nav-height-vh2} - 45px);
        }
    }
    &.edit {
        height: calc(100vh - 45px);
        .slug-page-content {
            height: calc(100vh - 45px);
            min-height: calc(100vh - 45px);
        }
    }
}
