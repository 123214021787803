.mo-checkbox-input-web {
    margin-bottom: $form-mb;
    &.no-mb {
        margin-bottom: 0;
    }
    .main-form-label {
        justify-content: flex-end;
    }
    .list-ios,
    .list-md {
        border-radius: $form-br;
    }
    .eciw-label {
        text-overflow: initial;
        white-space: initial;
    }
    .eciw-item {
        --background: var(--mo-color-bg2);
    }
}
