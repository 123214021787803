.translation-helper-web {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    .thw-language {
        color: var(--mo-color-text2);
        cursor: pointer;
        flex-shrink: 0;
        padding-right: 20px;
        transition: $mo-ease;
        &.active,
        &:hover {
            color: var(--mo-color-mo-color);
        }
    }
    &.in-nav {
        padding: 0 0 0 20px;
    }
}
