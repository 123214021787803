.mo-image-crop-square-input-web {
    margin-bottom: $form-mb;
    .main-form-label {
        margin-bottom: 7px;
    }
    .eici-image-wrap {
        background: var(--mo-color-bg2);
        height: 250px;
        margin: 0px auto 20px;
        width: 250px;
        &.press {
            height: 204px;
            width: 340px;
        }
        .eici-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
    .eici-buttons {
        display: flex;
        width: 100%;
        .eici-buttons-div {
            width: 100%;
            .eici-button {
                width: 100%;
            }
        }
    }
}
