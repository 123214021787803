.textarea-input-portfolio {
    .main-form-label {
        .main-form-label-wrap {
            flex: initial;
        }
        .main-form-helper-wrap {
            flex: 1;
            .main-form-label-value {
                text-align: right;
                white-space: normal;
            }
        }
    }
}
