.selection-form-item {
    border-bottom: 1px solid var(--mo-color-ln);
    cursor: pointer;
    display: flex;
    padding: 7px 0;
    transition: opacity 0.3s ease;
    &:hover {
        opacity: 0.5;
    }
    .sfi-label-wrap {
        align-items: center;
        display: flex;
        flex: 1;
    }
    .sfi-input-wrap {
        .sfi-input-div {
            align-items: center;
            border-radius: $form-br;
            border: none;
            display: flex;
            outline: none;
            padding: 0 13px;
            width: 100%;
            .sfi-input {
                -webkit-appearance: none;
                appearance: none;
                /* Remove default styles */
                background-color: #fff;
                border-radius: 50%;
                border: 2px solid #000;
                cursor: pointer;
                height: 22px;
                position: relative;
                width: 22px;
                &:checked {
                    background-color: var(--mo-color-mo-color);
                }
                &:checked:after {
                    background: white;
                    border-radius: 50%;
                    content: '';
                    /* Necessary for creating the checkmark */
                    height: 6px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 6px;
                }
            }
        }
    }
}
