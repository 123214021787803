.content-list-modal-portfolio {
    .clmp-thumb-web {
        padding: 5px 10px 5px 0;
    }
    .clmp-label {
        white-space: normal;
    }
    .clmp-label-icon {
        align-items: center;
        display: flex;
        margin-right: 10px;
    }
    .clmp-profile {
        color: var(--mo-color-text2);
    }
    .clmp-long-description {
        color: var(--mo-color-text2);
        font-size: 12px;
        padding-top: 5px;
    }
    .clmp-active {
        color: var(--mo-color-mo-color);
    }
    .clmp-medium-type {
        color: var(--mo-color-text2);
    }
}
