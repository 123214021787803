.tab-list-container-web {
    .tlcw-content {
        display: flex;
        height: $nav-height-vh2;
        .tlcw-left {
            background: var(--mo-color-bg1);
            border-right: 1px solid var(--mo-color-ln);
            height: $nav-height-vh2;
            padding: 15px;
            width: 240px;
            .tlcw-menu-web {
                .tlcw-thumb-wrap {
                    border-bottom: 1px solid var(--mo-color-ln);
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 10px;
                    .tlcw-size-icon {
                        .main-icon {
                            font-size: 15px;
                        }
                        &.active {
                            .main-icon {
                                color: var(--mo-color-mo-color);
                            }
                        }
                    }
                }
                .tlcw-tab-item {
                    --padding-start: 0;
                }
            }
        }
        .tlcw-right {
            flex: 1;
            height: $nav-height-vh2;
            padding-bottom: 10px;
            .tlcw-infinite {
                display: flex;
                flex-wrap: wrap;
                padding: 20px;
                .block-template-list-block-web,
                .event-list-small-block-web,
                .material-list-small-block-web,
                .offer-list-small-block-web,
                .page-template-list-block-web,
                .product-list-small-block-web,
                .project-list-small-block-web,
                .service-list-small-block-web,
                .square-block {
                    @media (max-width: 2680px) {
                        width: calc(100% / 8);
                    }
                    @media (max-width: 2380px) {
                        width: calc(100% / 7);
                    }
                    @media (max-width: 2080px) {
                        width: calc(100% / 6);
                    }
                    @media (max-width: 1780px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 1480px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1180px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 880px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 580px) {
                        width: 100%;
                    }
                }
                .portfolio-template-list-block-web {
                    width: 25%;
                }
                .portfolio-list-block-web,
                .press-list-small-block-web {
                    @media (max-width: 2780px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 2280px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1780px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 1280px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 780px) {
                        width: 100%;
                    }
                }
                .profile-list-small-block-web,
                .team-list-small-block-web {
                    @media (max-width: 2780px) {
                        width: calc(100% / 10);
                    }
                    @media (max-width: 2530px) {
                        width: calc(100% / 9);
                    }
                    @media (max-width: 2280px) {
                        width: calc(100% / 8);
                    }
                    @media (max-width: 2030px) {
                        width: calc(100% / 7);
                    }
                    @media (max-width: 1780px) {
                        width: calc(100% / 6);
                    }
                    @media (max-width: 1530px) {
                        width: calc(100% / 5);
                    }
                    @media (max-width: 1280px) {
                        width: calc(100% / 4);
                    }
                    @media (max-width: 1030px) {
                        width: calc(100% / 3);
                    }
                    @media (max-width: 780px) {
                        width: calc(100% / 2);
                    }
                    @media (max-width: 530px) {
                        width: 100%;
                    }
                }
            }
            &.map {
                padding-bottom: 0;
            }
            &.medium {
                padding: 0 5px 10px;
            }
            .tlcw-map-wrap {
                display: flex;
                height: $nav-height-vh2;
                width: 100%;
                .tlcw-map {
                    flex: 1;
                    .leaflet-container {
                        background: #191a1a;
                        height: 100%;
                        width: 100%;
                        a {
                            color: initial;
                            text-decoration: initial;
                        }
                        .tlcw-map-searchbox {
                            border: 2px solid rgba(var(--mo-color-bg2-rgb), 0.2);
                            .search-control {
                                border-radius: 2px;
                                .search-control-icon-button {
                                    width: 30px;
                                }
                            }
                        }
                    }
                }
                .tlcw-map-items {
                    height: $nav-height-vh2;
                    width: 300px;
                    .tlcw-map-item-detail {
                        padding: 0 5px;
                    }
                }
                div.tlcw-map-items>* {
                    width: 100%;
                }
            }
        }
    }
}
