.material-list-small-block {
    display: inline-block;
    overflow: hidden;
    padding: 2px 5px;
    position: relative;
    width: 50%;
    .mlsb-cover {
        .mlsb-cover-wrap {
            position: relative;
            z-index: 2;
            .mlsb-image-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
                padding-bottom: 100%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .mlsb-image {
                    border-radius: 7px;
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
            }
        }
    }
}
