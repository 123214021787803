.offer-detail-block {
    position: relative;
    .olb-cover-image {
        width: 100%;
    }
    .olb-material-info {
        padding: 10px;
        .olb-main-info {
            border-bottom: solid 1px var(--mo-color-ln);
            padding-bottom: 10px;
            margin-bottom: 10px;
            .olb-name {
                font-weight: 600;
            }
            .olb-tags {
                border-bottom: solid 1px var(--mo-color-ln);
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
            .olb-date {
                border-bottom: solid 1px var(--mo-color-ln);
                margin-bottom: 10px;
                padding-bottom: 10px;
                .olb-text {
                    margin-right: 10px;
                }
            }
            .olb-allowance {
                border-bottom: solid 1px var(--mo-color-ln);
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
            .olb-countries {
                padding-bottom: 10px;
                .olb-cities {
                    color: var(--mo-color-text2);
                }
            }
            .olb-phone {
                color: var(--mo-color-text2);
            }
        }
        .olb-section {
            border-bottom: solid 1px var(--mo-color-ln);
            padding-bottom: 10px;
            margin-bottom: 10px;
            .olb-section-title {
                border-bottom: solid 1px var(--mo-color-ln);
                font-weight: 600;
                margin-bottom: 10px;
                padding-bottom: 10px;
            }
            .olb-description {
                color: var(--mo-color-text2);
            }
            .olb-section-content {
                margin-bottom: 20px;
                .olb-image {
                    margin-bottom: 10px;
                }
                .olb-section-button {
                    margin: 20px auto 0;
                    max-width: 70%;
                }
            }
        }
    }
}
