.translation-form {
    .tf-divider {
        background: var(--mo-color-bg2);
        border-bottom: 1px solid var(--mo-color-ln);
        border-top: 1px solid var(--mo-color-ln);
        font-size: 12px;
        padding: 10px 15px;
        .tf-divider-content {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
            .tf-divider-buttons {
                .main-icon-block-web .main-icon {
                    font-size: 12px;
                }
            }
        }
    }
    .tf-default-value {
        display: block;
        padding: 15px;
        white-space: pre-line;
        .tf-image {
            margin: auto;
        }
        &.tf-cities-temp {
            .tf-wrap {
                align-items: center;
                display: flex;
                .tf-text {
                    padding-left: 10px;
                }
                .main-country-flag-web {
                    height: auto;
                    margin: 0;
                }
            }
        }
    }
    .tf-default-value-image {
        display: block;
        .tf-image {
            margin: auto;
        }
    }
    .tf-default-value-rich {
        display: block;
        padding: 15px;
    }
}
