.term-about-web {
    .taw-segments {
        background: var(--mo-color-bg1);
        display: flex;
        justify-content: center;
        padding: 5px;
        position: sticky;
        top: 0;
        @media (max-width: 576px) {
            top: $nav-height-about-mobile;
        }
        &.authenticated {
            top: $nav-height;
            @media (max-width: 576px) {
                top: 0;
            }
        }
    }
    .taw-title {
        font-weight: bold;
        padding: 20px;
        text-align: center;
        @media (max-width: 576px) {
            padding-top: calc(#{$nav-height-about-mobile} + 20px);
        }
        &.authenticated {
            @media (max-width: 576px) {
                padding-top: 20px;
            }
        }
    }
    .tab-content {
        margin: auto;
        max-width: 800px;
        padding: 20px;
        .taw-block {
            .taw-name {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 20px;
                text-transform: uppercase;
            }
            .taw-description {
                color: var(--mo-color-text2);
                margin-bottom: 40px;
            }
        }
    }
}
