.image-helper-web {
    position: relative;
    .mo-image-loader {
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
    }
}
.mo-ihwvh {
    visibility: hidden;
}
.mo-ihwvv {
    visibility: visible;
}
.mo-image-helper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
