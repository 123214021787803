.image-form {
    .if-input {
        height: 100%;
        padding: 20px;
        .dropzone {
            align-items: center;
            background: var(--mo-color-bg2);
            border-radius: $form-br;
            border: none;
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            justify-content: center;
            padding: 5px;
            .dz-message {
                margin: 0;
            }
            .dz-preview .dz-image {
                border-radius: 0px;
            }
        }
    }
}
