// Font-Family
$font1: "FuturaPT", sans-serif;
// General
$MainFontSize: 16px;
// Form
$form-mb: 30px;
$form-mb-new: 10px;
$form-br: 8px;
$form-border: 1px solid #8c929d;
$mo-ease: background 0.3s ease,
    border-color 0.3s ease,
    border-style 0.3s ease,
    border-width 0.3s ease,
    color 0.3s ease,
    opacity 0.3s ease,
    text-decoration-color 0.3s ease,
    text-decoration-line 0.3s ease,
    text-decoration-dtyle 0.3s ease,
    text-decoration-thickness 0.3s ease,
    text-underline-offset 0.3s ease,
    transform 0.3s ease;
$nav-height: 53px;
$nav-height-about: 71px;
$nav-height-about-mobile: 45px;
$nav-children-height: 45px;
$nav-player-height: 55px;
$nav-height-vh: calc(100vh - #{$nav-height});
$nav-height-vh2: calc(100vh - #{$nav-height} - #{$nav-children-height});
$nav-height-vh3: calc(100vh - #{$nav-height} - #{$nav-player-height});
$nav-list-height: 53px;
$portfolio-height-vh: calc(100vh - 45px);
