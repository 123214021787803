.profile-section-content-ordering-web {
    position: relative;
    .pscow-ordering-action {
        align-items: center;
        background: var(--mo-color-bg1);
        border-bottom: 1px solid var(--mo-color-ln);
        display: flex;
        position: sticky;
        top: 0;
        z-index: 2;
        .pscow-radio-group {
            display: flex;
            .pscow-label-text {
                font-size: 12px;
                color: var(--mo-color-text2);
            }
        }
        .pscow-button {
            margin-left: 40px;
        }
    }
    .pscow-infinite {
        padding: 20px;
        .pscow-reorder-item {
            .pscow-item {
                &::part(native) {
                    cursor: grab;
                }
            }
        }
        .pscow-label {
            padding-left: 10px;
            .pscow-text {
                color: var(--mo-color-text2);
            }
            .pscow-draft {
                color: var(--mo-color-mo-color);
            }
        }
    }
}
