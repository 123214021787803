.description-detail-block-web {
    border-bottom: solid 1px var(--mo-color-ln);
    padding: 12px 0;
    .ddbw-title {
        color: var(--mo-color-text2);
        padding-bottom: 7px;
        text-align: center;
        text-transform: uppercase;
    }
    .ddbw-text {
        color: var(--mo-color-text2);
        .product-list-micro-block-web {
            padding: 2px 10px 2px 0;
        }
    }
}
