.offer-list-big-block {
    padding-bottom: 50px;
    position: relative;
    width: 100%;
    .olbb-cover {
        border-radius: 7px;
        display: block;
        overflow: hidden;
        .olbb-image {
            width: 100%;
        }
    }
    .olbb-bottom {
        padding: 5px 10px 10px;
        .olbb-bottom-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .olbb-feed-date {
                color: var(--mo-color-text2);
            }
            .olbb-actions {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 5px;
            }
        }
        .olbb-date {
            color: var(--mo-color-text2);
            padding-top: 10px;
            .olbb-text {
                margin-right: 5px;
            }
        }
        .olbb-price-wrap {
            padding-top: 10px;
        }
        .olbb-countries {
            padding-top: 10px;
            .main-chip-list-block {
                display: flex;
            }
        }
        .olbb-description {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            color: var(--mo-color-text2);
            display: -webkit-box;
            margin: 10px 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
