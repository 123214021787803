.shipping-cost-panel-web {
    display: flex;
    .mo-text-input-webnew {
        margin-bottom: 10px;
        padding: 0 5px;
        width: 120px;
        .mo-text-input-web-inputnew.disabled {
            opacity: 0.7;
        }
    }
    .scpw-action-wrap {
        align-items: center;
        display: flex;
        margin-bottom: 10px;
        &.index-0 {
            margin-top: 20px;
        }
    }
}
