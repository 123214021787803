.location-input {
    .list-ios,
    .list-md {
        background: transparent;
        border-radius: $form-br;
    }
    .li-label {
        text-overflow: initial;
        white-space: initial;
    }
}
