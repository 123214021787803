.checkbox-input {
    align-items: center;
    background: var(--mo-color-bg2);
    border-radius: $form-br;
    border: 1px solid var(--mo-color-ln);
    cursor: pointer;
    display: flex;
    height: 39px;
    outline: none;
    padding: 0 13px;
    width: 100%;
    // &:focus {
    //     box-shadow: 0 0 0 2px var(--mo-color-black);
    //     outline: 1px solid var(--mo-color-black);
    // }
    .ci-input {
        -webkit-appearance: none;
        appearance: none;
        /* Remove default styles */
        background-color: #fff;
        border-radius: 50%;
        border: 2px solid #000;
        cursor: pointer;
        height: 22px;
        position: relative;
        width: 22px;
        &:checked {
            background-color: var(--mo-color-mo-color);
        }
        &:checked:after {
            background: white;
            border-radius: 50%;
            content: '';
            /* Necessary for creating the checkmark */
            height: 6px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 6px;
        }
    }
}
