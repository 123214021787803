.member-detail-block-web {
    display: flex;
    height: $nav-height-vh2;
    position: relative;
    &.medium-type-33 {
        .mdbw-left {
            padding: 0;
        }
    }
    .mdbw-left {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 1;
        height: $nav-height-vh2;
        justify-content: center;
        padding: 10px;
        .mdbw-cover-image {
            max-height: calc(#{$nav-height-vh2} - 20px);
            max-width: 100%;
        }
    }
    .mdbw-right {
        background: var(--mo-color-bg1);
        border-left: 1px solid var(--mo-color-ln);
        flex-shrink: 0;
        padding: 0 12px;
        position: relative;
        width: 370px;
    }
    &.edit {
        height: $nav-height-vh;
        .mdbw-left {
            height: $nav-height-vh;
            .mdbw-cover-image {
                max-height: calc(#{$nav-height-vh} - 20px);
            }
        }
        .mdbw-right {
            height: $nav-height-vh;
        }
    }
}
