.list-content {
    display: flex;
    flex-direction: column;
    .lc-header {
        border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
        border-top: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
        padding: 10px 0;
        .lc-wrap-title {
            .lc-title {
                font-weight: bold;
                padding-bottom: 15px;
                text-transform: uppercase;
            }
            .lc-filters {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 15px;
                .button-web {
                    margin: 0 10px;
                    min-width: 0;
                    padding: 5px 0;
                    text-transform: capitalize;
                }
                .lc-active {
                    font-weight: bold;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                }
            }
        }
        .lc-action {
            align-items: center;
            display: flex;
            justify-content: space-between;
            .lc-search-wrap {
                align-items: center;
                display: flex;
                justify-content: flex-end;
                padding: 0 0 0 10px;
                .lc-searchbar {
                    padding: 0 10px;
                    min-height: 0;
                    width: 200px;
                    .searchbar-search-icon {
                        display: none;
                        height: 0;
                        width: 0;
                    }
                    .searchbar-input-container {
                        min-height: 24px;
                    }
                    .searchbar-input {
                        border-radius: 5px;
                        font-size: 14px;
                        padding-bottom: 0;
                        padding-inline-start: 13px;
                        padding-top: 0;
                    }
                }
                .lc-icon {
                    .main-icon {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .lc-table {
        flex: 1;
        table {
            border-collapse: separate;
            border-spacing: 0;
            // min-width: 100%;
            position: relative;
            &.lc-sub-tab-table-Affiche {
                tbody {
                    tr:nth-child(-n+8) {
                        td {
                            border: 1px solid var(--mo-color-mo-color);
                        }
                    }
                }
            }
            tbody {
                tr:nth-child(even) {
                    background-color: var(--mo-color-bg2);
                    td {
                        background-color: var(--mo-color-bg2);
                    }
                    &:hover {
                        background-color: rgba(var(--mo-color-mo-color-rgb), 0.3);
                        .table-column,
                        .tr-action {
                            background-color: rgba(var(--mo-color-mo-color-rgb), 0.3);
                        }
                    }
                }
                tr:nth-child(odd) {
                    background-color: var(--mo-color-bg1);
                    td {
                        background-color: var(--mo-color-bg1);
                    }
                    &:hover {
                        background-color: rgba(var(--mo-color-mo-color-rgb), 0.3);
                        .table-column,
                        .tr-action {
                            background-color: rgba(var(--mo-color-mo-color-rgb), 0.3);
                        }
                    }
                }
            }
        }
    }
    .lc-reorder {
        .lc-reorder-item {
            display: flex;
            .lc-reorder-item-row {
                flex: 1;
            }
        }
    }
}
