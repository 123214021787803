.main-list-row {
    &.link-row {
        td {
            padding: 0;
            &.mlr-td-action {
                width: 40px;
                .mlr-action-div {
                    display: flex;
                    justify-content: center;
                    .mo-new-icon-pencil-alt-solid {
                        font-size: 14px;
                    }
                }
            }
            &.mlr-td-error {
                color: $error;
            }
            &.mlr-td-BooleanField {
                width: 40px;
            }
            &.mlr-td-ImageField {
                width: 30px;
                .mlr-link {
                    display: flex;
                    justify-content: center;
                }
                .rzh-image-zarma-name {
                    font-size: 13px !important;
                }
            }
            &.mlr-td-text {
                white-space: pre-line;
            }
        }
        .mlr-link {
            color: inherit;
            .mlr-link-div {
                padding: 5px 7px;
            }
        }
    }
    th {
        border-bottom: 1px solid var(--mo-color-ln);
        border-left: 1px solid var(--mo-color-ln);
        border-right: 1px solid var(--mo-color-ln);
        padding: 5px 7px;
        white-space: nowrap;
        &.pre-line {
            white-space: pre-line;
        }
        &:first-child {
            border-left: none;
        }
        &:last-child {
            border-right: none;
        }
    }
    td {
        border-bottom: 1px solid var(--mo-color-ln);
        border-left: 1px solid var(--mo-color-ln);
        border-right: 1px solid var(--mo-color-ln);
        padding: 5px 7px;
        white-space: nowrap;
        &.pre-line {
            white-space: pre-line;
        }
        &:first-child {
            border-left: none;
        }
        &:last-child {
            border-right: none;
        }
    }
    .mlr-action {
        width: 54px;
        .mo-new-icon-pencil-alt-solid {
            font-size: 14px;
        }
        .mo-new-icon-trash-solid {
            font-size: 14px;
        }
    }
    .mlr-icon {
        align-items: center;
        display: flex;
        font-size: 12px;
        justify-content: center;
        &.mo-new-icon-check-circle-solid {
            color: springgreen;
        }
        &.mo-new-icon-times-solid {
            color: $error;
        }
    }
    .mlr-image {
        cursor: zoom-in;
        height: 50px;
        object-fit: contain;
        object-fit: cover;
        width: 50px;
    }
    .mlr-rich {
        white-space: initial;
        a {
            color: var(--mo-color-mo-color);
            text-decoration: underline;
        }
        ul {
            margin: 0;
        }
    }
    &.total {
        border-bottom: 3px solid var(--mo-color-ln);
        td {
            font-weight: bold;
        }
    }
    &.compressed {
        td {
            font-size: 13px;
        }
    }
    &.small {
        td {
            font-size: 14px;
            color: var(--mo-color-text2);
        }
    }
}
.main-detail-block {
    display: flex;
    height: $nav-height-vh2;
    position: relative;
    width: 100%;
    .mdb-tabs {
        background-color: var(--mo-color-bg1);
        border-bottom: 1px solid rgba(var(--mo-color-text1-rgb), 0.1);
        display: flex;
        height: 45px;
        justify-content: space-between;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
        .mdb-buttons {
            align-items: center;
            border-right: 1px solid var(--mo-color-ln);
            cursor: pointer;
            display: flex;
            justify-content: center;
            padding: 10px;
            text-align: center;
            width: 100%;
            &:last-child {
                border-right: none;
            }
            &.active {
                color: var(--mo-color-mo-color);
                font-weight: bold;
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }
    .mdb-left {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 1;
        height: $nav-height-vh2;
        justify-content: center;
        padding: 10px;
        position: relative;
        width: calc(100vw - 370px);
        &.tabs {
            padding-top: 55px;
        }
        &.table {
            display: block;
            padding: 45px 0 0;
        }
        .flex-center {
            justify-content: center;
        }
        .mdb-cover-image {
            cursor: pointer;
            max-height: 100%;
            max-width: 100%;
        }
        .mdb-content {
            width: 100%;
        }
    }
    .mdb-right {
        background: var(--mo-color-bg1);
        border-left: 1px solid var(--mo-color-ln);
        flex-shrink: 0;
        padding: 0 12px;
        position: relative;
        width: 370px;
    }
    &.medium-type-custom {
        .mdb-left {
            padding: 0;
            &.tabs {
                padding: 45px 0 0;
            }
        }
    }
    &.edit {
        height: $nav-height-vh;
        .mdb-left {
            height: $nav-height-vh;
        }
        .mdb-right {
            height: $nav-height-vh;
        }
    }
    .color-green {
        color: springgreen;
    }
    .color-orange {
        color: $error;
    }
    .color-red {
        color: $error;
    }
}
.main-list-header {
    background: var(--mo-color-bg2);
    border-bottom: 1px solid var(--mo-color-ln);
    position: sticky;
    top: 0;
    z-index: 2;
    th {
        border-left: 1px solid var(--mo-color-ln);
        border-right: 1px solid var(--mo-color-ln);
        font-size: 13px;
        white-space: nowrap;
        &.wrap {
            word-break: keep-all;
            white-space: normal;
        }
        &.multi-line {
            white-space: pre-line;
        }
        &.mw200 {
            min-width: 200px;
        }
        &:first-child {
            border-left: none;
        }
        &:last-child {
            border-right: none;
        }
        &.ml-th-action {
            width: 40px;
        }
        &.ml-th-error {
            color: $error;
        }
        .ml-div {
            border-bottom: 3px solid var(--mo-color-ln);
            border-top: 2px solid var(--mo-color-ln);
            padding: 5px 7px;
        }
    }
    &.compressed {
        th {
            font-size: 13px;
        }
    }
}
.main-detail-info-block {
    background-color: var(--mo-color-bg2);
    padding: 20px 0;
    .mdib-wrap {
        border-bottom: 1px solid rgba(var(--mo-color-text1-rgb), 0.1);
        display: flex;
        padding: 10px;
        .mdib-title {
            flex-shrink: 0;
            width: 300px;
        }
        .mdib-title-helper {
            color: var(--mo-color-text2);
            flex-shrink: 0;
            font-size: 14px;
            width: 300px;
        }
        .mdib-value {
            padding-left: 10px;
            white-space: pre-line;
            .mdib-icon {
                align-items: center;
                display: flex;
                justify-content: center;
                &.mo-new-icon-check-circle-solid {
                    color: springgreen;
                }
                &.mo-new-icon-times-solid {
                    color: $error;
                }
            }
            .mdib-image {
                height: 200px;
            }
        }
        .mdib-translation-wrap {
            .mdib-translation-block {
                padding-top: 15px;
                .mdib-language {
                    color: var(--mo-color-text2);
                    font-size: 12px;
                    padding-left: 10px;
                }
            }
            &.flex-wrap {
                display: flex;
                flex-wrap: wrap;
                .mdib-language {
                    padding-right: 10px;
                }
                .mdib-value {
                    padding-right: 10px;
                }
            }
        }
    }
    .mdib-wrap-title {
        border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
        border-top: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
        padding: 10px;
        .mdib-title {
            font-weight: bold;
            padding-left: 0 !important;
            text-transform: uppercase;
        }
        .mdib-description {
            font-size: 14px;
            padding-top: 10px;
        }
    }
    &.section-title {
        .mdib-title {
            padding-left: 20px;
        }
        .mdib-title-helper {
            padding-left: 20px;
        }
    }
}
