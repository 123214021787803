.service-detail-block-web {
    .sdbw-schedule {
        white-space: pre-line;
        .sdbw-schedule-wrap {
            padding-bottom: 10px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
