.button-input-web {
    margin-bottom: $form-mb;
}
.ebi-button-web {
    align-items: center;
    background: black;
    border-radius: 3px;
    border: solid 1px var(--mo-color-mo-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    min-height: 46px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: $mo-ease;
    width: 100%;
    &.mb-30 {
        margin-bottom: 30px;
    }
}
