.ratio-helper-web {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    // z-index: 2;  precise why if needed
    .rhw-image-wrap {
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        padding-bottom: 100%; // To keep aspect ratio
        position: relative; // To keep aspect ratio
        width: 100%; // To keep aspect ratio
        .rhw-image {
            height: 100%; // To keep aspect ratio
            object-fit: cover;
            object-position: center;
            position: absolute; // To keep aspect ratio
            top: 0; // To keep aspect ratio
            width: 100%; // To keep aspect ratio
        }
    }
    &.contain {
        .rhw-image-wrap {
            .rhw-image {
                object-fit: contain;
                padding: 10px;
            }
        }
    }
    &.link {
        cursor: pointer;
    }
}
