.not-found-page-web {
    &.not-authenticated {
        padding-top: $nav-height-about;
        @media (max-width: 576px) {
            padding-top: $nav-height-about-mobile;
        }
    }
    .nfpw-content {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        .nfpw-wrap {
            align-items: center;
            background: linear-gradient(180deg,
                    rgba(var(--mo-color-bg2-rgb), 0.8) 0,
                    rgba(var(--mo-color-bg2-rgb), 0.5) 40%,
                    rgba(var(--mo-color-bg2-rgb), 0.5) 70%,
                    rgba(var(--mo-color-bg2-rgb), 0.8));
            color: var(--mo-color-text1);
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 20px;
            width: 100%;
            .nfpw-text1 {
                font-size: 100px;
                font-weight: bold;
            }
            .nfpw-text2 {
                font-size: 24px;
                padding-bottom: 30px;
                text-align: center;
            }
        }
    }
}
