.pick-an-offer-block {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto;
    padding: 40px;
    .paob-block {
        background-color: var(--mo-color-bg1);
        border-bottom: 27px solid var(--mo-color-mo-color2);
        border-radius: 14px;
        box-shadow: 0 0.0625rem 0.1875rem hsla(239, 34%, 27%, 0.15);
        .paob-navsteps {
            border-radius: 14px 14px 0 0;
            overflow: hidden;
            display: flex;
            .paob-navstep {
                align-items: center;
                background-color: var(--mo-color-mo-color2);
                border-right: 1px solid white;
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 20px 10px;
                width: 25%;
                &.current {
                    pointer-events: none;
                }
                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                &:last-child {
                    border-right: none;
                }
                .paob-navtext {
                    font-weight: bold;
                    text-align: center;
                }
                .paob-navtextsub {
                    padding-top: 5px;
                    text-align: center;
                }
            }
        }
        .paob-step2 {
            padding: 20px;
        }
        .paob-step3 {
            padding: 20px;
            .paob-variant-helper {
                align-items: center;
                border-bottom: 1px solid #e6e6e6;
                display: flex;
                padding: 10px 0;
                &:last-child {
                    border: none;
                }
                .paob-vh-name {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding-right: 20px;
                    .paob-vh-name-decription {
                        font-size: 14px;
                    }
                }
                .paob-vh-variant-price-wrap {
                    align-items: center;
                    display: flex;
                    .paob-vh-variant-price {
                        font-size: 16px;
                    }
                    .paob-vh-input-wrap {
                        padding-left: 20px;
                        input {
                            background: transparent;
                            border: 1px solid #e6e6e6;
                            max-width: 100%;
                            outline: none;
                            text-align: center;
                            width: 70px;
                        }
                    }
                }
            }
            .paob-vat {
                font-size: 14px;
                padding-top: 20px;
            }
        }
        .paob-step4 {
            align-items: center;
            display: flex;
            justify-content: space-around;
            padding: 50px;
        }
    }
    .paob-button-next-wrap {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        .paok-button-message {
            background: var(--mo-color-bg1);
            padding: 20px;
            .form-label-wrap {
                color: black;
                font-size: 16px;
                font-weight: bold;
            }
        }
        .paob-button-previous {
            align-items: center;
            color: var(--mo-color-mo-color);
            cursor: pointer;
            display: flex;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.3px;
            margin-right: 15px;
            padding: 10px 25px;
            text-align: center;
            width: auto;
            .paob-icon {
                align-items: center;
                display: flex;
                margin-right: 10px;
            }
        }
        .paob-button-next {
            align-items: center;
            background: var(--mo-color-mo-color);
            border-color: var(--mo-color-mo-color);
            border-radius: 0px;
            border-style: solid;
            border-width: 1px;
            color: rgb(255, 255, 255);
            cursor: pointer;
            display: flex;
            flex-shrink: 0;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.3px;
            padding: 10px 25px;
            text-align: center;
            width: auto;
            &.disabled {
                pointer-events: none;
                opacity: 0.4;
            }
            .paob-icon {
                align-items: center;
                display: flex;
                margin-left: 10px;
            }
        }
    }
}
