.mo-header-web {
    background: var(--mo-color-bg1);
    border-bottom: 1px solid var(--mo-color-ln);
    &.dev-color {
        background-color: darkslategray;
    }
    &.fixed-height {
        max-height: calc(#{$nav-height} + 1px);
    }
    &.forced {
        z-index: 999;
    }
    .ech-main-toolbar {
        align-items: center;
        display: flex;
        height: $nav-height;
        .ech-left-wrap {
            padding: 10px 0;
        }
        .ech-center-wrap {
            align-items: center;
            display: flex;
            padding: 0 20px;
            .ech-searchbar {
                max-width: 350px;
                padding: 0 10px;
                .searchbar-search-icon {
                    display: none;
                    height: 0;
                    width: 0;
                }
                .searchbar-input {
                    background: var(--mo-color-bg2);
                    padding-inline-start: 13px;
                }
            }
        }
        .ech-right-wrap {
            padding: 5px 0;
            .ech-navlink {
                margin: 0 10px;
            }
            .ech-active {
                color: var(--mo-color-mo-color);
            }
            .ech-nav-button {
                margin-right: 10px;
            }
            .ech-link {
                --background-activated: transparent;
                --background: transparent;
                position: relative;
                .ech-icon {
                    align-items: center;
                    color: var(--mo-color-text1);
                    display: flex;
                    font-size: 20px;
                    justify-content: center;
                    padding: 0 10px;
                }
                .ech-profile-thumb {
                    border: solid 1px var(--mo-color-mo-color);
                    border-radius: 50%;
                    height: 27px;
                    width: 27px;
                }
                .ech-badge {
                    align-items: center;
                    background-color: $error;
                    border-radius: 50%;
                    color: var(--mo-color-text1);
                    display: flex;
                    font-size: 12px;
                    height: 20px;
                    justify-content: center;
                    position: absolute;
                    right: 0px;
                    text-align: center;
                    top: 0;
                    width: 20px;
                    z-index: 5;
                    &.thumb {
                        right: -10px;
                    }
                }
                &.active {
                    .ecf-profile-thumb {
                        border: solid 3px var(--mo-color-mo-color) !important;
                    }
                }
            }
        }
    }
}
.mo-logo {
    cursor: pointer;
    height: 24px;
    &.auto {
        margin: auto;
    }
}
