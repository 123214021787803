.list-profile-link-web {
    align-items: center;
    display: flex;
    transition: $mo-ease;
    @media (max-width: 576px) {
        flex-wrap: wrap;
        max-height: 50px;
        overflow: hidden;
    }
    .lplw-profile-name-wrap {
        align-items: center;
        display: flex;
        margin-bottom: 5px;
        .lplw-profile-image {
            height: 28px;
            margin-right: 5px;
            width: 28px;
            &.medium {
                height: 20px;
                width: 20px;
            }
        }
    }
    .lplw-sep {
        margin: 0 5px;
    }
    &.big {
        flex-wrap: wrap;
        @media (max-width: 576px) {
            max-height: initial;
            overflow: initial;
        }
    }
}
