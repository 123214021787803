.detail-component-field-set {
    display: flex;
    .dcfs-fieldsets {
        flex: 1;
        .dcfs-fieldsets-header {
            align-items: center;
            border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
            border-top: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
            display: flex;
            justify-content: space-between;
            padding: 10px;
            .dcfs-wrap-title {
                align-items: center;
                display: flex;
                .dcfs-title {
                    font-weight: bold;
                    text-transform: uppercase;
                }
                .dcfs-title-superuser {
                    font-size: 10px;
                    margin-left: 10px;
                }
            }
        }
    }
}
