.video-content-block-web {
    flex: 1;
    padding-left: 10px;
    height: 200px;
    .project-list-medium-block-web {
        .plmbw-cover {
            height: 100px;
            width: 150px;
            .plmbw-cover-wrap {
                height: 100%;
                width: 100%;
                .plmbw-image {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }
}
