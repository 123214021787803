.profile-detail-list-filter-web {
    background: var(--mo-color-bg1);
    border-bottom: 1px solid var(--mo-color-ln);
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    .mo-select-input-web {
        margin: 0 10px 10px;
        width: 250px;
    }
}
