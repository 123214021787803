.styles-extra-input-old {
    margin-bottom: $form-mb;
    &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
    .main-form-label {
        margin-bottom: 7px;
    }
    .seio-input-item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .seio-input-values {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: space-between;
            padding-right: 20px;
            .mo-select-input-web {
                flex: 1;
                padding-right: 15px;
            }
        }
    }
}
.styles-extra-input-old-color {
    align-items: center;
    display: flex;
    position: relative;
    .seioc-picker {
        color: black; // keep, for 3rd party component
        right: 10px;
        position: absolute;
        top: -42px;
        z-index: 99;
        .seioc-colorbox {
            bottom: 0px;
            left: 0px;
            position: fixed;
            right: 0px;
            top: 0px;
        }
    }
}
