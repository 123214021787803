.event-list-medium-block-web {
    border-bottom: 1px solid var(--mo-color-ln);
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 10px;
    position: relative;
    width: 100%;
    .elmbw-cover {
        width: 200px;
        .elmbw-image {
            width: 100%;
        }
    }
    .elmbw-right {
        flex: 1;
        padding: 0px 10px 10px;
        .elmbw-date {
            color: var(--mo-color-text2);
            padding-bottom: 5px;
        }
        .elmbw-tags {
            .main-chip-list-block {
                margin-bottom: 5px;
                max-height: 21px;
            }
        }
        .elmbw-countries {
            display: flex;
            padding-top: 10px;
            align-items: center;
        }
        .elmbw-actions {
            display: flex;
        }
    }
}
