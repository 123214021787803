.container-mixed-panel-web {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    .cmpw-segment-wrap {
        background: var(--mo-color-bg1);
        border-bottom: 1px solid var(--mo-color-ln);
        padding: 15px;
        .cmpw-segment {
            flex-shrink: 0;
            .cmpw-tab-button {
                --border-color: black;
                .cmpw-tab-label {
                    color: var(--mo-color-text2);
                    font-size: $MainFontSize;
                }
                &.segment-button-checked {
                    background: var(--mo-color-bg1);
                    .cmpw-tab-label {
                        color: var(--mo-color-text1);
                    }
                }
            }
        }
    }
    .cmpw-fields {
        flex: 1;
        padding: 15px;
        .cmpw-content-links {
            align-items: center;
            border-bottom: 1px solid var(--mo-color-ln);
            display: flex;
            justify-content: space-between;
            margin: -5px -15px 15px;
            padding: 0 15px 10px;
            .cmpw-inherit {
                align-items: center;
                display: flex;
                flex-shrink: 0;
                font-size: 13px;
                .cmpw-inherit-text {
                    margin-left: 5px;
                }
            }
            .cmpw-link {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .mo-image-input-web {
            .edc-dropzone-input {
                background: var(--mo-color-bg2);
            }
        }
    }
    .cmpw-footer {
        background: var(--mo-color-bg1);
        border-top: 1px solid var(--mo-color-ln);
        padding: 15px;
    }
}
