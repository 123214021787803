.material-list-big-block {
    padding-bottom: 50px;
    position: relative;
    width: 100%;
    .mlbb-cover {
        border-radius: 7px;
        display: block;
        overflow: hidden;
        .mlbb-image {
            width: 100%;
        }
    }
    .mlbb-bottom {
        padding: 5px 10px 10px;
        .mlbb-bottom-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .mlbb-feed-date {
                color: var(--mo-color-text2);
            }
            .mlbb-actions {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 5px;
            }
        }
        .mlbb-price-wrap {
            padding-top: 10px;
        }
        .mlbb-countries {
            padding-top: 10px;
            .main-chip-list-block {
                display: flex;
            }
        }
    }
}
