.form-separator {
    border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
    border-top: 10px solid rgba(var(--mo-color-text1-rgb), 0.1);
    margin: 30px 0 20px;
    padding: 10px 0;
    p {
        font-weight: bold;
        text-transform: uppercase;
    }
}
