.ratio-zarming-helper {
    flex-shrink: 0;
    &.is-zarma {
        align-items: center;
        background: var(--mo-color-bg2);
        border: 1px solid var(--mo-color-ln);
        display: flex;
        justify-content: center;
        .rzh-image-zarma-name {
            color: var(--mo-color-text1);
            text-transform: uppercase;
        }
    }
    &.no-height {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 2;
        .rzhnh-image-wrap {
            align-items: center;
            background: var(--mo-color-bg2);
            display: flex;
            justify-content: center;
            overflow: hidden;
            padding-bottom: 100%; // To keep aspect ratio
            position: relative; // To keep aspect ratio
            width: 100%; // To keep aspect ratio
            .rzhnh-image {
                align-items: center;
                display: flex;
                height: 100%; // To keep aspect ratio
                justify-content: center;
                position: absolute; // To keep aspect ratio
                top: 0; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .rzhnh-image-zarma-name {
                    color: var(--mo-color-text1);
                    text-transform: uppercase;
                }
            }
        }
    }
}
