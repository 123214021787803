.portfolio-template-list-block-web {
    color: var(--mo-color-text1);
    display: block;
    position: relative;
    width: 100%;
    .ptlbw-cover {
        cursor: pointer;
        display: block;
        position: relative;
        .ptlbw-image-wrap {
            align-items: center;
            display: flex;
            justify-content: center;
            padding-bottom: 112%; // To keep aspect ratio
            position: relative; // To keep aspect ratio
            width: 100%; // To keep aspect ratio
            .ptlbw-image {
                height: 100%; // To keep aspect ratio
                object-fit: cover;
                object-position: center;
                position: absolute; // To keep aspect ratio
                top: 0; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
            }
        }
    }
    .ptlbw-footer {
        align-items: flex-end;
        bottom: 0;
        display: flex;
        height: 100%;
        left: 0;
        padding: 20px;
        position: absolute;
        transition: $mo-ease;
        width: 100%;
        &:hover {
            background: rgba(var(--mo-color-bg2-rgb), 0.7);
        }
    }
}
