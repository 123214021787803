.product-list-big-block {
    padding-bottom: 50px;
    position: relative;
    width: 100%;
    .prlbb-cover {
        border-radius: 7px;
        display: block;
        overflow: hidden;
        .prlbb-image {
            width: 100%;
        }
    }
    .prlbb-bottom {
        padding: 5px 10px 10px;
        .prlbb-bottom-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .prlbb-feed-date {
                color: var(--mo-color-text2);
            }
            .prlbb-actions {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 5px;
            }
        }
        .prlbb-price-wrap {
            padding-top: 10px;
        }
    }
}
