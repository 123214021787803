.credit-button {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .crb-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        span {
            color: var(--mo-color-mo-color);
            display: block;
            margin-right: 15px;
            transition: $mo-ease;
        }
    }
    .crb-status-text {
        display: block;
        font-size: 14px;
        color: var(--mo-color-text2);
        padding-right: 10px;
    }
    .mo-new-icon-ellipsis-h-solid {
        display: flex;
    }
}
