.profile-detail-web {
    .pdw-content {
        height: $nav-height-vh;
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        &.bottom {
            height: $nav-height-vh3;
        }
        .pdw-left {
            flex: 1;
            height: $nav-height-vh;
            position: relative;
            &.bottom {
                height: $nav-height-vh3;
            }
            .pdw-header-left-wrap {
                border-bottom: 1px solid var(--mo-color-ln);
                background: var(--mo-color-bg1);
                display: flex;
                justify-content: space-between;
                padding: 10px;
                position: sticky;
                top: 0;
                z-index: 4;
                .pdw-header-menu-wrap {
                    align-items: center;
                    display: flex;
                }
            }
            .pdw-list-wrap {
                padding: 10px 40px;
            }
        }
        .pdw-right {
            @extend .mo-hidden-vertical-scrollbar;
            background: var(--mo-color-bg1);
            height: $nav-height-vh;
            width: 350px;
            &.bottom {
                height: $nav-height-vh3;
            }
        }
    }
    .pdw-list {
        padding: 5px 0;
    }
}
.pdw-select {
    .pdw-select-option {
        text-transform: capitalize;
    }
}
