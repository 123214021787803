.profile-list-medium-block-web {
    margin-bottom: 30px;
    padding: 0 10px;
    position: relative;
    width: 100%;
    .pflmb-wrap {
        display: flex;
        position: relative;
        .pflmb-profile-pic {
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;
            width: 80px;
            .pflmb-image-link {
                display: block;
                padding-bottom: 100%;
                position: relative;
                width: 100%;
                .pflmb-image {
                    height: 100%;
                    position: absolute;
                }
            }
        }
        .pflmb-right {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 80px);
            .pflmb-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 10px 0 13px;
                .pflmb-chips {
                    margin-bottom: 10px;
                    max-height: 22px;
                    overflow: hidden;
                }
                .pflmb-country {
                    display: flex;
                    flex-wrap: wrap;
                    height: 18px;
                    overflow: hidden;
                }
            }
            .pflmb-action {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}
