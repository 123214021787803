.main-form-container-mobile {
    .mfcm-form-content {
        .mfcm-form-panels {
            background: var(--mo-color-bg1);
            @media (min-width: 768px) {
                width: 540px;
                margin: auto !important;
            }
            padding: 15px;
            &.inline,
            &.menu {
                padding: 15px 0;
            }
            &.menu {
                .mfcm-menu-label {
                    margin: 15px 8px 15px 0;
                }
            }
        }
        .mfcm-content-header {
            padding: 20px 0 0;
            --border-color: black;
        }
    }
    .mfcm-tab-button {
        --indicator-color: #8ea1ff;
        --border-color: black;
        padding: 2px 0;
        .mfcm-tab-label {
            color: var(--mo-color-text2);
            font-size: $MainFontSize;
        }
        &.segment-button-checked {
            background: var(--mo-color-bg1);
            .mfcm-tab-label {
                color: black;
            }
        }
    }
    .mfcm-footer {
        background: var(--mo-color-bg1);
        border-top: 1px solid var(--mo-color-ln);
        display: flex;
        padding: 10px;
        .mfcm-footer-button {
            width: 100%;
        }
    }
}
