.press-list-medium-block-web {
    display: flex;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    .plmbw-cover {
        width: 200px;
        position: relative;
        .plmbw-cover-wrap {
            display: block;
            cursor: pointer;
            z-index: 2;
            .plmbw-image {
                width: 100%;
            }
            .plmbw-extra-info {
                background-color: rgba(var(--mo-color-bg2-rgb), 0.7);
                bottom: 0;
                color: rgba(var(--mo-color-text1-rgb), 0.5);
                margin-bottom: 0;
                padding: 0 5px;
                position: absolute;
                right: 0;
                text-transform: lowercase;
                z-index: 3;
            }
        }
        .plmbw-play-btn {
            bottom: -3px;
            left: -10px;
            position: absolute;
            z-index: 4;
            .mo-new-icon-play-circle-regular {
                font-size: 20px;
            }
        }
    }
    .plmbw-info {
        flex: 1;
        padding: 0px 5px 0 10px;
        .plmbw-date-wrap {
            align-items: center;
            display: flex;
            margin-bottom: 5px;
            .plmbw-date {
                color: var(--mo-color-text2);
                display: block;
                flex-shrink: 0;
                font-size: 13px;
                margin-right: 5px;
                width: 100px;
            }
            .plmbw-tags {
                .main-chip-list-block {
                    .main-hashtag {
                        font-size: 13px;
                    }
                }
            }
        }
        .plmbw-description {
            -webkit-line-clamp: 3;
            color: var(--mo-color-text2);
            cursor: pointer;
            margin-bottom: 10px;
            white-space: initial;
        }
        .plmbw-extra {
            align-items: center;
            color: var(--mo-color-text2);
            display: flex;
            font-size: 14px;
            padding-top: 5px;
            .plmbw-extra-span {
                margin-right: 10px;
            }
        }
    }
    .plmbw-actions {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
    }
}
