.table-header {
    background: var(--mo-color-bg2);
    position: sticky;
    top: 0;
    z-index: 2;
    th {
        border-bottom: 3px solid var(--mo-color-ln);
        border-left: 1px solid var(--mo-color-ln);
        border-right: 1px solid var(--mo-color-ln);
        font-size: 13px;
        white-space: pre-line;
        word-break: normal;
        &:first-child {
            border-left: 3px solid var(--mo-color-ln);
        }
        &:last-child {
            border-right: 3px solid var(--mo-color-ln);
        }
        &.th-column {
            &.field-action {
                width: 80px;
                .th-div {
                    &.checkbox {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        padding: 0 5px;
                        input {
                            transform: scale(1.2);
                            margin-right: 10px;
                        }
                    }
                }
            }
            &.field-error {
                color: $error;
            }
            .th-div-wrap {
                align-items: center;
                display: flex;
                justify-content: space-between;
                .th-div {
                    padding: 5px 7px;
                    text-transform: capitalize;
                    text-align: left;
                }
                .th-filter-button {
                    color: var(--mo-color-text2);
                    font-size: 13px;
                    margin-left: 5px;
                }
                .th-filter-div {
                    .main-icon {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
}
