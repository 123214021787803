.detail-component {
    background-color: var(--mo-color-bg2);
    display: flex;
    flex-direction: column;
    height: $nav-height-vh2;
    padding: 0 20px 20px;
    position: relative;
    &.in-modal {
        min-height: calc(100vh - 30px - 42px);
        .dc-content {
            height: calc(100vh - #{$nav-height} - #{$nav-list-height} - 35px);
        }
    }
    .dc-tabs {
        align-items: center;
        background-color: var(--mo-color-bg2);
        border-bottom: 3px solid rgba(var(--mo-color-text1-rgb), 0.1);
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 10;
        .dc-buttons {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            padding: 15px 0 15px;
            .button-web {
                text-transform: capitalize;
                margin: 0 10px;
                min-width: 0;
                padding: 0;
            }
            .dc-separator {
                font-size: 13px;
                color: var(--mo-color-text2);
            }
        }
        .dc-active {
            font-weight: bold;
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }
    .dc-content {
        display: flex;
        flex-direction: column;
        height: calc(100vh - #{$nav-height} - #{$nav-list-height} - 61px);
        &.dc-justinline {
            .list-content {
                height: 100%;
            }
        }
        .dc-fieldset {
            background-color: var(--mo-color-bg2);
            padding: 20px 0;
        }
        .dc-panel-description-staff {
            font-size: 12px;
            padding: 10px;
        }
    }
}
