.portfolio-form-container-web {
    .pfcw-header {
        background: var(--mo-color-bg1);
        border-bottom: 1px solid var(--mo-color-ln);
        &.dev-color {
            background-color: darkslategray;
        }
        .pfcw-toolbar {
            .pfcw-nav-mode-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
            }
            .pfcw-button {
                justify-content: flex-start;
                padding-right: 0;
                margin-right: 12px;
                max-width: 200px;
                overflow: hidden;
                text-align: left;
                white-space: nowrap;
            }
            .pfcw-device {
                align-items: center;
                display: flex;
                font-size: 27px;
                justify-content: center;
                margin-left: 20px;
                .icon {
                    color: var(--mo-color-text1);
                    cursor: pointer;
                    display: flex;
                    margin: 0 20px;
                    &.active {
                        color: var(--mo-color-mo-color);
                    }
                }
            }
        }
    }
    .pfcw-form-content {
        .pfcw-form-panels-wrap {
            display: flex;
            height: calc(100vh - #{$nav-children-height});
            .pfcw-form-panels {
                background-color: var(--mo-color-text1);
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                width: 340px;
                .pfcw-form-panels-children {
                    background: var(--mo-color-bg1);
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    height: 100%;
                    &.menu {
                        .pfcw-menu-item::part(native) {
                            background: var(--mo-color-bg1);
                        }
                        .pfcw-menu-label {
                            margin: 15px 8px 15px 0;
                        }
                    }
                    .pfcw-container {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        height: 100%;
                        .pfcw-segment-wrap {
                            background: var(--mo-color-bg1);
                            border-bottom: 1px solid var(--mo-color-ln);
                            padding: 15px;
                            .pfcw-segment {
                                flex-shrink: 0;
                                .pfcw-tab-button {
                                    --border-color: black;
                                    .pfcw-tab-label {
                                        color: var(--mo-color-text2);
                                        font-size: $MainFontSize;
                                    }
                                    &.segment-button-checked {
                                        background: var(--mo-color-bg1);
                                        .pfcw-tab-label {
                                            color: var(--mo-color-text1);
                                        }
                                    }
                                }
                            }
                        }
                        .pfcw-button-staff {
                            border-bottom: 1px solid var(--mo-color-ln);
                            padding: 10px;
                        }
                        .pfcw-content-links {
                            align-items: center;
                            border-bottom: 1px solid var(--mo-color-ln);
                            display: flex;
                            justify-content: space-between;
                            padding: 10px;
                            .pfcw-inherit {
                                align-items: center;
                                display: flex;
                                flex-shrink: 0;
                                font-size: 13px;
                                .pfcw-inherit-text {
                                    margin-left: 5px;
                                }
                            }
                            .pfcw-link {
                                margin-right: 10px;
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                        .pfcw-container-fields {
                            flex: 1;
                            padding: 15px;
                        }
                        .mo-select-input-web-mb {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            .pfcw-form-preview {
                flex: 1;
            }
            &.fullscreen {
                .pfcw-form-panels {
                    margin-left: -340px;
                }
                .pfcw-form-preview {
                    .pfcw-template-preview-wrap {
                        padding: 0;
                        width: 100vw;
                        .pfcw-template-preview {
                            .slug-page-site {
                                .slug-page-content-wrap {
                                    .slug-page-content-div {
                                        height: $portfolio-height-vh;
                                        .slug-page-content {
                                            margin: 0;
                                            padding: 0;
                                            .slug-page-content-list {
                                                .hpc-selection-helper {
                                                    .selection-helper-page-content-web-left,
                                                    .selection-helper-page-content-web-right {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .pfcw-footer2 {
        border-top: 1px solid var(--mo-color-ln);
        padding: 10px;
    }
}
.pfcw-template-preview-wrap {
    position: relative;
    width: 100%;
    .pfcw-template-preview {
        color: black;
        height: 100%;
        position: relative;
        a:hover {
            color: inherit;
        }
        .slug-page-content-wrap {
            color: black;
            .slug-page-content-div {
                height: calc(#{$portfolio-height-vh} - 60px);
                .slug-page-content {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin: 0 30px 0 60px;
                    min-height: auto;
                    .slug-page-content-list {
                        .hpc-selection-helper {
                            flex: 1;
                            position: relative;
                            .selection-helper-page-content-web-left {
                                &:hover {
                                    [id^="content-section-"] {
                                        &:after {
                                            content: "";
                                            height: 100%;
                                            left: 0;
                                            outline-offset: -5px;
                                            outline: var(--mo-color-mo-color) 5px solid;
                                            position: absolute;
                                            top: 0;
                                            width: 100%;
                                            z-index: 99;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .footer-main-block {
                position: relative;
                &:before,
                &:after {
                    background: var(--mo-color-bg2);
                    content: " ";
                    height: 100%;
                    position: absolute;
                    top: 0;
                    z-index: 0;
                }
                &:before {
                    // box-shadow: -11px 0 9px -15px inset;
                    left: -60px;
                    width: 60px;
                }
                &:after {
                    // box-shadow: 11px 0 9px -15px inset;
                    right: -30px;
                    width: 30px;
                }
            }
        }
    }
    &.is-mobile {
        margin: 30px 30px 30px 0;
        width: calc(375px + 60px + 30px);
    }
    &.is-tablet {
        margin: 30px 30px 30px 0;
        width: calc(768px + 60px + 30px);
    }
    &.is-web {
        padding: 30px 0;
        width: calc(100vw - 340px);
    }
}
.is-edit-hovered {
    &:after {
        content: "";
        height: 100%;
        left: 0;
        outline-offset: -5px;
        outline: var(--mo-color-mo-color) 5px solid;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 50;
    }
}
