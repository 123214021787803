.event-detail-block-web {
    .edbw-quick-info-wrap {
        color: var(--mo-color-text1);
        padding-bottom: 15px;
        &.flex {
            align-items: center;
            display: flex;
        }
        &:last-child {
            padding-bottom: 0;
        }
        .edbw-icon {
            display: flex;
            margin-right: 10px;
        }
        .edbw-quick-info-text {
            white-space: pre-line;
        }
    }
    .edbw-sold-out {
        color: var(--mo-color-text1);
    }
    .edbw-section-button {
        margin-top: 10px;
    }
}
