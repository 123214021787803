.not-found-component {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1;
    height: 100%;
    position: relative;
    width: 100%;
    .nfc-wrap {
        align-items: center;
        background: linear-gradient(180deg,
                rgba(var(--mo-color-bg2-rgb), 0.8) 0,
                rgba(var(--mo-color-bg2-rgb), 0.5) 40%,
                rgba(var(--mo-color-bg2-rgb), 0.5) 70%,
                rgba(var(--mo-color-bg2-rgb), 0.8));
        color: var(--mo-color-text1);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        padding: 20px;
        position: absolute;
        top: 0;
        width: 100%;
        .nfc-text1 {
            font-size: 100px;
            font-weight: bold;
        }
        .nfc-text2 {
            font-size: 24px;
            padding-bottom: 30px;
            text-align: center;
        }
    }
}
