.styles-extra-input {
    display: flex;
    margin-bottom: $form-mb-new;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    .sei-label-wrap {
        flex-shrink: 0;
        padding-top: 5px;
        width: 150px;
    }
    .sei-input-wrap {
        flex: 1;
        .sei-input-item {
            align-items: center;
            display: flex;
            justify-content: space-between;
            .sei-input-values {
                align-items: center;
                display: flex;
                flex: 1;
                justify-content: space-between;
                padding-right: 20px;
                .mo-select-input-web {
                    flex: 1;
                    padding-right: 15px;
                }
            }
        }
    }
}
.styles-extra-input-color {
    align-items: center;
    display: flex;
    position: relative;
    .seic-picker {
        color: black; // keep, for 3rd party component
        right: 10px;
        position: absolute;
        top: -42px;
        z-index: 99;
        .seic-colorbox {
            bottom: 0px;
            left: 0px;
            position: fixed;
            right: 0px;
            top: 0px;
        }
    }
}
