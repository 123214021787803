.feed-section-detail-web {
    .fsdw-content {
        display: flex;
        position: relative;
        .fsdw-left {
            background: var(--mo-color-bg1);
            border-right: 1px solid var(--mo-color-ln);
            flex-shrink: 0;
            height: $nav-height-vh2;
            padding: 10px;
            width: 240px;
            .fsdw-tab-item {
                --padding-start: 0;
            }
        }
        .fsdw-right {
            padding: 10px 0;
            width: calc(100vw - 240px);
            .fsdw-right-wrap {
                height: calc(#{$nav-height-vh2} - 20px);
                position: relative;
                .fsdw-reorder-header {
                    background: black;
                    display: flex;
                    justify-content: flex-end;
                    padding: 0px 10px 10px;
                    position: sticky;
                    top: 0;
                    z-index: 10;
                }
            }
        }
    }
}
