.unlisted-button-web {
    background-color: rgba(var(--mo-color-mo-color), 0.9);
    border-radius: 2px;
    color: black;
    cursor: pointer;
    right: 0;
    padding: 5px;
    position: absolute;
    top: 0;
    z-index: 3;
    &.detail {
        border-radius: 23.5px;
        margin-right: 20px;
        padding: 5px 15px;
        position: relative;
    }
}
