.iframe-content-block-web {
    background: var(--mo-color-bg1);
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
    .icbw-banner {
        flex-shrink: 0;
        height: 400px;
        position: relative;
        width: 100%;
        .icbw-img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    .icbw-description-wrap {
        padding: 20px;
        .icbw-name {
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 20px;
            text-align: center;
        }
        .icbw-description {
            margin: auto;
            max-width: 700px;
        }
    }
    .icbw-button-wrap {
        display: flex;
        justify-content: center;
        padding: 20px;
    }
}
