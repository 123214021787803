.product-list-medium-block {
    display: flex;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    .prlmb-cover {
        width: 100px;
        .prlmb-cover-wrap {
            position: relative;
            z-index: 2;
            .prlmb-image-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
                padding-bottom: 100%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .prlmb-image {
                    border-radius: 7px;
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
            }
        }
    }
    .prlmb-info {
        flex: 1;
        padding: 0px 5px 0 10px;
        .prlmb-tags {
            .main-chip-list-block {
                height: 21px;
                margin-bottom: 5px;
            }
        }
        .prlmb-price-wrap {
            padding-top: 5px;
        }
    }
    .prlmb-actions {
        display: flex;
        flex-direction: column;
        width: 30px;
    }
}
