.detail-page-header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    .dp-header-background-image-overlay {
        background-color: rgba(var(--mo-color-black-rgb), 0.82);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
