.detail-brevo {
    padding: 10px;
    .db-item {
        align-items: center;
        display: flex;
        .checkbox-input {
            width: auto;
        }
        .db-item-name {
            margin-left: 10px;
        }
    }
}
