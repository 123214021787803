.event-detail-block {
    position: relative;
    .edb-cover-image {
        width: 100%;
    }
    .edb-cover-credit-wrap {
        padding: 5px;
        text-align: center;
        .edb-cover-credit {
            color: var(--mo-color-text2);
        }
    }
    .edb-event-info {
        padding: 16px 10px 10px;
        .edb-button {
            display: flex;
            justify-content: center;
            padding-bottom: 15px;
        }
        .edb-name {
            font-weight: 600;
            padding-bottom: 15px;
        }
        .edb-tags {
            border-bottom: solid 1px var(--mo-color-ln);
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        .edb-quick-info-div {
            border-bottom: solid 1px var(--mo-color-ln);
            padding-bottom: 10px;
            margin-bottom: 10px;
            .edb-quick-info-wrap {
                padding-bottom: 15px;
                .edb-icon {
                    margin-right: 10px;
                }
            }
        }
        .edb-links-wrap {
            .edb-sold-out {
                color: var(--mo-color-mo-color);
            }
        }
        .edb-section {
            border-bottom: solid 1px var(--mo-color-ln);
            padding-bottom: 10px;
            margin-bottom: 10px;
            .edb-section-title {
                border-bottom: solid 1px var(--mo-color-ln);
                font-weight: 600;
                margin-bottom: 10px;
                padding-bottom: 10px;
            }
            .edb-description {
                color: var(--mo-color-text2);
            }
            .edb-section-content {
                margin-bottom: 20px;
                .team-list-medium-block {
                    margin-bottom: 20px;
                    padding: 0;
                }
                .edb-image {
                    margin-bottom: 10px;
                }
                .edb-section-button {
                    margin: 20px auto 0;
                    max-width: 70%;
                }
            }
        }
    }
}
