.team-list-small-block {
    border: 1px solid transparent;
    display: inline-block;
    margin-bottom: 30px;
    position: relative;
    width: 50%;
    .tlsbm-wrap {
        position: relative;
        .tlsbm-profile-pic {
            display: block;
            padding: 5px;
            position: relative;
            .tlsbm-image-link {
                padding-bottom: 100%;
                position: relative;
                width: 100%;
                .tlsbm-image {
                    position: absolute;
                    top: 0;
                }
            }
        }
        .tlsbm-info {
            padding: 0 10px 0;
            text-align: center;
            width: 100%;
            .tlsbm-chips {
                max-height: 22px;
                margin-bottom: 10px;
                overflow: hidden;
            }
            .tlsbm-country {
                flex-wrap: wrap;
                height: 18px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                .tlsbm-country-flag {
                    flex: 0 0 24px;
                    height: 18px;
                }
                .tlsbm-city-name {
                    height: 18px;
                    overflow: hidden;
                }
            }
        }
        .tlsbm-action {
            padding: 10px;
            text-align: center;
        }
    }
}
