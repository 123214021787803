.project-list-medium-block {
    display: flex;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    .plmb-cover {
        height: 100px;
        width: 100px;
        position: relative;
        .plmb-cover-wrap {
            display: block;
            z-index: 2;
            .plmb-image-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
                padding-bottom: 100%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .plmb-image {
                    border-radius: 7px;
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
            }
            .plmb-extra-info {
                background-color: rgba(var(--mo-color-bg2-rgb), 0.7);
                bottom: 0;
                color: var(--mo-color-text1);
                margin-bottom: 0;
                padding: 0 5px;
                position: absolute;
                right: 0;
                text-transform: lowercase;
                z-index: 3;
            }
        }
        .plmb-play-btn {
            bottom: -3px;
            left: -10px;
            position: absolute;
            z-index: 4;
            .mo-new-icon-play-circle-regular {
                font-size: 20px;
            }
        }
    }
    .plmb-info {
        flex: 1;
        padding: 0px 5px 0 10px;
        .plmb-tags {
            .main-chip-list-block {
                height: 21px;
                margin-bottom: 5px;
            }
        }
        .plmb-credit-detail {
            .plmb-roles {
                height: 24px;
                overflow: hidden;
            }
        }
    }
    .plmb-actions {
        display: flex;
        flex-direction: column;
        width: 30px;
    }
}
