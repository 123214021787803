.block-template-detail-block-web {
    display: flex;
    height: $nav-height-vh2;
    position: relative;
    width: 100%;
    &.edit {
        height: $nav-height-vh;
    }
    .btdbw-left {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
        width: calc(100vw - 370px);
        .mixed-content-block-web {
            background: var(--mo-color-text1);
        }
    }
    .btdbw-right {
        border-left: 1px solid var(--mo-color-ln);
        flex-shrink: 0;
        padding: 0 12px;
        position: relative;
        width: 370px;
    }
}
