.mo-image-input {
    margin-bottom: $form-mb;
    .main-form-label {
        margin-bottom: 7px;
    }
    .edc-dropzone-input {
        align-items: center;
        background: var(--mo-color-bg2);
        display: flex;
        justify-content: center;
        .edc-input {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 50vh;
            justify-content: center;
            padding: 20px;
            text-align: center;
            .icim-dropzone-helper-text {
                position: relative;
                z-index: 2;
            }
            .m-icon {
                font-size: 23px;
                height: 24px;
                position: relative;
                z-index: 2;
            }
        }
        .edc-preview-img {
            width: 100%;
        }
    }
    .dropzone {
        align-items: center;
        background: var(--mo-color-bg2);
        border: none;
        border-radius: $form-br;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        .dz-message {
            margin: 0;
        }
        .dz-preview .dz-image {
            border-radius: 0px;
        }
    }
}
.mo-image-input-web {
    margin-bottom: $form-mb;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
        .edc-dropzone-input {
            pointer-events: none;
        }
    }
    .main-form-label {
        margin-bottom: 7px;
    }
    .edc-dropzone-input {
        align-items: center;
        background: var(--mo-color-bg2);
        cursor: pointer;
        display: flex;
        justify-content: center;
        outline: none;
        .edc-input {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 200px;
            justify-content: center;
            padding: 20px;
            text-align: center;
            .mo-dropzone-helper-text {
                position: relative;
                z-index: 2;
            }
            .m-icon {
                font-size: 23px;
                height: 24px;
                position: relative;
                z-index: 2;
            }
        }
        .edc-preview-img {
            cursor: pointer;
            min-height: 200px;
            object-fit: contain;
            width: 100%;
        }
    }
    .dropzone {
        align-items: center;
        background: var(--mo-color-bg2);
        border: none;
        border-radius: $form-br;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        .dz-message {
            margin: 0;
        }
        .dz-preview .dz-image {
            border-radius: 0px;
        }
    }
    .eici-buttons {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        .eici-dropzone-input {
            background: none;
        }
        .eici-buttons-div {
            padding: 5px;
            width: 50%;
            .eici-button {
                width: 100%;
            }
        }
    }
}
