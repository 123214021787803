.shipping-modal-form-web-modal {
    .wrapper {
        height: 95vh;
        width: 95vw;
    }
    .smfwm-content {
        display: flex;
        .smfwm-left {
            border-right: 1px solid var(--mo-color-ln);
            padding: 20px;
            width: 300px;
            .smfwm-agent-wrap {
                .smfwm-agent {
                    border-bottom: 1px solid var(--mo-color-ln);
                    // cursor: pointer;
                    display: block;
                    padding: 5px;
                    transition: $mo-ease;
                    // &:hover {
                    //     background: var(--mo-color-bg1);
                    // }
                }
                .smfwm-service-wrap {
                    padding-left: 10px;
                    .smfwm-service {
                        border-bottom: 1px solid var(--mo-color-ln);
                        // cursor: pointer;
                        display: block;
                        padding: 5px;
                        transition: $mo-ease;
                        // &:hover {
                        //     background: var(--mo-color-bg1);
                        // }
                    }
                    .smfwm-zone-wrap {
                        padding-left: 10px;
                        .smfwm-zone {
                            border-bottom: 1px solid var(--mo-color-ln);
                            cursor: pointer;
                            display: block;
                            padding: 5px;
                            transition: $mo-ease;
                            &:hover {
                                background: var(--mo-color-bg1);
                            }
                        }
                    }
                }
            }
        }
        .smfwm-middle {
            border-right: 1px solid var(--mo-color-ln);
            padding: 20px;
            .smfwm-zone-name {
                font-weight: bold;
                margin-bottom: 10px;
            }
            .smfwm-zone-countries {
                .main-country-flag-web {
                    height: auto;
                }
            }
        }
        .smfwm-right {
            flex: 1;
            padding: 20px;
            .smfwm-service-name {
                font-weight: bold;
                margin-bottom: 10px;
                margin-left: 5px;
            }
        }
    }
}
