.file-input {
    .fi-input-div {
        border: 1px solid var(--mo-color-ln);
        width: 350px;
        &.full {
            width: 100%;
        }
        .fi-image-add-wrap {
            align-items: center;
            background: var(--mo-color-bg2);
            cursor: pointer;
            display: flex;
            justify-content: center;
            .fi-input {
                align-items: center;
                display: flex;
                flex-direction: column;
                height: 200px;
                justify-content: center;
                padding: 20px;
                text-align: center;
                .mo-dropzone-helper-text {
                    position: relative;
                    z-index: 2;
                }
                .m-icon {
                    font-size: 23px;
                    height: 24px;
                    position: relative;
                    z-index: 2;
                }
                &.error {
                    color: red;
                }
            }
            .fi-preview-img {
                cursor: pointer;
                height: 200px;
                object-fit: contain;
                width: 100%;
            }
        }
        .fi-buttons-wrap {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            width: 100%;
            .fi-dropzone-input {
                background: none;
            }
            .fi-buttons-div {
                padding: 5px;
                width: 50%;
                .fi-button {
                    width: 100%;
                }
            }
            &.clear {
                .fi-buttons-div {
                    width: calc(100% / 3);
                }
            }
        }
    }
    .dropzone {
        align-items: center;
        background: var(--mo-color-bg2);
        border: none;
        border-radius: $form-br;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        .dz-message {
            margin: 0;
        }
        .dz-preview .dz-image {
            border-radius: 0px;
        }
    }
}
