.co-search-input {
    align-items: center;
    background: var(--mo-color-bg2);
    border-radius: $form-br;
    display: flex;
    padding: 0 10px;
    &.bg1 {
        background: var(--mo-color-bg1);
        .co-search-input-input {
            background: var(--mo-color-bg1);
        }
    }
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    i {
        align-items: center;
        color: #757575;
        display: flex;
    }
    .co-search-input-input {
        background: var(--mo-color-bg2);
        border: none;
        flex: 1;
        min-height: 39px;
        outline: none;
        padding: 0 13px;
        &::placeholder {
            color: #757575;
        }
    }
}
