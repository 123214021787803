.admin-wrapper {
    .aw-content {
        height: $nav-height-vh;
        display: flex;
        position: relative;
        .aw-left {
            background: var(--mo-color-bg1);
            flex-shrink: 0;
            height: $nav-height-vh;
            padding: 0 10px;
            width: 240px;
            .adcmt-tab-item {
                align-items: center;
                border-bottom: 1px solid var(--mo-color-ln);
                color: var(--mo-color-text2);
                cursor: pointer;
                display: flex;
                flex: 1;
                justify-content: space-between;
                padding: 7px 10px 7px 0;
                &:hover {
                    background: var(--mo-color-ln);
                    color: var(--mo-color-mo-color);
                }
                &.children {
                    padding-left: 20px;
                }
                &.separator {
                    color: var(--mo-color-text2);
                    font-weight: bold;
                    pointer-events: none;
                    text-transform: uppercase;
                }
            }
        }
        .aw-right {
            flex: 1;
            height: $nav-height-vh;
            width: calc(100vw - 240px);
        }
    }
}
