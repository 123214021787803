.profile-detail-about {
    .pdab-image-block {
        .pdab-image-block-profile {
            border-radius: 5%;
            height: 250px;
            margin: 20px auto 0;
            object-fit: cover;
            overflow: hidden;
            width: 250px;
        }
        .pib-profile-image-credit-wrap {
            padding: 10px;
            text-align: center;
            .pib-profile-image-credit {
                font-size: 14px;
            }
        }
        .pdab-image-block-about {
            padding: 5px;
        }
        .pdab-image-block-serie {
            padding: 10px 2px 0;
        }
        .pdab-section-button {
            margin: 20px auto 0;
            max-width: 70%;
        }
    }
    .pdab-top-info {
        padding: 20px 10px 0;
        text-align: center;
        .pdab-name {
            font-size: 30px;
            font-weight: 600;
            padding-top: 20px 0 11px;
        }
        .pdab-main-categories {
            justify-content: center;
            padding-bottom: 15px;
        }
        .pdab-metiers {
            justify-content: center;
            padding-bottom: 12px;
        }
        .pdab-description {
            padding-bottom: 15px;
        }
        .pdab-country {
            justify-content: center;
            align-items: center;
            display: flex;
            padding-bottom: 15px;
            .main-hashtag {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .pdab-other-profiles {
            align-items: center;
            display: flex;
            flex-direction: column;
            .pdab-op-wrap {
                padding-bottom: 15px;
                .pdab-op-text {
                    font-style: italic;
                    margin-bottom: 10px;
                }
            }
        }
        .pdab-website {
            color: var(--mo-color-text2);
            display: block;
            padding-bottom: 10px;
        }
        .pdab-social-wrap {
            display: flex;
            margin-top: 10px;
            justify-content: center;
            .pdab-social {
                margin-right: 19px;
                .pdab-social-image {
                    height: 16px;
                    width: 16px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .pdab-alfa1 {
        max-width: 500px;
        padding: 20px;
        width: 100%;
        .pdab-bravo2 {
            border-bottom: solid 1px var(--mo-color-ln);
            margin: auto;
            max-width: 500px;
            padding-bottom: 20px;
            text-align: center;
            .pdab-charlie1 {
                font-weight: 600;
            }
            .pdab-charlie2 {
                font-weight: 600;
                margin-top: 15px;
            }
            .pdab-charlie3 {
                display: flex;
                justify-content: center;
                .pdab-delta1 {
                    margin: 10px;
                    .pdab-echo2 {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }
        .pdab-bravo3 {
            border-bottom: solid 1px var(--mo-color-ln);
            margin: 10px auto 0;
            max-width: 500px;
            padding-bottom: 50px;
            .pdab-charlie1 {
                margin-bottom: 20px;
                .pdab-delta1 {
                    padding: 15px 0;
                    display: flex;
                    .pdab-echo1 {
                        margin-right: 20px;
                    }
                    .pdab-echo2 {
                        margin-right: 10px;
                        margin-right: 20px;
                    }
                }
            }
        }
        .pdab-bravo4 {
            margin: auto;
            max-width: 500px;
            .pdab-charlie1 {
                border-bottom: solid 1px var(--mo-color-ln);
                display: flex;
                padding: 10px 0;
                .pdab-title {
                    font-weight: 600;
                    margin: 0;
                    width: 50%;
                }
                .pdab-value {
                    text-align: right;
                    width: 50%;
                    span,
                    .pdab-description {
                        display: block;
                        text-align: right;
                    }
                }
            }
            .pdab-charlie2 {
                font-size: 24px;
                text-align: center;
                padding: 40px 0 25px;
                border-bottom: solid 1px var(--mo-color-ln);
                p {
                    font-weight: 600;
                }
            }
        }
    }
    .pdab-alfa2 {
        text-align: center;
        width: 100%;
        .pdab-charlie2 {
            margin: 0 auto;
            max-width: 100%;
            padding: 10px;
        }
    }
}
