.product-detail-package-block-web {
    display: flex;
    height: $nav-height-vh2;
    position: relative;
    width: 100%;
    .pdpbw-left {
        display: block;
        flex: 1;
        height: $nav-height-vh2;
        padding: 10px;
        .pdpbw-cover-image {
            cursor: pointer;
            margin: 0 auto;
            max-height: 75%;
            max-width: 100%;
        }
        .pdpbw-options {
            margin-top: 25px;
            padding: 0 50px;
            .pdpbw-options-wrap {
                border-top: 1px solid var(--mo-color-ln);
                padding: 25px 0;
                .pdpbw-options-name {
                    font-size: 19px;
                    padding: 0 7px 7px;
                }
                .pdpbw-options-items {
                    display: flex;
                    flex-wrap: wrap;
                    .pdpbw-options-item-wrap {
                        flex-basis: 25%;
                        padding: 7px;
                        .pdpbw-options-item-button {
                            padding: 10px;
                            text-align: center;
                        }
                        &.active {
                            .ratio-helper-web {
                                border: 2px solid var(--mo-color-mo-color);
                            }
                        }
                    }
                }
            }
        }
    }
    .pdpbw-right {
        border-left: 1px solid var(--mo-color-ln);
        flex-shrink: 0;
        height: $nav-height-vh2;
        width: 370px;
        .pdpbw-product-info {
            padding: 0 10px 10px;
            .pdpbw-main-info {
                border-bottom: solid 1px var(--mo-color-ln);
                padding-bottom: 10px;
                margin-bottom: 10px;
                .pdpbw-name {
                    font-weight: 600;
                    padding: 12px 0 5px;
                }
                .pdpbw-tags {
                    border-bottom: solid 1px var(--mo-color-ln);
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }
                .pdpbw-label {
                    margin-top: 5px;
                }
                .pdpbw-countries {
                    align-items: center;
                    display: flex;
                    padding-bottom: 10px;
                }
                .pdpbw-phone {
                    color: var(--mo-color-text2);
                }
            }
            .pdpbw-section {
                border-bottom: solid 1px var(--mo-color-ln);
                padding-bottom: 10px;
                margin-bottom: 10px;
                .pdpbw-section-title {
                    border-bottom: solid 1px var(--mo-color-ln);
                    font-weight: 600;
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                }
                .pdpbw-technical-info {
                    padding-top: 10px;
                    .pdpbw-dimension,
                    .pdpbw-weight {
                        font-size: 12px;
                    }
                }
                .pdpbw-description {
                    color: var(--mo-color-text2);
                    white-space: pre-line;
                    a {
                        display: none;
                    }
                }
            }
            .pdpbw-section-price {
                align-items: center;
                border-bottom: solid 1px var(--mo-color-ln);
                display: flex;
                margin-bottom: 10px;
                padding-bottom: 10px;
                .pdpbw-variants {
                    padding-right: 10px;
                    width: 150px;
                    .mo-select-input-web {
                        margin-bottom: 0;
                    }
                }
                .pdpbw-qty-wrap {
                    padding-right: 10px;
                    width: 75px;
                    .mo-text-input-web {
                        margin-bottom: 0;
                    }
                }
                .pdpbw-button-wrap {
                    flex: 1;
                    .button-web {
                        padding: 12px;
                        width: 100%;
                    }
                }
            }
        }
    }
    &.edit {
        height: $nav-height-vh;
        .pdpbw-left {
            height: $nav-height-vh;
        }
        .pdpbw-right {
            height: $nav-height-vh;
        }
    }
}
