.text-input-mobile {
    margin-bottom: $form-mb;
    .tim-input {
        background: var(--mo-color-bg2);
        border-radius: $form-br;
        &.disabled {
            opacity: 0.4;
        }
    }
    .tibm-loader {
        align-items: center;
        display: flex;
        .tibm-loader-text {
            font-size: 13px;
            margin-right: 15px;
            color: var(--mo-color-text2);
        }
        .tibm-loader-spinner {
            height: 10px;
            width: 10px;
        }
    }
}
.mo-text-input-web {
    margin-bottom: $form-mb;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    &.hidden-input {
        margin-bottom: 10px;
    }
    .mo-text-input-web-input {
        background: var(--mo-color-bg2);
        border-radius: $form-br;
        &.tiw-input-textarea {
            border: none;
            padding: 9px 13px;
            resize: none;
            width: 100%;
            &:focus {
                outline: none;
            }
        }
        &.rich {
            .ck-editor {
                all: initial;
                border-radius: $form-br;
                width: 100%;
                a {
                    color: blue;
                    text-decoration: underline;
                }
                .ck-content {
                    border-bottom-left-radius: $form-br !important;
                    border-bottom-right-radius: $form-br !important;
                    border-bottom: none;
                    border-left: none;
                    border-right: none;
                }
                .ck-sticky-panel__content {
                    border-top-left-radius: $form-br !important;
                    border-top-right-radius: $form-br !important;
                    border: none;
                }
                .ck-toolbar {
                    border-bottom-left-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    border-top-left-radius: $form-br !important;
                    border-top-right-radius: $form-br !important;
                }
                .ck-toolbar__items {
                    flex-wrap: wrap !important;
                }
            }
        }
    }
    .mo-text-input-wrap {
        display: flex;
        .mo-text-input-web-input {
            flex: 1;
        }
        .qiw-inherit {
            align-items: center;
            display: flex;
            flex-shrink: 0;
            font-size: 13px;
            margin-left: 20px;
            .qiw-inherit-text {
                margin-left: 5px;
            }
        }
    }
    .tiw-loader {
        align-items: center;
        display: flex;
        .tiw-loader-text {
            font-size: 13px;
            margin-right: 15px;
            color: var(--mo-color-text2);
        }
        .tiw-loader-spinner {
            height: 10px;
            width: 10px;
        }
    }
    .list-ios,
    .list-md {
        background: transparent;
        border-radius: $form-br;
    }
    .tiw-label {
        text-overflow: initial;
        white-space: initial;
    }
}
.mo-text-input-webnew {
    margin-bottom: $form-mb;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    &.hidden-input {
        margin-bottom: 10px;
    }
    .mo-text-input-web-inputnew {
        background: var(--mo-color-bg2);
        border-radius: $form-br;
        border: none;
        min-height: 39px;
        outline: none;
        padding: 0 13px;
        width: 100%;
        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }
    .mo-text-input-wrap {
        display: flex;
        .mo-text-input-web-input {
            flex: 1;
        }
        .qiw-inherit {
            align-items: center;
            display: flex;
            flex-shrink: 0;
            font-size: 13px;
            margin-left: 20px;
            .qiw-inherit-text {
                margin-left: 5px;
            }
        }
    }
    .tiw-loader {
        align-items: center;
        display: flex;
        .tiw-loader-text {
            font-size: 13px;
            margin-right: 15px;
            color: var(--mo-color-text2);
        }
        .tiw-loader-spinner {
            height: 10px;
            width: 10px;
        }
    }
    .list-ios,
    .list-md {
        background: transparent;
        border-radius: $form-br;
    }
    .tiw-label {
        text-overflow: initial;
        white-space: initial;
    }
}
