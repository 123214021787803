.mo-footer {
    background: rgba(var(--mo-color-bg2-rgb), 0.7);
    .ecf-navigation-footer {
        align-items: center;
        display: flex;
        justify-content: space-around;
        .lf-link {
            --background-activated: transparent;
            --background: transparent;
            position: relative;
            .mo-footer-icon {
                align-items: center;
                color: var(--mo-color-text1);
                display: flex;
                font-size: 20px;
                justify-content: center;
            }
            .ecf-profile-thumb {
                border: solid 1px var(--mo-color-text1) !important;
                height: 27px;
                width: 27px;
            }
            .lf-badge {
                align-items: center;
                background-color: $error;
                border-radius: 50%;
                color: var(--mo-color-text1);
                display: flex;
                font-size: 12px;
                height: 20px;
                justify-content: center;
                position: absolute;
                right: -10px;
                text-align: center;
                top: 0;
                width: 20px;
            }
            &.active {
                .mo-footer-icon {
                    color: var(--mo-color-mo-color);
                }
                .ecf-profile-thumb {
                    border: solid 3px var(--mo-color-mo-color) !important;
                }
            }
            &.ion-activated {
                .mo-footer-icon {
                    color: var(--mo-color-mo-color);
                }
            }
        }
    }
}
