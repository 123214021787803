.add-to-section-form-web {
    .atsfw-helper {
        padding: 20px;
    }
    .atsfw-input-wrap {
        flex: 1;
        .atsfw-input {
            background: var(--mo-color-bg1);
            border-radius: $form-br;
            border: none;
            min-height: 40px;
            outline: none;
            padding: 10px 13px;
            width: 100%;
        }
        .error-helper-web {
            margin: 0;
            padding: 5px 5px 0 5px;
        }
    }
    .atsfw-button {
        margin-left: 10px;
        width: auto !important;
    }
}
