.layout-input-web {
    margin-bottom: 0;
    .liw-content {
        border-radius: $form-br;
        cursor: pointer;
        overflow: hidden;
        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
        .liw-scale-content {
            color: black !important;
            &:hover {
                color: black !important;
            }
            .liw-content-source {
                color: var(--mo-color-text2);
                font-size: 13px;
                margin-left: 10px;
            }
        }
    }
}
.layout-form-modal {
    background: rgba(var(--mo-color-bg2-rgb), 0.85);
    &::part(content) {
        background-color: #f4f4f4;
        border-radius: 0;
        height: 95vh;
        outline: 1px solid var(--mo-color-ln);
        width: 95vw;
    }
}
.liw-right-block-header {
    display: flex;
    justify-content: space-between;
    height: $nav-children-height;
    margin-top: 8px;
    .liw-left-actions {
        align-items: center;
        display: flex;
        padding: 0 20px;
    }
    .liw-right-actions {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        .liw-variation-filters-input {
            margin-right: 10px;
            width: 130px;
            .mo-select-input-web,
            .mo-text-input-web {
                margin-bottom: 0;
                .mo-text-input-web-input {
                    background-color: $white1;
                    color: black;
                    min-height: auto;
                    padding: 5px 10px;
                }
            }
        }
        .liw-close {
            font-size: 30px;
            margin-right: 7px;
        }
        .liw-device {
            align-items: center;
            display: flex;
            font-size: 27px;
            justify-content: center;
            margin-left: 10px;
            .icon {
                color: black;
                cursor: pointer;
                display: flex;
                margin: 0 20px;
                &.active {
                    border-bottom: 2px solid var(--mo-color-mo-color);
                }
            }
        }
    }
}
.layout-form-modal-wrap {
    display: flex;
    height: calc(100vh - 5vh);
    .liw-left-block {
        background: var(--mo-color-text1);
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 370px;
        .liw-left-header {
            .liw-left-header-top {
                align-items: center;
                border-bottom: 1px solid #f4f4f4;
                color: black;
                display: flex;
                justify-content: space-between;
                padding: 12px;
                .liw-left-header-text {
                    &.pointer {
                        cursor: pointer;
                    }
                    .liw-icon-wrap {
                        align-items: center;
                        display: flex;
                        .liw-icon {
                            display: flex;
                            margin-right: 10px;
                        }
                    }
                }
            }
            .liw-left-header-bottom {
                border-bottom: 1px solid #f4f4f4;
                padding: 12px;
            }
        }
        .liw-select-list-container {
            color: black;
            display: flex;
            flex: 1;
            .liw-category-list-wrap {
                flex-basis: 50%;
                flex-shrink: 0;
                height: calc(95vh - 49px - 63px);
                padding: 20px;
                width: 50%;
                .liw-category-list-section {
                    padding-bottom: 10px;
                    .liw-category-list-title {
                        display: block;
                        font-weight: bold;
                        text-transform: uppercase;
                        padding-bottom: 10px;
                        &.pointer {
                            cursor: pointer;
                        }
                        &.active {
                            text-decoration-color: black;
                            text-decoration-line: underline;
                            text-decoration-thickness: 3px;
                            text-underline-offset: 3px;
                        }
                    }
                    .liw-category-name {
                        color: var(--mo-color-text2);
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 500;
                        transition: $mo-ease;
                        &:hover,
                        &.active {
                            color: black;
                            text-decoration: underline;
                        }
                        &.noclick {
                            cursor: initial;
                            color: var(--mo-color-text2);
                            text-decoration: none;
                        }
                    }
                    .liw-category-name-sub {
                        color: var(--mo-color-text2);
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 500;
                        padding-left: 10px;
                        transition: $mo-ease;
                        &:hover,
                        &.active {
                            color: black;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .liw-select-list-wrap {
                flex-basis: 50%;
                flex-shrink: 0;
                height: calc(95vh - 49px - 63px);
                padding: 20px 0;
                position: relative;
                width: 50%;
                .liw-list-block {
                    display: inline-block;
                    padding: 10px;
                    width: 100%;
                    .liw-list-block-image {
                        border: 1px solid #e3e6e8;
                        cursor: pointer;
                        margin: auto;
                        max-width: 100%;
                        object-fit: contain;
                        &.active {
                            outline-offset: -5px;
                            outline: 5px solid var(--mo-color-mo-color);
                        }
                    }
                    &.reorder {
                        display: flex;
                        .liw-list-block-image {
                            width: 135px;
                        }
                    }
                }
                &.bookmark {
                    flex-basis: 100%;
                    width: 100%;
                    .liw-list-block {
                        display: flex;
                        &.reorder {
                            cursor: grab;
                        }
                        .liw-list-block-image {
                            width: 50%;
                        }
                        .liw-bookmark-wrap {
                            padding: 0 10px;
                            width: 50%;
                            .liw-bookmark-text {
                                font-size: 14px;
                            }
                            .liw-bookmark-description {
                                color: var(--mo-color-text2);
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
        .liw-left-footer {
            border-top: 1px solid #e3e6e8;
            display: flex;
            padding: 5px;
            .liw-footer-button {
                margin: 5px;
                width: 50%;
            }
        }
    }
    .liw-right-block {
        background: $white1;
        display: flex;
        flex-direction: column;
        flex: 1;
        width: calc(100% - 370px);
        .liw-block-preview-wrap {
            align-items: center;
            display: flex;
            flex: 1;
            padding: 0 20px;
            width: 100%;
            .liw-block-preview {
                box-shadow: 0 0 10px 0 rgba(var(--mo-color-bg2-rgb), 0.5);
                display: flex;
                height: calc(100vh - 45px - 60px);
                position: relative;
                width: 100%;
                .liw-block-preview-spinner {
                    align-items: center;
                    background: var(--mo-color-text1);
                    color: black;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    .liw-select-preview-text {
                        max-width: 500px;
                        padding: 50px;
                        white-space: pre-line;
                    }
                }
                .slug-page-content {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: calc(100vh - 45px - 60px);
                    min-height: calc(100vh - 45px - 60px);
                    width: 100%;
                }
            }
        }
    }
}
