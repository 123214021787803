.detail-page-modal-web {
    height: 100%;
    .dpmw-content {
        --background: transparent !important;
    }
    .dpmw-backdrop {
        background: rgba(var(--mo-color-bg2-rgb), 0.85);
        cursor: zoom-out;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .dpmw-navigation {
        .dpmw-previous {
            align-items: center;
            color: var(--mo-color-text1);
            display: flex;
            font-size: 30px;
            justify-content: center;
            left: 0;
            position: fixed;
            top: calc(50% - 15px);
            width: 80px;
            z-index: 10;
            .dpmw-icon {
                display: flex;
            }
            .mo-new-icon-chevron-left-solid {
                &.hidden {
                    opacity: 0;
                }
            }
        }
        .dpmw-next {
            align-items: center;
            color: var(--mo-color-text1);
            display: flex;
            font-size: 30px;
            justify-content: center;
            position: fixed;
            right: 0;
            top: calc(50% - 15px);
            width: 80px;
            z-index: 10;
            .dpmw-icon {
                display: flex;
            }
            .mo-new-icon-chevron-right-solid {
                &.hidden {
                    opacity: 0;
                }
            }
        }
        .dpmw-close {
            color: var(--mo-color-text1);
            cursor: pointer;
            font-size: 22px;
            position: fixed;
            left: 29px;
            top: 29px;
            z-index: 11;
            .dpmw-close-icon {
                display: flex;
            }
        }
    }
    .dpmw-actions {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: 70px;
        position: absolute;
        right: 0;
        width: 80px;
        .main-detail-edit-block-web {
            display: flex;
            flex-direction: column-reverse;
            .main-icon-block-web {
                margin-bottom: 10px;
            }
            .inactive-button-web.detail {
                margin: 0;
            }
        }
    }
    .dpmw-modal-wrap {
        height: calc(100% - 30px);
        margin: 15px 80px;
        position: relative;
        width: calc(100% - 160px);
        .dpmw-header {
            align-items: center;
            background: var(--mo-color-bg1);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border: 1px solid var(--mo-color-ln);
            display: flex;
            height: 45px;
            justify-content: space-between;
            left: 0;
            overflow: hidden;
            padding: 0 5px 0 10px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 12;
            .dpmw-header-background-image-overlay {
                background-color: rgba(var(--mo-color-black-rgb), 0.82);
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
            .dpmw-header-left {
                align-items: center;
                display: flex;
                max-width: 250px;
                padding-right: 20px;
                z-index: 1;
                .profile-short-info-web {
                    margin-bottom: 0;
                }
            }
            .dpmw-title-wrap {
                align-items: center;
                display: flex;
                font-size: 17px;
                height: 100%;
                justify-content: center;
                left: 0px;
                padding: 0px 90px;
                position: absolute;
                top: 0px;
                width: 100%;
                .dpmw-title {
                    font-size: 17px;
                    font-weight: bold;
                    max-width: calc(100% - 500px);
                    white-space: normal;
                }
            }
            .main-detail-edit-block-web {
                padding-left: 20px;
                z-index: 1;
            }
        }
        .dpmw-modal-content {
            background: var(--mo-color-bg2);
            border: 1px solid var(--mo-color-ln);
            min-height: calc(100vh - 30px);
            overflow: hidden;
            padding-top: 45px;
            &.website {
                padding-top: 0;
            }
            .adherent-detail-block-web {
                height: calc(100vh - 30px - 45px);
                .adbw-left {
                    height: calc(100vh - 30px - 45px);
                }
                .adbw-right {
                    height: calc(100vh - 30px - 45px);
                }
            }
            .main-detail-block {
                height: calc(100vh - 30px - 45px);
                .mdb-left {
                    height: calc(100vh - 30px - 45px);
                    overflow-y: hidden;
                    .mixed-content-block-web {
                        height: calc(100vh - 30px - 45px);
                        .slug-page-content {
                            height: calc(100vh - 30px - 45px);
                            min-height: calc(100vh - 30px - 45px);
                        }
                    }
                }
                .mdb-right {
                    height: calc(100vh - 30px - 45px);
                }
            }
            .member-detail-block-web {
                height: calc(100vh - 30px - 45px);
                .mdbw-left {
                    height: calc(100vh - 30px - 45px);
                    .mixed-content-block-web {
                        height: calc(100vh - 30px - 45px);
                        .slug-page-content {
                            height: calc(100vh - 30px - 45px);
                            min-height: calc(100vh - 30px - 45px);
                        }
                    }
                }
                .mdbw-right {
                    height: calc(100vh - 30px - 45px);
                }
            }
            .portfolio-detail-block-web {
                .pdbw-content {
                    height: calc(100vh - 30px);
                }
                &.template {
                    .pdbw-content {
                        height: calc(100vh - 30px - 45px);
                    }
                }
            }
            .product-detail-package-block-web {
                height: calc(100vh - 30px - 45px);
                .pdbw-left {
                    height: calc(100vh - 30px - 45px);
                    .mixed-content-block-web {
                        height: calc(100vh - 30px - 45px);
                        .slug-page-content {
                            height: calc(100vh - 30px - 45px);
                            min-height: calc(100vh - 30px - 45px);
                        }
                    }
                }
                .pdbw-right {
                    height: calc(100vh - 30px - 45px);
                }
            }
        }
    }
}
.detail-modal-wrapper-web {
    min-height: 100%;
    .ctf-wrapper {
        background: transparent;
        height: 100vh;
        opacity: 1;
        position: relative;
        width: 100vw;
    }
}
