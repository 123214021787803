.material-detail-block {
    position: relative;
    .mdb-cover-image {
        width: 100%;
    }
    .mdb-material-info {
        padding: 10px;
        .mdb-main-info {
            border-bottom: solid 1px var(--mo-color-ln);
            padding-bottom: 10px;
            margin-bottom: 10px;
            .mdb-name {
                font-weight: 600;
            }
            .mdb-tags {
                border-bottom: solid 1px var(--mo-color-ln);
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
            .mdb-countries {
                padding-bottom: 10px;
                .mdb-cities {
                    color: var(--mo-color-text2);
                }
            }
            .mdb-phone {
                color: var(--mo-color-text2);
            }
        }
        .mdb-section {
            border-bottom: solid 1px var(--mo-color-ln);
            padding-bottom: 10px;
            margin-bottom: 10px;
            .mdb-section-title {
                border-bottom: solid 1px var(--mo-color-ln);
                font-weight: 600;
                margin-bottom: 10px;
                padding-bottom: 10px;
            }
            .mdb-description {
                color: var(--mo-color-text2);
            }
            .mdb-section-content {
                margin-bottom: 20px;
                .mdb-price {
                    color: var(--mo-color-text2);
                }
                .mdb-section-button {
                    margin: 20px auto 0;
                    max-width: 70%;
                }
            }
        }
    }
}
