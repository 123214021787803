.rich-text-input {
    .ck-editor {
        all: initial;
        border-radius: $form-br;
        width: 100%;
        a {
            color: blue;
            text-decoration: underline;
        }
        .ck-content {
            border-bottom-left-radius: $form-br !important;
            border-bottom-right-radius: $form-br !important;
            border-bottom: none;
            border-left: none;
            border-right: none;
        }
        .ck-sticky-panel__content {
            border-top-left-radius: $form-br !important;
            border-top-right-radius: $form-br !important;
            border: none;
        }
        .ck-toolbar {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-top-left-radius: $form-br !important;
            border-top-right-radius: $form-br !important;
        }
        .ck-toolbar__items {
            flex-wrap: wrap !important;
        }
    }
}
.ck-balloon-panel {
    z-index: 9999 !important;
}
