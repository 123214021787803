.profile-info-block {
    padding: 20px;
    position: relative;
    .pib-blur {
        -moz-filter: blur(20px);
        -ms-filter: blur(20px);
        -o-filter: blur(20px);
        -webkit-filter: blur(20px);
        bottom: 0;
        filter: blur(20px);
        height: 80px;
        left: 0;
        opacity: 0.6;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        .pib-image {
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }
    .pib-bg-cover {
        bottom: 0;
        height: 75px;
        left: 0;
        opacity: 0.7;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        .pib-image {
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }
    .pib-profile-image-wrap {
        margin-bottom: 20px;
        position: relative;
        width: 100%;
        .pib-profile-header {
            align-items: flex-end;
            display: flex;
            .pib-profile-image {
                height: 97px;
                width: 97px;
            }
            .pib-actions {
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                padding-left: 10px;
            }
        }
        .pib-profile-created-by {
            margin-top: 15px;
        }
        .pib-profile-image-credit {
            font-size: 14px;
            margin-top: 10px;
        }
        .pib-last-login {
            font-size: 10px;
            margin-top: 5px;
            span {
                margin-right: 10px;
            }
        }
    }
    .pib-name {
        font-size: 19px;
        font-weight: 600;
        padding-bottom: 11px;
    }
    .pib-main-categories {
        padding-bottom: 15px;
    }
    .pib-metiers {
        padding-bottom: 12px;
    }
    .pib-description {
        padding-bottom: 15px;
    }
    .pib-country {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 15px;
    }
    .pib-other-profiles {
        .pib-op-wrap {
            padding-bottom: 15px;
            .pib-op-text {
                font-style: italic;
                margin-bottom: 10px;
            }
        }
    }
    .pib-website {
        color: var(--mo-color-text2);
        display: block;
        padding-bottom: 10px;
    }
    .pib-social-wrap {
        display: flex;
        margin-top: 10px;
        .pib-social {
            margin-right: 19px;
            .pib-social-image {
                height: 16px;
                width: 16px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
