.content-detail-input-portfolio-web {
    margin-bottom: $form-mb;
    &.disabled {
        cursor: not-allowed !important;
        opacity: 0.4;
        .cdipw-input-wrap {
            cursor: not-allowed;
        }
    }
    .cdipw-input-wrap {
        align-items: center;
        background: var(--mo-color-bg2);
        border-radius: $form-br;
        cursor: pointer;
        display: flex;
        min-height: 38px;
        padding: 5px 13px;
        position: relative;
        .cdipw-input {
            color: var(--mo-color-text1);
            flex: 1;
            min-height: 28px;
            .cdipw-text-width {
                max-width: 280px;
            }
        }
        .cdipw-clear {
            color: var(--mo-color-text1);
            cursor: pointer;
        }
    }
}
.content-detail-input-portfolio-modal-web {
    .cdipmw-thumb {
        padding: 5px 10px 5px 0;
    }
    .cdipmw-label {
        white-space: normal;
    }
    .cdipmw-not-active {
        color: var(--mo-color-mo-color);
    }
    .cdipmw-medium-type {
        color: var(--mo-color-text2);
    }
}
