.profile-detail-block-web {
    padding: 20px;
    position: relative;
    .pfdbw-profile-image-wrap {
        align-items: center;
        border-bottom: 1px solid var(--mo-color-ln);
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;
        position: relative;
        width: 100%;
        .pfdbw-profile-image {
            cursor: zoom-in;
            height: 97px;
            object-fit: contain;
        }
        .pfdbw-profile-created-by {
            font-size: 12px;
            margin-top: 15px;
            text-align: center;
        }
        .pfdbw-profile-image-credit-wrap {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-top: 10px;
            .pfdbw-profile-image-credit {
                font-size: 14px;
                &.tag {
                    margin-right: 10px;
                }
            }
        }
        .pfdbw-actions {
            display: flex;
            flex: 1;
            justify-content: space-around;
            padding-top: 20px;
            width: 100%;
        }
    }
    .pfdbw-name {
        color: var(--mo-color-text1);
        font-size: 21px;
        font-weight: 600;
        padding-top: 5px;
        text-align: center;
    }
    .pfdbw-metiers {
        color: var(--mo-color-text1);
    }
    .pfdbw-countries {
        display: flex;
        flex-wrap: wrap;
        .main-country-flag-web {
            margin-bottom: 5px;
        }
    }
    .pfdbw-description {
        padding-bottom: 15px;
    }
    .pfdbw-country {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 15px;
    }
    .pfdbw-other-profiles {
        .pfdbw-op-wrap {
            .pfdbw-op-text {
                font-style: italic;
                margin-bottom: 10px;
            }
        }
    }
    .pfdbw-website-wrap {
        .pfdbw-website {
            color: var(--mo-color-mo-color);
            display: block;
            font-size: 13px;
            padding-top: 5px;
            text-align: center;
            text-decoration-color: transparent;
            text-decoration-style: underline;
            transition: $mo-ease;
            &:hover {
                text-decoration-color: var(--mo-color-mo-color);
            }
        }
    }
    .pfdbw-social-wrap {
        display: flex;
        .pfdbw-social {
            align-items: center;
            display: flex;
            margin-right: 19px;
            .pfdbw-social-image {
                height: 16px;
            }
            &:last-child {
                margin-right: 0;
            }
            i {
                color: var(--mo-color-text1);
                display: flex;
            }
        }
    }
    .pfdbw-member-info-wrap {
        padding-bottom: 10px;
        .pfdbw-member-button-wrap {
            display: flex;
            justify-content: center;
            padding-bottom: 10px;
        }
        .pfdbw-member-info-title {
            color: var(--mo-color-text1);
        }
    }
    .pfdbw-member-info-created-wrap {
        padding: 2px 0;
        .pfdbw-member-info-added {
            display: inline-block;
            margin-right: 3px;
        }
    }
    .pfdbw-last-login {
        font-size: 10px;
        margin-top: 5px;
        padding-top: 10px;
        span {
            margin-right: 10px;
        }
    }
}
