.search-menu {
    .sm-flag {
        height: 15px;
    }
    .sm-header-button {
        justify-content: space-between;
        .sm-header-title {
            font-weight: 600;
        }
    }
    .sm-thumb-wrap {
        color: #898f99;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        .sl-size-icon {
            font-size: 40px;
            &.active {
                color: var(--mo-color-mo-color);
            }
        }
    }
    .sm-choices-list {
        background: var(--mo-color-bg1);
        margin-bottom: 0;
        padding-bottom: 32px;
        .sm-choice-label {
            color: var(--mo-color-text2) !important;
            font-size: $MainFontSize;
        }
    }
    .sm-filter-option-choice {
        border: 1px solid var(--mo-color-mo-color);
        border-radius: 5px;
        color: var(--mo-color-mo-color);
    }
}
