.subscription-list-web {
    .slw-content {
        padding: 20px;
        .slw-subscription-wrap {
            color: var(--mo-color-text2);
            padding-bottom: 20px;
            .slw-subscription-top {
                align-items: center;
                display: flex;
                .slw-name {
                    color: var(--mo-color-text1);
                }
                .slw-interval {
                    background: #ffe7f2;
                    border-radius: 4px;
                    color: #b3063d;
                    margin-left: 10px;
                    padding: 1px 6px;
                    &.active {
                        background: #d7f7c2;
                        color: #05690d;
                    }
                }
            }
            .slw-button-wrap {
                padding-top: 10px;
            }
        }
    }
}
